/**
 * Desc: 日期类相关的其他操作，请查看 moment.js 插件方法。
 *       这里是日期类补充公共方法。
 *
 * Author: Jiangyb
 * Data: 2021年7月14日
 */

/**
 * 得到今天、昨天、明天日期
 * @param {number} dates 不传或0: 代表今日, -1: 代表昨日，1: 代表明日
 * @returns {string} "yyyy-mm-dd"
 */
export function getDateOfStartOrEnd(dates) {
	let dd = new Date();
	let n = dates || 0;
	dd.setDate(dd.getDate() + n);
	let y = dd.getFullYear();
	let m = dd.getMonth() + 1;
	let d = dd.getDate();
	m = m < 10 ? "0" + m : m;
	d = d < 10 ? "0" + d : d;
	let day = y + "-" + m + "-" + d;
	return day;
}

/**
 * 得到本周、上周、下周的起始、结束日期
 * @param {string} type "s": 开始, "e": 结束
 * @param {number} dates 不传或0: 本周，-1: 上周，1: 下周
 * @returns "yyyy-mm-dd"
 */
export function getMondayOfStartOrEnd(type, dates) {
	let now = new Date();
	let nowTime = now.getTime();
	let day = now.getDay();
	let longTime = 24 * 60 * 60 * 1000;
	let n = longTime * 7 * (dates || 0);
	let dd = null;
	if (type == "s") {
		dd = nowTime - (day - 1) * longTime + n;
	}
	if (type == "e") {
		dd = nowTime + (7 - day) * longTime + n;
	}
	dd = new Date(dd);
	let y = dd.getFullYear();
	let m = dd.getMonth() + 1;
	let d = dd.getDate();
	m = m < 10 ? "0" + m : m;
	d = d < 10 ? "0" + d : d;
	day = y + "-" + m + "-" + d;
	return day;
}

/**
 * 得到本月、上月、下月的起始、结束日期
 * @param {string} type "s": 开始, "e": 结束
 * @param {number} months 不传或0: 本月，-1: 上月，1: 下月
 * @returns "yyyy-mm-dd"
 */
export function getMonthOfStartOrEnd(type, months) {
	let d = new Date();
	let year = d.getFullYear();
	let month = d.getMonth() + 1;
	months = months || 0;
	if (Math.abs(months) > 12) {
		months = months % 12;
	}
	if (months != 0) {
		if (month + months > 12) {
			year++;
			month = (month + months) % 12;
		} else if (month + months < 1) {
			year--;
			month = 12 + month + months;
		} else {
			month = month + months;
		}
	}
	month = month < 10 ? "0" + month : month;
	let firstday = year + "-" + month + "-" + "01";
	let lastday = "";
	if (
		month == "01" ||
		month == "03" ||
		month == "05" ||
		month == "07" ||
		month == "08" ||
		month == "10" ||
		month == "12"
	) {
		lastday = year + "-" + month + "-" + 31;
	} else if (month == "02") {
		if (
			(year % 4 == 0 && year % 100 != 0) ||
			(year % 100 == 0 && year % 400 == 0)
		) {
			lastday = year + "-" + month + "-" + 29;
		} else {
			lastday = year + "-" + month + "-" + 28;
		}
	} else {
		lastday = year + "-" + month + "-" + 30;
	}
	let day = "";
	if (type == "s") {
		day = firstday;
	} else {
		day = lastday;
	}
	return day;
}

/**
 * 得到今年、去年、明年的开始、结束日期
 * @param {string} type "s": 开始, "e": 结束
 * @param {*} dates 不传或0: 今年，-1: 去年，1: 明年
 * @returns "yyyy-mm-dd"
 */
export function getYearOfStartOrEnd(type, dates) {
	let dd = new Date();
	let n = dates || 0;
	let year = dd.getFullYear() + Number(n);
	let day = null;
	if (type == "s") {
		day = year + "-01-01";
	}
	if (type == "e") {
		day = year + "-12-31";
	}
	if (!type) {
		day = year + "-01-01/" + year + "-12-31";
	}
	return day;
}

export function formatDates(date, fmt) {
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
	}
	let o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds()
	};
	for (let k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			let str = o[k] + '';
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
		}
	}
	return fmt;
};

function padLeftZero(str) {
	return ('00' + str).substr(str.length);
}
