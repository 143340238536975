<!--通讯录-->
<template>
  <div id="chat-contact">
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">通讯录</span>
      </template>
      <template #right>
        <van-icon @click="onClickAdd" name="add-o" size="2em" color="#ffffff"></van-icon>
      </template>
    </van-nav-bar>

    <van-cell @click="onClickNewFriend">
      <template #icon>
        <img src="../assets/icon/newFriend.png" style="background-color: #26a5f9;width: 3em;object-fit: cover">
      </template>
      <template #title>
        新的朋友
      </template>
    </van-cell>

    <van-index-bar :sticky="false">
            <span v-for="i in contactList" :key="i.index">
                <van-index-anchor class="van-index-anchor" :index="i.index"/>
                <span v-for="j in i.list" :key="j.name">
                    <van-cell @click="onClickCell">
                        <template #icon>
                            <van-image width="3em" fit="cover" :src="j.headUrl"/>
                        </template>
                        <template #title>
                            {{ j.name }}
                        </template>
                    </van-cell>
                    <van-divider/>
                </span>
            </span>
    </van-index-bar>
  </div>
</template>

<script>
import Vue from 'vue';
import {NavBar, IndexBar, IndexAnchor, Cell, Image, Divider} from 'vant';

Vue.use(NavBar);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Cell);
Vue.use(Image);
Vue.use(Divider);
export default {
  name: "chat-contact",
  data() {
    return {
      contactList: [
        {
          index: 'A',
          list: [
            {
              name: 'AA华润置业小王',
              headUrl: 'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1028479771,2944343576&fm=26&gp=0.jpg'
            },
            {
              name: 'A华润销售刘志国',
              headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598611502824&di=34130bd2a75045cdfefcc7f0b03bfb2e&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201510%2F18%2F20151018172940_5etXi.jpeg'
            },
            {
              name: 'AA物美精装袁美美',
              headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598611502824&di=bbc517fb4cb0df5ec5a97b1bcef3fba0&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201702%2F09%2F20170209222526_NmCsv.thumb.700_0.jpeg'
            },
            {
              name: '阿珍',
              headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598611502824&di=19c9d0b53aaaa35a6bc405cdc48a0e00&imgtype=0&src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fq_70%2Cc_zoom%2Cw_640%2Fimages%2F20190129%2F8478c326fa174158859b9ac093e36938.jpeg'
            },
            {
              name: '阿强',
              headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598611502823&di=6827c2a04ac78cfbb1daa1d8746aacb6&imgtype=0&src=http%3A%2F%2Fimg.mp.itc.cn%2Fupload%2F20160506%2F288989d1e85642169217a3d6d95216d7_th.jpg'
            }
          ]
        },
        {
          index: 'C',
          list: [
            {
              name: '陈义珍',
              headUrl: 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1610542142,718703573&fm=26&gp=0.jpg'
            },
            {
              name: '陈胜',
              headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598611502822&di=3d15f5e3f6f0450496b213602996be1f&imgtype=0&src=http%3A%2F%2Fcdn.duitang.com%2Fuploads%2Fitem%2F201412%2F17%2F20141217211542_CYRCV.jpeg'
            },
          ]
        },
        {
          index: 'H',
          list: [
            {
              name: '黄齐声',
              headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598611502821&di=f74cb25fb8004d308fc2d533d9eb9a46&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201411%2F29%2F20141129194517_5Z2Lu.png'
            },
            {
              name: '黄修身',
              headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598611502821&di=1ccece0d147919590273733cc1837851&imgtype=0&src=http%3A%2F%2Fdiy.qqjay.com%2Fu2%2F2014%2F0408%2F756dd9c10a90b04ed51d704c69cdb052.jpg'
            }
          ]
        },
        {
          index: 'L',
          list: [
            {
              name: '刘昌',
              headUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=245642097,2471196830&fm=26&gp=0.jpg'
            },
            {
              name: '柳传神',
              headUrl: 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=264376310,1771117265&fm=26&gp=0.jpg'
            }
          ]
        },
        {
          index: 'J',
          list: [
            {
              name: '蒋福水',
              headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598611585457&di=7ea33e76cf573f33207804554b5adf57&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201506%2F23%2F20150623212114_mJjsY.jpeg'
            },
            {
              name: '金魁',
              headUrl: 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1088161441,560000220&fm=26&gp=0.jpg'
            }
          ]
        },
        {
          index: 'Q',
          list: [
            {
              name: '秦爱军',
              headUrl: 'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1564300687,1927476428&fm=26&gp=0.jpg'
            },
            {
              name: '齐恒',
              headUrl: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3410052090,3376497392&fm=26&gp=0.jpg'
            }
          ]
        }
      ]
    };
  },
  methods: {
    onClickFallback() {
      this.$router.back();
    },
    onClickAdd() {
      this.$router.push('/add-friend');
    },
    onClickCell() {
      this.$router.push('/chat-window');
    },
    onClickNewFriend() {
      this.$router.push('/new-friend');
    }
  }
}
</script>

<style scoped>
#chat-contact {
  height: 100%;
  background-color: #F8F8F8;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.van-index-anchor {
  background-color: #efefef;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}

.van-cell__title {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
}

.van-divider {
  margin: 0;
}
</style>