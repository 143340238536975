<template>
  <div id="newFriend-container">
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" style="background-color:#e9e9e9;">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">新的朋友</span>
      </template>
      <template #right>
        <van-icon @click="onClickAddButton" name="add-o" size="2em" color="#ffffff"></van-icon>
      </template>
    </van-nav-bar>

    <van-cell v-for="i in applyList" :key="i.name">
      <template #icon>
        <van-image width="3em" fit="cover" :src="i.headUrl"/>
      </template>
      <template #title>
        {{ i.name }}
      </template>
      <template #right-icon>
        <div class="statusMessage" v-if="i.status == 1||i.status==2">{{ i.status == 1 ? '已同意' : '已拒绝' }}</div>
        <transition name="slide-fade">
          <div v-if="i.status == 0" style="display: flex;">
            <van-button @click="onClickAgree(i.name)" style="display: block;margin:auto 5px; width: 4em;" size="small"
                        color="#26a5f9" text="同意"/>
            <van-button @click="onClickRefuse(i.name)" style="display: block;margin:auto 5px; width: 4em;" size="small"
                        color="#2769fc" text="拒绝"/>
          </div>
        </transition>
      </template>
    </van-cell>
  </div>
</template>

<script>
import Vue from 'vue';
import {Cell, Button} from 'vant';

Vue.use(Cell);
Vue.use(Button);

export default {
  name: "new-friend",
  data() {
    return {
      applyList: [
        {
          name: '云昭',
          headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598866677522&di=d06a94ff117e61714b8e525dfbeb2bc8&imgtype=0&src=http%3A%2F%2Fi.cqlp.cc%2Fattachment%2Fforum%2F201411%2F23%2F114157lx1ld7mm2m1kagm7.jpg',
          status: 0
        },
        {
          name: '钱多多',
          headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598866885579&di=ddfd78997538fc9ffe984b95857162a2&imgtype=0&src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201703%2F31%2F20170331090953_zUcaS.jpeg',
          status: 2
        },
        {
          name: '刘昌',
          headUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=245642097,2471196830&fm=26&gp=0.jpg',
          status: 1
        }
      ]
    }
  },
  methods: {
    onClickFallback() {
      this.$router.back();
    },
    onClickAddButton() {
      this.$router.push('/add-friend');
    },
    onClickAgree(params) {
      for (let i = 0; i < this.applyList.length; i++) {
        if (this.applyList[i].name == params) {
          this.applyList[i].status = 1;
        }
      }
    },
    onClickRefuse(params) {
      for (let i = 0; i < this.applyList.length; i++) {
        if (this.applyList[i].name == params) {
          this.applyList[i].status = 2;
        }
      }
    }
  }
}
</script>

<style scoped>
#newFriend-container {
  height: 100%;
  background-color: #e9e9e9;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}

.statusMessage {
  font-size: 12px;
  color: #969799;
  margin: auto 5px;
}

.van-cell__title {
  margin: auto 5px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
</style>