<!--登录页面-->
<template>
	<div class="login-container">
		<div class="img-box">
			<img src="../assets/logo.png" />
		</div>
		<van-form @submit="onSubmit">
			<van-field class="login-form-line" v-model="userTel" name="userTel" label="手机号" placeholder="您的手机号"
				:rules="[{ required: true, message: '请填写用户名' }]" />
			<van-field class="login-form-line" v-model="userPassword" type="password" name="userPassword" label="密码"
				placeholder="您的密码" :rules="[{ required: true, message: '请填写密码' }]" />
			<van-row class="login-form-line margin-left-right" type="flex" justify="space-between">
				<van-col span="8">
					<van-checkbox v-model="rememberPasswordFlag">
						记住密码
					</van-checkbox>
				</van-col>
				<van-col span="8">
					<div class="forgetPassword">
						忘记密码?
					</div>
				</van-col>
			</van-row>
			<div class="clause-box">
				<van-checkbox icon-size="14px" v-model="isAgreeClause">
					我已阅读旦巢
					<router-link to='/privacy'>隐私政策</router-link>以及
					<router-link to='/agreement'>用户服务协议</router-link>
				</van-checkbox>
			</div>
			<div class="login-form-line margin-left-right">
				<van-button :disabled="canSubmit" ref="buttonSubmit" round block type="info" native-type="submit">
					登录
				</van-button>
			</div>
			<div class="register" @click="onToRegister">
				立即注册
			</div>
		</van-form>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {
		Form,
		Field,
		Button,
		Checkbox,
		Col,
		Row,
		Toast
	} from 'vant';

	Vue.use(Form);
	Vue.use(Field);
	Vue.use(Button);
	Vue.use(Checkbox);
	Vue.use(Col);
	Vue.use(Row);

	export default {
		name: "login",
		data() {
			return {
				userTel: '',
				userPassword: '',
				rememberPasswordFlag: false,
				canSubmit: true,
				isAgreeClause: true
			};
		},
		methods: {
			onSubmit(values) {
				console.log('submit', values);

				var json = {
					"account": values.userTel,
					"password": values.userPassword
				}
				this.$axios({
					url: "tenant/login",
					method: "POST",
					headers: {
						'Content-Type': 'application/json;charset=UTF-8'
					},
					data: json
				}).then(res => {
					console.log(res)
					if (res.data && res.data.token) {
						this.$store.commit('changeVuexToken',
							res.data.token)
						this.$store.commit('changeVuexUser',
							res.data.userInfo)
						console.log(res.data.userInfo)
						this.$router.push('/index');
					} else {
						Toast.fail(res.msg || '登录失败');
					}
					// if (res.data.code == 10019) {
					//   this.$router.push('/index');
					// }else if(res.data.code == 10011){
					//   Toast.fail('账户/密码错误!');
					// } else if (res.data.code == 10012) {
					//   Toast.fail('账户未激活,请联系我们!');
					// } else if (res.data.code == 10013) {
					//   Toast.fail('账户异常,请联系客服!');
					// } else {
					//   Toast.fail('登录失败');
					// }
				}).catch(error => {
					console.log(error, 'error');
					Toast.fail('登录失败');
				})
			},
			onToRegister() {
				this.$router.push('/register');
			}
		},
		watch: {
			'userTel': function(newVal) {
				if (newVal) {
					this.userPassword = '';
				}
			},
			'userPassword': function() {
				this.canSubmit = this.userTel.length > 0 && this.userPassword.length > 0 && this.isAgreeClause ? false : true;
			},
			'isAgreeClause':function(newVal) {
				this.canSubmit = this.userTel.length > 0 && this.userPassword.length > 0 && newVal ? false : true;
			}
		}
	}
</script>

<style scoped>
	.login-container {}

	.img-box {
		display: block;
		padding: 3em;
	}

	.img-box img {
		display: block;
		margin: 0 auto;
		width: 5em
	}

	.login-form-line {
		margin-top: 1em;
		margin-bottom: 1em;
		align-items: center;
	}

	.margin-left-right {
		margin-left: 2em;
		margin-right: 2em;
	}

	.forgetPassword {
		color: #969799;
		text-align: right;
	}

	.clause-box {
		text-align: center;
		color: #646566;
		font-size: 12px;
		padding: 0 9%;
	}

	.register {
		text-align: center;
		margin: 2%;
		padding: 2%;
		color: rgb(255, 153, 0);
	}
</style>
