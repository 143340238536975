<template>
  <div id="temporary-visit-container">
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">临时来访</span>
      </template>
    </van-nav-bar>

    <!--    <div class="item-box">-->
    <!--      <div class="item">-->
    <!--        <div class="head-box">-->
    <!--          <img src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2291332875,175289127&fm=26&gp=0.jpg"/>-->
    <!--        </div>-->
    <!--        <div class="center-box">-->
    <!--          <div class="top">-->
    <!--            <div class="name">刘昌</div>-->
    <!--            <div class="auth-img-box">-->
    <!--              <img src="../assets/icon/auth.png">-->
    <!--            </div>-->
    <!--            <div class="role-img-box">-->
    <!--              <img src="../assets/icon/man.png">-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="center">到访开始时间:2020-10-01</div>-->
    <!--          <div class="bottom">到访结束时间:2020-10-08</div>-->
    <!--        </div>-->
    <!--        <div class="right-box">-->
    <!--          <div class="prohibit-button">-->
    <!--            <div class="img-box">-->
    <!--              <img src="../assets/icon/prohibit.png"/>-->
    <!--            </div>-->
    <!--            <div class="text">取消来访</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="item-box" v-for="(i,index) in visitItems" :key="index">
      <div class="item">
        <div class="head-box">
          <img src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2291332875,175289127&fm=26&gp=0.jpg"/>
        </div>
        <div class="center-box">
          <div class="top">
            <div class="name">{{ i.name }}</div>
            <div class="auth-img-box">
              <img src="../assets/icon/auth.png">
            </div>
            <div class="role-img-box">
              <img src="../assets/icon/man.png">
            </div>
          </div>
          <div class="center">到访开始时间:{{ i.startDate }}</div>
          <div class="bottom">到访结束时间:{{ i.endDate }}</div>
        </div>
        <div class="right-box">
          <div class="prohibit-button" @click="onClickProhibit(index)">
            <div class="img-box">
              <img src="../assets/icon/prohibit.png"/>
            </div>
            <div class="text">取消来访</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "temporary-visit",
  data() {
    return {
      visitItems: [
        {
          name: '刘昌',
          startDate: '2020-10-01',
          endDate: '2020-10-08'
        }
      ]
    }
  },
  methods: {
    onClickFallback() {
      this.$router.back();
    },
    onClickProhibit(index) {
      this.visitItems.splice(index, 1);
    }
  }
}
</script>

<style scoped>
#temporary-visit-container {
  min-height: 100%;
  background-color: #F8F8F8;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.van-index-anchor {
  background-color: #efefef;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}


.item-box > .item {
  padding: 2%;
  margin: 2%;
  background-color: #FFFFFF;
  border-radius: 5px;
  display: flex;
  height: 4em;
}

.item-box > .item > .head-box {
  height: 100%;
}

.item-box > .item > .head-box > img {
  height: 100%;
  border-radius: 5px;
}

.item-box > .item > .center-box {
  color: #646566;
  font-size: 12px;
  height: 100%;
  margin: auto;
  padding: 0 1%;
}

.item-box > .item > .center-box > * {
  display: block;
}

.item-box > .item > .center-box > .top {
  display: flex;
  font-size: 16px;
}

.item-box > .item > .center-box > .center {
  margin: 3px 0;
}

.item-box > .item > .center-box > .bottom {
}

.item-box > .item > .center-box > .top > .auth-img-box, .item-box > .item > .center-box > .top > .role-img-box {
  margin: auto 3px;
  display: block;
  width: 1em;
}

.item-box > .item > .center-box > .top > .auth-img-box img, .item-box > .item > .center-box > .top > .role-img-box img {
  display: block;
  width: 100%;
}

.item-box > .item > .center-box > .top > .role-text {
  font-size: 12px;
  margin: auto 0;
  color: #eaa63f;
}

.item-box > .item > .right-box {
  margin: auto 0;
  margin-left: auto;
}


.item-box > .item > .right-box > .prohibit-button {
  display: flex;
}

.item-box > .item > .right-box > .prohibit-button > .img-box {
  margin: auto 0;
  display: block;
  width: 1em;
}

.item-box > .item > .right-box > .prohibit-button > .img-box > img {
  display: block;
  width: 100%;
}

.item-box > .item > .right-box > .prohibit-button > .text {
  margin: auto 0;
  color: #26a5f9;
}
</style>
