<template>
	<div class="container">
		<!--导航条-->
		<van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
			<template #left>
				<van-icon name="arrow-left" @click="onClickFallback" size="2em" color="#ffffff" />
			</template>
			<template #title>
				<span class="navbar-title">{{title}}</span>
			</template>
		</van-nav-bar>

		<div class="u-m-t-30 u-padding-left-30">
			<div class="u-font-40">请完成以下认证</div>
			<div class="u-m-t-30">请输入13631***340收到的短信验证码</div>
			<van-field label="手机号" v-model="phone" center required placeholder="请输入手机号" :border="true" />
			<van-field v-model="sms" center required clearable label="验证码" placeholder="请输入验证码" :border="false"
				use-button-slot>
				<van-button slot="button" size="small" type="primary" @click="gainCode">{{time}}</van-button>
			</van-field>
		</div>

		<div class="u-m-t-80 u-padding-30">
			<van-button type="primary" size="large" @click="nextStep">下一步</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				title: '修改登录密码',
				form: {

				},
				isClick: true,
				time: '获取验证码',
				phone: '',
				sms: '',
			}
		},
		created() {
			
		},
		methods: {
			onClickFallback() {
			  this.$router.back();
			},
			async nextStep() {
				const params = {
					code: this.form.vcode
				}
				let {
					message,
					status
				} = await this.$axios('verifiCode', params)

				if (status != 20000) {
					this.$toast.fail(message);
					return false;
				}

				if (this.title == '修改登录密码') {
					this.$router.push('/setPassword');
				} else if(this.title == '修改支付密码'){
					this.$router.push({path:'/modify-payment-password',query:{type:'1'}});
				} else {
					this.$router.push('/setPhone');
				}
			},
			// 获取验证码
			async gainCode() {
				if (this.isClick) {
					if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this
							.phone)) {
								
						const params = {
							phone: this.form.phone
						}
						let {status} = await this.$axios('sendCode', params) // 获取验证码接口
						if(status === 20000){
							this.isClick = false
							let s = 60
							this.time = s + 's'
							let interval = setInterval(() => {
								s--
								this.time = s + 's'
								if (s < 0) {
									this.time = '重新获取'
									this.isClick = true
									clearInterval(interval)
								}
							}, 1000)
						}else{
							this.$toast.fail('验证码发送失败!')
						}
					} else {
						this.$toast.fail('请输入正确的手机号码')
					}
				}
			}
		}
	}
</script>

<style scoped>
	.container{
		padding:15px;
	}
</style>
