<template>

  <div id="vr-container">
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">360°VR看房</span>
      </template>
    </van-nav-bar>
    <Pano style="height: 100%;width: 100%;" :source="imgUrl"></Pano>
  </div>
</template>

<script>
import {Pano} from 'vuejs-vr'

export default {
  name: "vr",
  components: {Pano},
  data() {
    return {
      imgUrl: require('../assets/testVr.jpg'),
    }
  },
  methods:{
    onClickFallback:function (){
      this.$router.back();
    }
  }
}
</script>

<style scoped>
#vr-container {
  height: 100%;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.van-index-anchor {
  background-color: #efefef;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}
</style>
