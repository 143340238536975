<template>
  <div id="booking-container">
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">预约办理</span>
      </template>
    </van-nav-bar>

    <van-tabs v-model="tabIndex">
      <van-tab class="cancel-tab" title="办理退房">
        <div class="item-box">
          <div class="item">
            <div class="basic-box">
              <div class="status">
                <div class="light"/>
                <div class="text">退房中</div>
              </div>
              <div class="item-box">
                <div class="item">
                  <div class="name">房号:</div>
                  <div class="value">701</div>
                </div>
                <div class="item">
                  <div class="name">月租金:</div>
                  <div class="value">￥2654元</div>
                </div>
                <div class="item">
                  <div class="name">押金:</div>
                  <div class="value">￥5308元</div>
                </div>
                <div class="item">
                  <div class="name">其他费用:</div>
                  <div class="value">￥200元</div>
                </div>
                <div class="item">
                  <div class="name">起租日期:</div>
                  <div class="value">2020-10-01</div>
                </div>
                <div class="item">
                  <div class="name">合约到期:</div>
                  <div class="value">2021-02-01</div>
                </div>
                <div class="item">
                  <div class="name">类型:</div>
                  <div class="value">租客整租</div>
                </div>
              </div>
              <div class="button-box" v-if="!showDetail">
                <van-button class="button" color="#DC143C" @click="showDetail=true" size="small" text="申请退房"/>
              </div>
            </div>
            <div class="detail-box" v-if="showDetail">
              <van-divider/>
              <div class="item-box">
                <div class="item">
                  <div class="name">合同周期</div>
                  <div class="value">6个月</div>
                </div>
                <div class="item">
                  <div class="name">合同是否到期</div>
                  <div class="value">否</div>
                </div>
                <div class="item">
                  <div class="name">起租日期</div>
                  <div class="value">2020-10-01</div>
                </div>
                <div class="item">
                  <div class="name">退还押金</div>
                  <div class="value">预计￥5308元(需房东确认)</div>
                </div>
                <div class="item">
                  <div class="name">水电用量</div>
                  <div class="value">水:35&nbsp;电:136</div>
                </div>
                <div class="item">
                  <div class="name">水电费用</div>
                  <div class="value">350(以退房日期用量为准)</div>
                </div>
                <div class="item">
                  <div class="name">退房日期</div>
                  <div class="value">2020-09-16</div>
                </div>
              </div>
              <div class="bottom-box">
                <div class="hide-button-box">
                  <van-icon @click="showDetail=false" name="arrow-up" size="1.5em" color="#646566"></van-icon>
                </div>
                <div class="total">
                  预计缴纳金额:￥251元
                </div>
                <div class="button-box">
                  <van-button class="button" color="#DC143C" size="small" text="申请退房"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="酒店办理">

      </van-tab>
      <van-tab title="预约看房" class="see-tab">
        <div class="item-box">
          <div class="item" @click="onJumpToRentDetail">
            <div class="img-box">
              <img src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1599130045348&di=5c32f43df9be14a7d61bb06c164afabb&imgtype=0&src=http%3A%2F%2Fimg5.k68.cn%2Fk68over2008-10%2F108295%2Fk68_cn_201032612928_02.jpg">
            </div>
            <div class="text-box">
              <div class="name">大运 星河时代</div>
              <div class="info">合租&nbsp;|&nbsp;4室1厅&nbsp;·&nbsp;14m²&nbsp;·&nbsp;7/12层</div>
              <div class="time">
                您已预约在2020-09-16 8点上门看房
              </div>
              <div class="price">
                <div class="actual">
                  <div class="number">￥2654</div>
                  <div class="unit">/月</div>
                </div>
                <div class="original">
                  <div class="number">￥3216</div>
                  <div class="unit">/月</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "booking",
  data() {
    return {
      tabIndex: this.$store.state.bookingSelectTabbarIndex,
      showDetail:false,
    }
  },
  methods: {
    onClickFallback: function () {
      this.$router.back();
    },
    onJumpToRentDetail:function (){
      this.$router.push('/booking-detail');
    },
  },
  watch:{
    tabIndex: function (newValue) {
      this.$store.commit({
        type: 'changeBookingSelectTabbarIndex',
        newValue: newValue
      });
    }
  }
}
</script>

<style scoped>
#booking-container {
  background-color: #F8F8F8;
  min-height: 100%;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.van-index-anchor {
  background-color: #efefef;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}

::v-deep .van-tabs__line {
  background-color: #26a5f9;
}

.cancel-tab > .item-box > .item {
  background-color: #FFFFFF;
  border-radius: 5px;
  margin: 2%;
  padding: 2%;
}

.cancel-tab .basic-box .item-box {
  display: flex;
  flex-wrap: wrap;
}

.cancel-tab .basic-box .item-box > .item {
  width: 50%;
  display: flex;
  margin: 5px 0;
}

.cancel-tab .basic-box > .status {
  display: flex;
  margin: 2% 0;
}

.cancel-tab .basic-box > .status > .light {
  width: 5px;
  background-color: #DC143C;
}

.cancel-tab .basic-box > .status > .text {
  margin: auto 3px;
  font-weight: bold;
  color: #DC143C;
}


.cancel-tab .basic-box .item-box > .item > .name {
  font-size: 12px;
  margin: auto 0;
  color: #646566;
}

.cancel-tab .basic-box .item-box > .item > .value {
  margin: auto 3px;
}

.cancel-tab .basic-box > .button-box > .button{
  display: block;
  margin-left: auto;
  border-radius: 5px;
}

.cancel-tab .detail-box > .item-box > .item{
  display: flex;
  margin: 2% 0;
}

.cancel-tab .detail-box > .item-box > .item > .name{
  font-size: 12px;
  color: #636465;
}

.cancel-tab .detail-box > .item-box > .item >.value{
  margin-left: auto;
}

.cancel-tab .detail-box > .bottom-box{
  display: flex;
}

.cancel-tab .detail-box > .bottom-box > .hide-button-box{
  margin: auto 0;
  display: block;
}

.cancel-tab .detail-box > .bottom-box > .hide-button-box >>> .van-icon-arrow-up {
  display: block;
}

.cancel-tab .detail-box > .bottom-box >.total{
  margin: auto 0;
  margin-left: auto;
  margin-right: 7px;
  font-weight: bold;
  color: #DC143C;
}

.cancel-tab .detail-box > .bottom-box >.button-box >.button{
  border-radius: 5px;
}

.see-tab .item-box .item{
  padding: 2%;
  margin: 3%;
  background-color: #FFFFFF;
  border-radius: 5px;
}

.see-tab .item-box .item{
  display: flex;
}

.see-tab .item-box .item .text-box{
  font-size: 14px;
  margin-left: 5px;
}

.see-tab .item-box .item .text-box .name{
  font-weight: bold;
}

.see-tab .item-box .item .text-box .info{
  color: #646566;
  font-size: 12px;
  margin: 7px 0;
}

.see-tab .item-box .item .text-box .price{
  margin: 7px 0;
}

.see-tab .item-box .item .text-box .price .actual{
  font-size: 14px;
  color: #DC143C;
}

.see-tab .item-box .item .text-box .price .actual .number{
  margin: auto 0;
  padding-bottom: 2px;
}

.see-tab .item-box .item .text-box .price .actual .unit{
  font-size: 10px;
  margin: auto 0;
}

.see-tab .item-box .item .text-box .price .original{
  font-size: 10px;
  margin: auto 0;
  color: #646566;
  text-decoration:line-through
}

.see-tab .item-box .item .text-box .time{
  color: #3CB371;
  font-size: 12px;
  margin: auto 0;
}

.see-tab .item-box .item .img-box{
  width: 6em;
  height: 6em;
}

.see-tab .item-box .item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.see-tab .item-box .item .text-box .price,.see-tab .item-box .item .text-box .price >*{
  display: flex;
}


</style>
