// [Boolean] 是否是开发环境
const isDevelopment = process.env.NODE_ENV === "development";

// [string] 菜单位置，window.open 打开其他系统的地址
const windowOpenUrl = "";

// [string] 请求接口域名前缀 todo, example https://www.baidu.com
const origin = process.env.VUE_APP_BASE_API;
// [string] 请求接口根路径前缀
const pathName = isDevelopment ? "/fontDev/" : "/house/";
console.log(process.env,'process.env')
// [string] 完整接口请求地址
const baseURL = origin;

// [number] 指定请求超时的毫秒数(0 表示无超时时间),如果请求话费了超过 `timeout` 的时间，请求将被中断
const timeout = 3 * 60 * 1000;

// [Boolean] 表示跨域请求时是否需要使用凭证
const withCredentials = true;

// const headers = {
//   "Content-Type": "application/x-www-form-urlencoded",
//   Authorization: "Basic eG1qZzp4bWpnMTIz",
//   // Authorization: "Basic Y3BzaXM6Y3BzaXM1OVhDVmg="
// };

export default { isDevelopment, origin, pathName, baseURL, timeout, withCredentials, windowOpenUrl };
