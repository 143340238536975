import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import login from "../views/login";
import index from "../views/index"
import chatWindow from "../views/chat-window";
import chatContact from "../views/chat-contact";
import addFriend from "../views/add-friend";
import newFriend from "../views/new-friend";
import rent from "../views/rent";
import rentDetail from "../views/rent-detail";
import my from "../views/my";
import vr from "../views/vr";
import supporting from "@/views/supporting";
import booking from "@/views/booking";
import bookingDetail from "@/views/booking-detail";
import release from "@/views/release";
import wallet from "@/views/wallet";
import authFamily from "@/views/auth-family"
import addFamily from "@/views/add-family"
import realName from "@/views/real-name"
import register from "@/views/register"
import accountManagement from "@/views/account-management"
import keyManagement from "@/views/key-management"
import personalInformation from "@/views/personal-information"
import temporaryVisit from "@/views/temporary-visit"
import paymentManagement from "@/views/payment-management"
import modifyPaymentPassword from "@/views/modify-payment-password"
import verifiPhone from "@/views/verifiPhone/verifiPhone"
import agreement from "@/views/agreement/agreement"
import privacy from "@/views/privacy/privacy"
import search from "@/views/search/search"
import collection from "@/views/collection/collection"
import todayMoveInto from "@/views/todayMoveInto/todayMoveInto"


// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

const routes = [{
		path: "/",
		redirect: "/login"
	},
	{
		title:'收藏',
		name:"collection",
		path: "/collection",
		component: collection
	},
	{
		title:'今天入住',
		name:"todayMoveInto",
		path: "/todayMoveInto",
		component: todayMoveInto
	},
	{
		path: "/login",
		component: login
	}, {
		path: "/index",
		component: index
	},
	{
		path: "/privacy",
		component: privacy
	},
	{
		path: "/search",
		component:search
	},
	{
		path: "/agreement",
		component: agreement
	},
	{
		path: "/chat-window",
		component: chatWindow
	}, {
		path: "/chat-contact",
		component: chatContact
	}, {
		path: "/add-friend",
		component: addFriend
	}, {
		path: "/new-friend",
		component: newFriend
	}, {
		path: "/rent",
		component: rent
	}, {
		path: "/rent-detail",
		component: rentDetail
	}, {
		path: "/my",
		component: my
	}, {
		path: "/vr",
		component: vr
	}, {
		path: "/supporting",
		component: supporting
	}, {
		path: "/booking",
		component: booking
	}, {
		path: "/booking-detail",
		component: bookingDetail
	}, {
		path: "/release",
		component: release
	}, {
		path: '/wallet',
		component: wallet
	}, {
		path: '/auth-family',
		component: authFamily
	}, {
		path: '/add-family',
		component: addFamily
	}, {
		path: '/real-name',
		component: realName
	}, {
		path: '/register',
		component: register
	}, {
		path: '/account-management',
		component: accountManagement
	}, {
		path: '/key-management',
		component: keyManagement
	}, {
		path: '/personal-information',
		component: personalInformation
	}, {
		path: '/temporary-visit',
		component: temporaryVisit
	}, {
		path: '/payment-management',
		component: paymentManagement
	}, {
		path: '/modify-payment-password',
		component: modifyPaymentPassword
	},
	{
		path: '/income-bill',
		component: () => import('@/views/income-bill') // 异步引进
	},
	{	
		title:'修改手机号',
		name:"setPhone",
		path: '/setPhone',
		component: () => import('@/views/setPhone/setPhone')
	},
	{
		title:"修改登录密码",
		name:"setPassword",
		path: '/setPassword',
		component: () => import('@/views/setPassword/setPassword')
	},
	{	
		name:"verifi-phone",
		path: '/verifi-phone',
		component: verifiPhone
	}
]

let router = new VueRouter({
	routes
})
export default router;
