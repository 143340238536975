<template>
  <div id="release-container">
    <!--导航条-->
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">发布</span>
      </template>
    </van-nav-bar>
    <div class="step-box">
      <van-steps :active="step" active-color="#26a5f9">
        <van-step>基本信息</van-step>
        <van-step>确定发布</van-step>
      </van-steps>
    </div>
    <div class="release-body">
      <div v-if="step==0" class="first-box">
        <div class="item-box">
          <div class="item" style="margin-top: 7px">
            <div class="text">出租类型</div>
            <div class="option">
              <van-radio-group v-model="type" direction="horizontal">
                <van-radio name="0">整租</van-radio>
                <van-radio name="1">合租</van-radio>
              </van-radio-group>
            </div>
          </div>

          <div class="item">
            <div class="text">性别要求</div>
            <div class="option">
              <van-radio-group v-model="sex" direction="horizontal">
                <van-radio name="-1">不限</van-radio>
                <van-radio name="0">男</van-radio>
                <van-radio name="1">女</van-radio>
              </van-radio-group>
            </div>
          </div>

          <div class="item">
            <div class="text">年龄要求</div>
            <div class="option">
              <div v-if="!(age[0]==0&&age[1]==100)">{{ age[0] }}岁&nbsp;-&nbsp;{{ age[1] }}岁</div>
              <div v-if="age[0]==0&&age[1]==100">不限</div>
            </div>
          </div>
          <van-slider class="age-slider" v-model="age" range/>

          <div class="item">
            <div class="text">交租日期</div>
            <div class="option" @click="showDayPicker=true">
              {{ day }}号
            </div>
          </div>

          <div class="item">
            <div class="text">房租租金</div>
            <div class="option" @click="showDayPicker=true">
              ￥780
            </div>
          </div>

          <div class="item">
            <div class="text">电费</div>
            <div class="option" @click="showDayPicker=true">
              1.5元/度
            </div>
          </div>

          <div class="item">
            <div class="text">水费</div>
            <div class="option" @click="showDayPicker=true">
              8元/方
            </div>
          </div>

          <div class="item">
            <div class="text">其他费用</div>
            <div class="option" @click="showDayPicker=true">
              8元/方
            </div>
          </div>

          <div class="item">
            <div class="text" style="margin: auto 0">缴纳押金</div>
            <div class="option" style="display: flex" @click="showDayPicker=true">
              <div v-if="needDeposit" style="margin: auto 5px">￥1400</div>
              <van-switch v-model="needDeposit"></van-switch>
            </div>
          </div>
        </div>
        <div class="mating-box">
          <div class="title">房间配套</div>
          <div class="item-box">
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/bad.png">
              </div>
              <div class="text">床</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/wardrobe.png">
              </div>
              <div class="text">衣柜</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/Refrigerator.png">
              </div>
              <div class="text">冰箱</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/tv.png">
              </div>
              <div class="text">电视</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/conditioner.png">
              </div>
              <div class="text">空调</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/washing.png">
              </div>
              <div class="text">洗衣机</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/heater.png">
              </div>
              <div class="text">热水器</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/wifi.png">
              </div>
              <div class="text">宽带</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/fire.png">
              </div>
              <div class="text">天然气</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/microwave.png">
              </div>
              <div class="text">微波炉</div>
            </div>
          </div>
        </div>
        <div class="remark-field">
          <van-field ref="remarkInput" v-model="remark" placeholder="请在此输入详细信息" @keydown="onInputRemark"/>
        </div>
        <div class="address-box">
          <div class="text">地址</div>
          <!--          <div class="value">{{address}}</div>-->
          <div class="value">
            <van-field ref="addressInput" v-model="address" @keydown="onInputAddress"></van-field>
          </div>
        </div>
        <div class="button-box">
          <van-button @click="step=1" class="right-button" text="下一步" color="#26a5f9" size="small"/>
        </div>
      </div>
      <div v-if="step==1" class="second-box">
        <div class="photo-box">
          <div class="title">上传图片</div>
          <div class="img-item-box">
            <van-uploader v-model="photoItems" multiple/>
          </div>
        </div>
        <div class="vr-box">
          <div class="title">上传VR图片</div>
          <div class="img-item-box">
            <van-uploader v-model="vrItems" multiple/>
          </div>
        </div>
        <div class="contract-box">
          <div class="title">租赁合同</div>
          <img src="../assets/testContract.png"/>
        </div>
        <div class="button-box">
          <div class="left" @click="step=0">
            <van-button text="上一步" color="#26a5f9" size="small"/>
          </div>
          <div class="right" @click="onJumpBack">
            <van-button text="确定发布" color="#26a5f9" size="small"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {RadioGroup, Radio, Slider, Switch, Uploader} from 'vant';

Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Slider);
Vue.use(Switch);
Vue.use(Uploader);
export default {
  name: "release",
  data() {
    return {
      type: 0,
      sex: -1,
      age: [22, 35],
      day: 15,
      showDayPicker: false,
      needDeposit: false,
      remark: '',
      address: '龙岗区荷坳街道乐城8栋1607',
      step: 0,
      photoItems: [
        {url: 'https://static.store-companion.ikea.cn/static/img/example_a2_2.b7c89859.jpg'}
      ],
      vrItems:[
      ]
    }
  },
  mounted() {

  },
  methods: {
    onClickFallback() {
      this.$router.back();
    },
    onChange(value) {
      console.log(value);
    },
    onInputRemark(key) {
      if (key.keyCode == 13) {
        this.$refs.remarkInput.blur();
      }
    },
    onInputAddress(key) {
      if (key.keyCode == 13) {
        this.$refs.addressInput.blur();
      }
    },
    onJumpBack(){
      this.$router.back();
    }
  }
}
</script>

<style scoped>
#release-container {
  min-height: 100%;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.van-index-anchor {
  background-color: #efefef;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}

::v-deep .van-nav-bar{
  z-index: 2;
}

.step-box >>> .van-steps {
  width: 50%;
  margin: auto;
  font-size: 18px;
}

.step-box >>> .van-step__title, .step-box >>> .van-step__icon {
  font-size: 14px;
}

.release-body {
  margin: 2%;
  padding: 2%;
}

.release-body > .first-box > .item-box > .item {
  display: flex;
  margin: 5% 0;
}

.release-body > .first-box > .item-box > .item > .option {
  margin-left: auto;
}

.release-body > .first-box > .mating-box > .title {
  font-weight: bold;
}

.release-body > .first-box > .mating-box > .item-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 7px;
}

.release-body > .first-box > .mating-box > .item-box > .item {
  width: 20%;
}

.release-body > .first-box > .mating-box > .item-box > .item > .text {
  text-align: center;
  font-size: 12px;
  color: #646566;
}

.release-body > .first-box > .mating-box > .item-box > .item > .img-box {
  width: 40%;
  margin: auto;
}

.release-body > .first-box > .mating-box > .item-box > .item > .img-box > img {
  width: 100%;
}

.age-slider {
  width: 90%;
  margin: 7px auto;
}


.release-body > .first-box > .address-box {
  display: flex;
}

.release-body > .first-box > .address-box .text {
  margin: auto 0;
}

.release-body > .first-box > .address-box .value {
  margin-left: auto;
  width: 80%;
}

.release-body > .first-box > .address-box .value >>> input {
  text-align: right;
}

.release-body > .first-box > .button-box > .right-button {
  display: block;
  margin-left: auto;
  border-radius: 5px;
  width: 8em;
}

.second-box > .photo-box > .title {
  font-weight: bold;
}

::v-deep .van-uploader__upload {
  background-color: #FFFFFF;
}

::v-deep .van-uploader__preview-image,::v-deep .van-uploader__upload{
  width: 100px;
  height: 100px;
}

.second-box > .vr-box > .title{
  font-weight: bold;
}

.second-box > .contract-box >.title{
  font-weight: bold;
}

.second-box > .button-box{
  display: flex;
}

.second-box> .button-box > .left{
  margin-right: auto;
}

.second-box> .button-box > .right{
  margin-left: auto;
}

.second-box> .button-box >>> .van-button{
  border-radius: 5px;
  width: 8em;
}
</style>
