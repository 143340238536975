<template>
  <div id="key-management-container">
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">钥匙管理</span>
      </template>
      <template #right>
        <van-icon @click="showAddPopup=true" name="plus" size="2em" color="#ffffff"/>
      </template>
    </van-nav-bar>

    <div class="item-box">
      <div class="item" v-for="(i,index) in keyItems" :key="index">
        <div class="left">
          <div class="img-box">
            <img src="../assets/icon/key.png"/>
          </div>
        </div>
        <div class="center">
          <div class="name">
            {{ i.name }}
          </div>
          <div class="remark">
            {{ i.no }}
          </div>
        </div>
        <div class="right">
          <div class="button-box">
            <van-button @click="onClickUnbind(index)" text="解除绑定" color="#26a5f9" size="mini"/>
          </div>
        </div>
      </div>
    </div>

    <div class="qr-box">
      <img src="../assets/testQr.png"/>
      <div class="remark">请将本二维码对准旦巢智能门锁摄像头,即可自动开门</div>
    </div>

    <van-popup round v-model="showAddPopup">
      <div class="dialog-box">
        <div class="title">
          <div class="text">添加钥匙</div>
        </div>
        <van-divider/>
        <div class="content">
          <van-field v-model="inputNo" placeholder="请输入旦巢钥匙上铭刻的编码"/>
        </div>
        <van-divider/>
        <div class="bottom">
          <div class="left" @click="showAddPopup=false">
            <div class="text">取消</div>
          </div>
          <div class="right" @click="onClickEnter">
            <div class="text">确定</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {Dialog} from "vant";

export default {
  name: "key-management",
  data() {
    return {
      keyItems: [
        {
          name: '旦巢智能钥匙2020Pro版',
          no: '2020PHJ356X'
        }
      ],
      showAddPopup: false,
      inputNo: '',
    }
  },
  methods: {
    onClickFallback() {
      this.$router.back();
    },
    onClickEnter() {
      this.keyItems.push({name: '旦巢智能钥匙2020Pro版', no: this.inputNo});
      this.inputNo = '';
      this.showAddPopup = false;
    },
    onClickUnbind(index) {
      Dialog.confirm({
        title: '确定解绑本钥匙吗?',
        message: '解绑后可点击本页面\n右上角的加号添加钥匙',
      }).then(() => {
        this.keyItems.splice(index, 1);
      }).catch(() => {

      })
    }
  }
}
</script>

<style scoped>
#key-management-container {
  background-color: #F8F8F8;
  min-height: 100%;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.van-index-anchor {
  background-color: #efefef;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}

.item-box .item {
  background-color: #FFFFFF;
  margin: 2%;
  padding: 3% 2%;
  border-radius: 5px;
  display: flex;
}

.item-box .item .left {
  margin: auto 7px;
}

.item-box .item .img-box {
  width: 2em;
}

.item-box .item .img-box img {
  width: 100%;
}

.item-box .item .center {
  margin: 0 7px;
}

.item-box .item .center .name {
  font-size: 14px;
}

.item-box .item .center .remark {
  font-size: 10px;
  color: #646566;
}

.item-box .item .right {
  margin: auto 0;
  margin-left: auto;
}

.qr-box {
  margin: 2%;
  padding: 2%;
  background-color: #FFFFFF;
  border-radius: 5px;
}

.qr-box img {
  width: 90%;
  display: block;
  margin: auto;
}

.qr-box .remark {
  font-size: 12px;
  text-align: center;
  color: #646566;
}

::v-deep .van-divider {
  margin: 0;
}

.dialog-box {
  width: 250px;
}

.dialog-box .title {
  height: 40px;
  display: flex;
}

.dialog-box .title .text {
  text-align: center;
  margin: auto;
}

.dialog-box .content {
  height: 50px;
}

.dialog-box .content >>> .van-field {
  margin: auto;
}

.dialog-box .bottom {
  height: 40px;
  display: flex;
}

.dialog-box .bottom > * {
  width: 50%;
  text-align: center;
  margin: auto;
}

.dialog-box .bottom .right {
  color: #26a5f9;
}

::v-deep .van-field input {
  text-align: center;
}


</style>
