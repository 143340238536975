<template>
	<div>
		<van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
		  <template #left>
		    <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
		  </template>
		  <template #title>
		    <span class="navbar-title">隐私政策</span>
		  </template>
		</van-nav-bar>
		<div class="content line-hei-22">
			<div class="m-t-20 color-0 font-wei m-b-15">更新日期：2023年01月25日</div>
			<div class="m-t-20 color-0 font-wei m-b-15">生效日期：2023年01月25日</div>
			<div class="m-t-20 color-0 font-wei m-b-15">欢迎您使用旦巢租客端APP！</div>
			<div>
			旦巢租客所提供的各项服务的所有权和运作权均归深圳市旦巢信息技术有限公司（以下简称“旦巢”）所有。
			</div>
			<div>
			深圳市旦巢信息技术有限公司（以下简称“我们”）是“旦巢租客端APP等互联网端口（或称“旦巢平台”）”的平台服务开发者。
			</div>
			<div>
			请您在访问旦巢租客端APP等互联网端口（或称“旦巢平台”）前仔细阅读本隐私政策的全部条款（尤其是以粗体/粗体下划线方式显示的内容），确认您已完全了解并充分理解我们对您个人信息的处理规则，并作出您认为适当的选择。如您不同意协议中的任何条款，您应立即停止访问或使用旦巢平台。
			</div>
			<div>
			旦巢（“我们”）非常重视用户的隐私和个人信息保护，您的信任对我们非常重要，我们将根据法律法规要求并参照行业最佳实践为您的个人信息安全提供充分保障。您使用旦巢的产品与/或服务时，我们可能会收集和使用您的个人信息。我们希望通过《旦巢隐私政策》（“本隐私政策”）向您说明您在使用我们的产品与/或服务时如何如何收集、使用、共享和保护您的个人信息，以及我们为您提供的访问、更新、删除、和保护这些信息的方式。
			</div>
			<div>
			本隐私政策将帮助您了解以下内容：
			</div>
<!-- 			
			<div class="m-t-30 color-0 font-wei" >一、我们如何收集和使用您的个人信息</div>
			<div class="m-t-30 color-0 font-wei" >二、我们如何共享、转让、公开披露您的个人信息</div>
			<div class="m-t-30 color-0 font-wei" >三、我们如何保存和保护您的个人信息</div>
			<div class="m-t-30 color-0 font-wei" >四、关于第三方插件管理</div>
			<div class="m-t-30 color-0 font-wei" >五、您如何管理您的个人信息</div>
			<div class="m-t-30 color-0 font-wei" >六、未成年人的个人信息保护</div>
			<div class="m-t-30 color-0 font-wei" >七、通知和修订</div>
			<div class="m-t-30 color-0 font-wei" >八、如何联系我们</div> -->
			<div class="m-t-30 color-0 font-wei" >一、我们如何收集和使用您的个人信息</div>
			<div>
			个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特别自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括姓名、出生日期、证件号码、个人生物识别信息、通信通讯联系方式、住址、账户信息、财产状况、行踪轨迹等。
			
			<span class="m-t-30 color-0 font-wei">其中一旦泄露、非法提供或滥用可能危害人身或财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的信息为个人敏感信息，主要包括：证件号码、个人生物识别信息、银行账户、征信信息、财产信息、交易信息、行踪轨迹、健康生理信息等。</span>
		
			我们收集使用您的个人信息将遵循合法、正当、必要的原则，按照法律法规要求以及产品与/或服务需要收集您的个人信息。我们仅会出于以下目的，收集和使用您的个人信息：
			</div>
			<div class="m-t-30 color-0 font-wei">
			（一）您需要授权我们收集和使用个人信息的场景
			</div>
	        <div>
<span class="m-t-30 color-0 font-wei">1、帮助您成为我们的用户</span>
			</div>
			<div>
			为了向您提供我们产品与/或服务的基本功能，我们需要收集、保存和使用下列与您有关的个人信息，如果您拒绝提供相关信息，您可能无法正常使用我们的产品与/或服务。这些功能包括：
			</div>
			<div>
			在您注册成为旦巢用户时，您需要使用您的手机号码注册账户，并完善相关的网络身份识别信息（如头像、昵称及登录密码等）， 我们将通过发送短信验证码的方式来验证您的身份是否有效。如果您仅需使用浏览、搜索等功能，您无需注册成为我们的用户以及提供上述信息。 在提供账号信息的过程中，如果您愿意额外补充如下个人信息，将有助于我们向您提供更为个性化的服务：包括您的性别、生日、常居地、家乡、喜好、家庭信息等。如果您不提供该等信息，不会影响您享受旦巢服务的基本功能。
			</div>
			<div>
			经您同意后，我们可能向第三方共享您的账户信息（头像、昵称及其他页面提示的信息），使您可以便捷地实现第三方账户的注册或登录。此外，我们可能会根据您的授权从第三方获取您共享的账户信息，并与您的旦巢账户进行绑定，使您可通过第三方账户直接登录、使用我们的产品与/或服务。我们将在您授权同意的范围内使用您的相关信息。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">2、附加用户服务</span>
			</div>
			<div>
			实人认证：您需要根据认证要求向我们提供相应的身份信息（身份证、护照、户口本及其他身份证件信息）、银行卡信息、生物识别特征（静态或动态的面部特征）以完成实人认证。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">3、账户信息展示</span>
			</div>
			<div>
			如果您已拥有旦巢账户，我们可能会在旦巢服务中显示您的上述个人信息（实人认证实名认证仅显示认证是否通过的结果），以及您在旦巢账户相关联的产品与/或服务中执行的操作，包括通过旦巢账户集中展示您的个人资料、钱包、会员权益、优惠、交易订单。我们会尊重您对旦巢服务和旦巢账户设置所做的选择。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">4、向您提供基于地理位置的信息展示
</span>
			</div>
			<div>
			为了向您提供所在城市或周边的房源信息展示和搜索服务，提升我们的服务表现、效率和功能，经您授权我们会收集您在使用我们服务过程中产生的相关信息，包括：
			</div>
			<div>
			<span class="m-t-30 color-0 font-wei">（1）位置信息：当您通过系统授权开启移动设备的定位功能并使用基于位置提供的服务时，我们会收集和使用您的位置信息以便为您推荐周边的生活服务</span>
（ 例如：您不需要手动输入所在地理位置就可获得相关服务，估算门店与您之间的实际距离方便您进行租房决策，为您推荐附近的房源信息）。我们会使用有关技术获取您的位置信息（准确度会有所不同），这些技术包括 IP 地址、GPS 以及能够提供相关信息的WLAN（如Wi-Fi） 接入点、蓝牙和基站等。
<span class="m-t-30 color-0 font-wei">您使用服务中提供的信息中可能包含相应地理位置信息，例如您在账号资料中设置的地区信息或您上传的图片、视频中的地理标记信息。您可以在移动设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但可能因此无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。</span>
			</div>
			<div>
	<span class="m-t-30 color-0 font-wei">（2）日志信息：</span>
			当您使用我们提供的租房/服务时，我们会收集您的浏览、搜索、点击、收藏、添加购物车、交易、售后、关注、分享、发布等信息并作为有关网络日志进行保存，其中包括您的 IP地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、电信运营商、网络请求等。
			</div>
<div>
<span class="m-t-30 color-0 font-wei">5、为您提供预约、预订、入住、续租、换房、转租、退租功能
</span>
			</div>
			<div>
您需要根据所选择的功能类型提供联系人信息（姓名、性别、电话号码等）、地址信息、服务偏好等。
			</div>
			<div>
您可能需要根据有关法律规定和相关方要求（电子合同、金融监管机构等）提供您的<span class="m-t-30 color-0 font-wei">实名身份信息（包括但不限于您的身份证、军官证、护照、驾驶证、学生证等载明您身份的证件照片、复印件、号码等）、联系人信息（姓名、性别、电话号码等）、 电子邮箱地址（用于确认订单信息等）、个人照片、合租人信息、紧急联络人信息</span>等。

			</div>
			<div>
为向您展示您账户的订单信息及便于您对订单信息的管理，我们会收集您在使用我们服务过程中产生的订单信息。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">您在旦巢上生成的订单中，将可能包含您的身份信息、联络信息、房间信息、支付信息等，这些都属于敏感信息，请您谨慎向他人展示或对外提供</span>，我们也将会在展示订单信息时，在保证信息可用性的基础上尽量去标识化处理。
			</div>
	        <div>
<span class="m-t-30 color-0 font-wei">6、为您提供开启智能门锁、门禁功能
</span>
			</div>
			<div>
我们会收集您的个人信息类型包括：<span class="m-t-30 color-0 font-wei">面部识别特征（个人敏感信息）、您设定的门禁密码。</span>
收集这些信息是为了帮助您可以顺利进入您租住的房间。
			</div>
           <div>
<span class="m-t-30 color-0 font-wei">7、为您提供电子合同签署功能
</span>
			</div>
			<div>
我们会收集的个人信息类型：
			</div>
           <div>
<span class="m-t-30 color-0 font-wei">1) 身份证号码（个人敏感信息）
</span>
			</div>
 <div>
<span class="m-t-30 color-0 font-wei">2）姓名（个人敏感信息）
</span>
			</div>
 <div>
<span class="m-t-30 color-0 font-wei">3）电话号码（个人敏感信息）
</span>
			</div>
 <div>
<span class="m-t-30 color-0 font-wei">4）紧急联系人信息，包含姓名和电话以及与本人关系
</span>
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">5）公司信息（营业执照及联系人信息）（敏感信息）
</span>
			</div>
			<div>
收集这些信息是为了使您在我方平台签署电子合同。如您是个人用户仅需提供1）、2）、3）、4）项；如您是企业用户需提供第5项信息。
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">8、开通相关权益、参与优惠活动功能
</span>
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">1）公司信息（名称、所在地址、行业方向）（敏感信息）
</span>
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">2）学历信息（学历、就读学校、入学年份、毕业年份、教育类型）（个人敏感信息）
</span>
			</div>
			<div>
收集这些信息是为了帮助您进行开通相关权益、参与相关优惠活动、免押金、免服务费活动，如您无需获得上述权益，可无需提供上述信息。不提供上述信息不妨碍您使用我方平台核心功能。
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">9、安全风险防范功能
</span>
			</div>
			<div>
我们会收集的个人信息类型：
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">1) 身份证号码（个人敏感信息）
</span>
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">2）姓名（个人敏感信息）
</span>
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">3）电话号码（个人敏感信息）
</span>
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">4）银行卡信息（个人敏感信息）
</span>
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">5）学历信息（学历、就读学校、入学年份、毕业年份、教育类型）（个人敏感信息）
</span>
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">6）公司信息（营业执照、联系人信息、名称、所在地址、行业方向）（敏感信息）
</span>
			</div>
			<div>
收集这些信息是为了进行签约用户的欺诈防范、风险评估，为了配合住建委、房管局的合规监管。在法律允许的情况下，我们会采用自动化的方式审查或分析信息。这些活动的实施是基于确保旦巢遵守适用法律，防止欺诈、促进安全，确保我们充分履行对您的服务。旦巢不会因为需要向您发送第三方营销信息而审查、浏览或分析您的通信，也不会出售对这些通信的审查或分析。
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">10、为您提供安全保障
</span>
			</div>
			<div>
为了保障您的账号安全、交易安全以及系统运行安全，满足法律法规和我们协议规则的相关要求，在您使用我们的产品与/或服务过程中，经您授权我们会获取您的设备信息，<span class="m-t-30 color-0 font-wei">包括您使用的设备属性、连接和状态信息，例如设备型号、设备标识符（如IMEI/androidID/IDFA/OPENUDID/GUID/OAID、SIM卡IMSI、ICCID信息等）、设备MAC地址、软件列表、电信运营商等软硬件特征信息。</span>

			</div>
			<div>为提高您使用我们提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会使用或整合您的个人信息以及我们的关联方、合作伙伴取得您授权或者依法共享的信息，根据您的使用习惯和常用软件信息等来综合判断您的账号及交易风险，包括验证身份，预防、发现、调查可能存在的欺诈、网络病毒、网络攻击等安全风险以及违反我们或关联方协议、政策或规则等行为，以保护您、其他用户、我们或关联方的合法权益，并记录一些我们认为有风险的链接(“URL”)。
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">11、我们可能间接收集的个人信息
</span>
			</div>
			<div>您使用我方的关联方、我方合作伙伴的产品与/或服务，与我方产品与/或服务之间交互的行为信息。
			</div>
			<div>为了给您提供更好、更优、更加个性化的服务，或共同为您提供服务，或为了预防互联网欺诈的目的，我方的关 联方、合作伙伴会依据法律的规定或与您的约定的前提下，向我方分享您的个人信息。
			</div>
			<div>当您通过我们产品与/或服务使用上述服务时，您授权我们根据实际业务及合作需要，从我们关联公司处接收、汇 总、分析我们确认其来源合法或您授权同意其向我们提供的您的个人信息或交易信息。我们承诺，我们已对上述个 人信息的来源的合法性进行了确认。
			</div>
			<div class="m-t-30 color-0 font-wei">
			（二）您可以选择授权我们收集和使用个人信息的场景
			</div>
			<div>
我们会根据您在软件安装及使用中授予的具体权限，接收并记录您的浏览器和计算机上、移动设备的相关信息，您 可以在旦巢APP通过“系统的设置->通用->系统权限管理”中逐项查看设备权限的开启状态，并可以决定将这些权限 随时的开启或关闭。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功 能，您关闭权限即代表您取消这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供下述与 这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。
			</div>
			<div>
具体包括：
			</div>
			<div>
1、为了保障软件与服务的安全运行、运营的质量及效率，保障用户交易安全、我们可能会自动收集您的设备所在位 置相关信息（包括您授权的GPS位置以及蓝牙和基站等传感器信息）；以及设备相关信息（包括设备型号、操作系 统版本、唯一设备标识符、登录IP地址、MAC地址、移动应用列表信息）。			   </div>
			<div>
2、为了用于判断您的账户安全、检测及防范安全事件，当您使用我们的网站或客户端提供的产品与/或服务时，我们会 自动收集您对我们服务的详细使用情况，作为有关网络日志保存。包括您的搜索查询内容、浏览记录、访问日期和 时间及您访问的网页记录。
		   </div>
3、为了向您展示您感兴趣的房源、商品或服务信息或在您搜索时向您展示您可能希望找到的房源。我们会收集您的 订单信息、看房记录、浏览信息、您的设备位置信息，为您推荐您所在区域可以浏览的房源、可购买的商品或服 务，或向您推荐离您最近的可租房源。例如，在首页信息流标注的“推荐”所列举的推荐房源信息。若您想管理 我们给您发送的个性化推荐的房源、商品和服务信息，您可以在“我的-编辑资料”中进行查阅。需注意的 是，您可不予设置您的爱好特征，但是您仍然会在首页信息流或“推荐”板块内看到房源信息，且房源信息的数量不会变化，只不过房源信息的相关性会下降。如果您不提供这些个人信息，您依然可以使用旦巢的产品与/或服务中的核心功能。
		   </div>
			<div>
4、为了使您可以完成拍摄、拍照、扫码以及实现面部识别特征识别登录、在线交易时提供面部识别特征，我们会在 系统界面提示您开启摄像头 （相机）权限。
		   </div>
			<div>
5、为了使您可以将旦巢中的优质房源信息通过微信、QQ第三方通讯工具发送给您的好友，我们会使用基于您发送 信息的附加功能。
 		   </div>
			<div>
6、当您对旦巢的优质房源感兴趣，需要房源页面专属功能拨打电话联系旦巢管家或者在线咨询功能联系旦巢管家 时，我们会提示您开启通讯录设备访问权限。我们会收集并使用加密功能隐藏您的真实电话号码，旦巢管家会通过 该功能进行电话回拨。我们会对您与旦巢管家沟通的过程进行录音或文字留存，该留存信息仅会在法律所允许的存 储期限和范围内予以留存和处理，并仅在基于您的维权需要、政府部门及司法部门的调查指令需要提供时，我们会 进行必要披露。		   </div>
			<div>
7、实现相关业务功能可能开启您的设备访问权限的逐项列举：点击此处查看相关业务功能及设备权限目录 请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息 结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人 信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
		   </div>
			<div class="m-t-30 color-0 font-wei">
				（三）其他
			</div>
            <div>
<span class="m-t-30 color-0 font-wei">您可以通过旦巢平台为其他人办理预定、入住等业务动作，并提供该实际服务接收人的有关信息，此前请您确保已取得相关人的授权。
</span>
			</div>
			<div>
如果我们将信息用于本隐私政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会单独征求您的授权同意。
			</div>
			<div class="m-t-30 color-0 font-wei">
			（四）征得同意的例外
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">请您知悉，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</span>
			</div>
			<div>
			1、与国家安全、国防安全有关的；
			</div>
			<div>
			2、与公共安全、公共卫生、重大公共利益有关的；
			</div>
			<div>
			3、与犯罪侦查、起诉、审判和判决执行等有关的；
			</div>
			<div>
			4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
			</div>
			<div>
			5、所收集的个人信息是个人信息主体自行向社会公众公开的；
			</div>
			<div>
			6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；但是您明确拒绝或者处理该信息侵害您重大利益的除外。			        </div>
			<div>
			7、根据您的要求签订合同所必需的；
			</div>
			<div>
			8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
			</div>
			<div>
			9、为合法的新闻报道所必需的；
			</div>
			<div>
			10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
			</div>
		    <div>
11、法律法规规定的其他情形。
			</div>
		    <div>
请注意，单独或与其他信息相结合无法识别您的身份或者与您直接建立联系的信息，不属于个人信息。如果我们将单独无法与任何特定个人建立联系的信息与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。
			</div>

			<div class="m-t-30 color-0 font-wei">
			（五）个人信息的使用规则
			</div>
			<div>
			1、我们会根据本隐私政策的约定，在实现我们的产品与/或服务功能所必要的范围内，对所收集的个人信息进行使用。
			</div>
			<div>
2、在收集您的个人信息后，我们可能将通过技术手段对部分数据进行匿名化处理，匿名化处理的信息将无法识别主体。<span class="m-t-30 color-0 font-wei">请您了解并同意，在此情况下我们有权使用已经匿名化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span>
 			</div>
			<div>
<span class="m-t-30 color-0 font-wei">请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除、通过系统设置或联系我们等方式撤回您的同意，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账户时，我们将停止使用并删除您的个人信息。</span>
			</div>
			<div>
3、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
			</div>
			<div>
			4、当我们展示您的个人信息时，我们会采用包括内容替换、去标识化处理等方式对您的信息进行脱敏，以保护您的信息安全。
			</div>
			<div>
			5、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会要求您通过主动勾选的形式事先征求您的同意。
			</div>
			<div class="m-t-30 color-0 font-wei">
			二、我们如何共享、转让、公开披露您的个人信息
			</div>
<div class="m-t-30 color-0 font-wei">
			（一）共享
			</div>

			<div>
			我们不会与旦巢以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
			</div>
			<div>
			1、获得您明确同意或授权的共享。
			</div>
			<div>
			2、基于法定情形下：根据法律法规的规定、诉讼争议解决需要，或行政、司法等有权机关依法提出的要求。
			</div>
			<div>
			3、与关联公司共享：为向您提供一致化服务以及便于您进行统一管理，我们可能会将您的个人信息与我们的关联公司共享。但我们只会共享必要的个人信息，如果我们共享您的个人敏感信息或者关联方改变个人信息的使用目的，将再次征求您的授权同意。

			</div>
			<div>
			4、与合作伙伴分享：我们可能会向业务合作伙伴共享为您提供服务所必要的订单信息、账户信息、支付信息等。我们的业务合作伙伴包括以下类型：		  	    </div>
			<div>
			供应商/服务合作商。仅为实现本隐私政策中声明的目的，我们的某些服务将由业务合作伙伴提供。以保障为您提供的服务顺利完成，我们可能会将您的个人信息共享给上述合作伙伴，包括配送业务、技术服务、支付服务、金融业务等。其中可能包括您的联络信息、订单信息、支付信息、地址信息等，以保障为您提供的服务顺利完成。例如在您使用我们的外卖服务时，我们必须与配送服务合作商共享您的订单和配送信息，以保证订单的安全准确送达；又如我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。<span class="m-t-30 color-0 font-wei"> 其中您的联络信息和地址信息属于个人敏感信息，我们已经采取了默认开启的“号码保护”、单据上的“地址隐藏”等举措尽全力保护您的个人信息。</span>
			</div>
			<div>
			第三方商家。我们必须将您的订单信息、必要交易信息与第三方商家共享，以便其向您提供商品、服务及售后等。
			</div>
			<div>
			广告、分析服务类等业务合作伙伴。经您授权，我们可能会与提供广告投放推广和统计分析的合作伙伴共享信息，但我们仅会向这些合作伙伴提供广告推广的覆盖面、有效性以及统计类等信息，而不会共享可以识别您身份的个人信息，否则我们会对这些信息进行去标识化处理以避免对您身份的识别。相关合作伙伴可能会对上述信息进行汇聚分析，用于提供广告曝光监测或服务决策。
			</div>
			<div>
			5、基于协议约定：依据您与我们签署的相关协议(包括在线签署的电子协议及平台规则)或法律文件，有必要向第三方共享时。
			</div>
			<div>
			6、基于合理商业习惯：例如与第三方共享联合营销活动中的中奖信息，以便第三方能及时向您发放奖品；或者在我们计划与其他公司进行资本市场活动(包括但不限于IPO，债券发行)接受尽职调查时。
			</div>
			<div>
			7、基于学术研究；例如为科研机构开展统计或学术研究之必要。
			</div>
			<div>
			8、基于符合法律法规的社会公共利益等。
			</div>
			<div>
			我们会通过应用程序接口（API）、软件工具开发包（SDK）与第三方进行必要的数据共享，不同版本的第三方SDK会有所不同，一般包括第三方账号登录类、分享类、第三方支付类、地图导航类、厂商推送类、统计类等，我们会对第三方进行严格的安全检测并约定数据保护措施。
			</div>
			<div>
			请您知悉，我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，对我们仅为实现本隐私政策中声明的目的与之共享个人信息的公司、组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息。
			</div>
<div class="m-t-30 color-0 font-wei">
			（二）转让
			</div>
			<div>
			随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似的交易，如涉及个人信息的转让，我们会要求受让您个人信息的公司、组织继续接受本隐私政策的约束，否则,我们将要求该公司、组织重新征求您的授权同意。
			</div>
			<div class="m-t-30 color-0 font-wei">
		（三）公开披露
			</div>
			<div>
			我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才可能公开披露您的个人信息：
			</div>
			<div>
			1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
			</div>
			<div>
2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">请注意，您在使用我们服务时自愿发布甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您的交易信息，以及您在评价时选择上传包含个人信息的文字、图片或视频等各种形式的信息。请您在使用我们的服务时更加谨慎地考虑，是否要发布甚至公开分享相关信息。</span>
			</div>
			<div class="m-t-30 color-0 font-wei">
			（四）共享、转让、公开披露个人信息时事先征得授权同意的例外
			</div>
			<div>
		以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
			</div>
			<div>
			1、与国家安全、国防安全有关的；
			</div>
			<div>
			2、与公共安全、公共卫生、重大公共利益有关的；
			</div>
			<div>
		    3、与犯罪侦查、起诉、审判和判决执行等有关的；
			</div>
			<div>
		   4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
			</div>
			<div>
			5、其他维护公共利益的情形，例如您的信用评价信息需要被公开/共享；
			</div>
			<div>
			6、您自行向社会公众公开的个人信息；
			</div>
			<div>  
            7、 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。但是您明确拒绝或者处理该信息侵害您重大利益的除外。
			</div>
			<div>根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，我们对此类数据的处理将无需另行向您通知并征得您的同意。
</div>
			<div class="m-t-30 color-0 font-wei">
			三、我们如何保存和保护您的个人信息
			</div>
			<div class="m-t-30 color-0 font-wei">
		（一）个人信息的保存
			</div>

			<div>
			1、保存期限：除非依据法律法规或双方约定，我们仅会在实现目的所必需的最短时间内留存您的相关个人信息。在您主动注销账号时，我们将根据法律法规的要求尽快删除您的个人信息或匿名化处理，有关注销账号的相关规则和流程。
			</div>
			<div>
			2、保存地域：我们在中华人民共和国境内收集和产生的个人信息，将存储在中国境内，但以下情形除外：
			</div>
			<div>
			法律法规有明确规定的；
			</div>
			<div>
			单独征得您的授权同意；
			</div>
			<div>
			您主动发起的跨境预定、下单、交易等个人行为（如购买国际机票、预订国际酒店等）。
			</div>
			<div>
			在上述情形中，我们会并要求数据接收方按照本隐私政策以及其他相关的安全保密措施来处理个人信息。
			</div>
			<div>
			3、终止运营：如果发生终止运营等情形，我们将会至少提前30天通知您，并在终止运营后对您的个人信息进行删除或匿名化处理。
			</div>
			
			<div class="m-t-30 color-0 font-wei">
			（二）个人信息的保护措施
</div>
			<div>
			1、数据安全措施
			</div>
			<div>
			为保障您的信息安全，我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如在您的浏览器与“服务”之间交换数据(如信用卡信息)时采用SSL加密保护技术;同时对网站本身提供https安全浏览方式;使用加密技术确保数据的保密性;使用受信赖的保护机制防止数据遭到恶意攻击;部署访问控制机制，确保只有授权人员才可访问个人信息;以及举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
			</div>
			<div>
			2、安全认证
			</div>
			<div>
			我们会先后通过了公安部信息安全等级保护三级认证、ISO27001信息安全认证、第三方支付行业（支付卡行业PCI DSS)数据安全标准国际认证等认证资质，并与监管机构、第三方测评机构建立了良好的协调沟通机制，及时抵御并处置各类信息安全威胁，为您的信息安全提供全方位保障。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">3、请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账户或密码发生泄露，请您立即根据本隐私政策中提供的联系方式联络我们，以便我们采取相应措施。</span>
			</div>
			<div>
			4、安全事件
			</div>
			<div>
			在不幸发生个人信息安全事件后，我们会立即成立专项应急小组，启动应急预案，防止安全事件扩大，并按照法律法规的要求及时向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
			</div>
			<div class="m-t-30 color-0 font-wei">
			四、关于第三方插件管理
</div>
			为了给您提供更专业、安全的核心业务功能和附加业务功能，我们的产品中会接入第三方插件产品（包括在我们的网页或应用程序中部署统计分析工具、软件开发工具包SDK、调用地图API接口）。
			<div>
<span class="m-t-30 color-0 font-wei">目前我们系统产品中存在的第三方插件披露如下：</span>
			</div>
			<div>
			1、百度地图（baidu_map）收集您的设备信息、位置信息以及网络状态，为您提供地图找房、AI找房以及其他根据位置找房的房源信息服务；北京百度网讯科技有限公司https://map.baidu.com/zt/client/privacy/index.html
			</div>
			<div>
			2、高德地图（amap）收集您的设备信息、位置信息以及网络状态，为您提供地图找房、AI找房以及其他根据位置找房的房源信息服务；高德软件有限公司 https://lbs.amap.com/pages/privacy/
			</div>
			<div>
3、微信SDK（tencent.wechat）收集您的网络状态信息，以根据您的指令实现房源信息的分享、微信支付功能；深圳市腾讯计算机系统有限公司https://open.weixin.qq.com/
			</div>
			<div>
			4、支付宝SDK（tencent.wechat）收集您的网络状态信息，以根据您的指令实现房源信息的分享、支付宝支付功能；支付宝（中国）网络技术有限公司 https://render.alipay.com/p/c/k2cx0tg8
			</div>
			<div>
			以上插件会根据产品功能收集您的个人信息，我们仅会在您授权同意开通相关功能或在您主动使用相关功能我们给予充分提示时，才会将您的对应个人信息接口授权给上述第三方插件。
			</div>


			<div class="m-t-30 color-0 font-wei">
			五、您如何管理您的个人信息
</div>
			<div>
			我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">1、访问和更正您的个人信息</span>
			</div>
			<div>
除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：
			</div>
			<div>
（1）您的账户信息：具体路径为：账户名称、个人资料信息：首页--“我的”进入我的--点击“设置”按钮进入账户设置；账户密码、电话号码、安全信息：首页--“我的”进入我的--点击“设置”按钮进入账户设置，您也可通过上述途径更新除实名认证信息之外的其他个人信息（您的实名认证信息是您通过实名认证时使用的姓名和身份证信息）。
			</div>
			<div>
（2）对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
			</div>
			<div>
（3）您无法访问和更正的个人信息：除上述列明的信息外，考虑到不响应请求可能给您合法权益带来的风险和损害、技术可行性及实现请求成本等因素，您的部分个人信息我们还无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用附加功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">2、删除您的个人信息</span>
			</div>
			<div>
您在我们的产品与/或服务页面中可以直接清除或删除的信息；
			</div>
			<div>
在以下情形中，您可以向我们提出删除个人信息的请求：
			</div>
			<div>
（1）如果我们处理个人信息的行为违反法律法规；
			</div>
			<div>
（2）如果我们收集、使用您的个人信息，却未征得您的同意；
			</div>
			<div>
（3）如果我们处理个人信息的行为违反了与您的约定；
			</div>


			<div>
（4）如果您注销了旦巢账户；
			</div>
			<div>
（5）如果我们终止服务及运营。
			</div>
			<div>
若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">3、改变您授权同意的范围或撤回您的授权</span>
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">您可以通过删除信息、关闭设备功能、在旦巢APP中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集、处理您个人信息的全部授权。
</span>
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
</span>
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">4、注销账户</span>
			</div>
			<div>
您可以在我们的旦巢APP个人中心—注销账号申请注销账户。您注销账户后，我们将停止为您提供产品与/或服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息。
			</div>
			<div>
您的账户一旦注销，将面临如下风险及问题：
			</div>
			<div>
（1）账户在旦巢系统中尚有资产或虚拟权益（包括但不限于账户余额、虚拟货币、优惠券等）且您在申请账户注销前未予处理的，相关记录可能被删除且无法恢复。因此，我们建议您在申请注销账户前，妥当处理您的账户在旦巢系统中的资产或虚拟权益，例如将账户余额提现至银行卡等；
			</div>
			<div>
（2）账户内未完成的订单、服务会因您账户注销而无法继续执行，如您已完成支付义务，已支付的权益有可能无法返还。因此，我们建议您在申请注销账户前，确认账户内是否仍然存在未完成的订单、服务（特别是那些已完成支付义务但尚未最终交付的订单、服务）；
			</div>
			<div>
（3）如您的账户因违法违规操作或侵犯他人合法权益导致处罚或纠纷，或者我们为履行反洗钱等法律义务，我们将在法律允许的必要的期限内保留处理此处罚、纠纷或投诉所必要的信息，以及履行反洗钱等法律义务所必要的信息，并在该等期限届满后对相关个人信息进行删除或匿名化处理。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">5、如果您不想接受我们给您发送的促销信息，您随时可通过以下方式取消：</span>
			</div>

			<div>
（1）您可以随时回复“TD”来取消我们给您发送的手机促销短信。
			</div>
			<div>
（2）您可以通过旦巢APP“账户设置-设置-推送消息设置-通知”设置是否接受我们通过“通知”推动给您的商品和促销信息。
			</div>
			<div>
（3）我们会与第三方的平台或媒体（“平台”）合作基于您的个人信息向您推荐个性化的商品或服务。这些个人信息包括诸如在本网站的产品与/或服务的使用情况、访问本网站关联公司网站的情况及您在您的账户设置中填写的兴趣爱好。平台仅向我们提供了展示商品的窗口，窗口内容的链接是旦巢平台内的个性化商品展示信息，由旦巢进行管理，因此我们不会向广告商提供您的任何的个人信息。而且我们在推荐窗内设置了屏蔽功能，您可选择屏蔽您不喜欢的广告。如您不愿意接受旦巢在单个平台上的推荐服务，请联系平台进行关闭。您知晓并理解平台是自主经营的实体，旦巢无法对其进行管理。
			</div>
			<div>
（4）为了保护您的隐私，我们不会以任何方式和途径向您推送涉及宗教信仰、性、疾病等相关敏感内容的促销或商品信息给您。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">6、响应您的请求</span>
			</div>
			<div>
如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息，或您认为我们存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以本协议下方的其他方式与我们联系。
			</div>
			<div>
为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的15日内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">在以下情形中，按照法律法规要求，我们将可能无法响应您的请求：</span>
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">（1）与国家安全、国防安全直接相关的；
</span>
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">（2）与公共安全、公共卫生、重大公共利益直接相关的；</span>
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">（3）与犯罪侦查、起诉、审判和执行判决等直接相关的；</span>
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">（4）有充分证据表明您存在主观恶意或滥用权利的；</span>
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span>
			</div>
			<div>
<span class="m-t-30 color-0 font-wei">（6）涉及商业秘密的。</span>
			</div>

			<div class="m-t-30 color-0 font-wei">
六、未成年人的个人信息保护</div>
			<div>
旦巢非常重视对未成年人个人信息的保护。我们的网站和服务主要面向成人。
<span class="m-t-30 color-0 font-wei">如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
</span>
			</div>
对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
			<div>
<span class="m-t-30 color-0 font-wei">如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</span>
			</div>

			<div class="m-t-30 color-0 font-wei">
			七、通知和修订</div>
			为给您提供更好的服务，我们的业务将不时变化，本隐私政策也将随之调整。未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在我们网站、移动端上发出更新版本或以其他方式提醒您相关内容的更新，也请您访问我们以便及时了解最新的隐私政策。在前述情况下，若您继续使用我们的服务，即表示同意接受修订后的本隐私政策并受之约束。
			<div class="m-t-30 color-0 font-wei">
八、如何联系我们</div>
			<div>
您可以通过以下方式与我们联系，我们将在15天内答复您的请求；
			</div>
			<div>
1、您可以通过旦巢App上提供的在线联系方式/客服系统与我们联系；
			</div>

			<div>
2、您可以联系旦巢的客服电话进行反馈；
			</div>

		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				
			}
		},
		methods:{
			onClickFallback() {
			  this.$router.back();
			},
		}
	}
</script>

<style scoped>
	.m-l-15{
		margin-left: 15px;
	}
	.m-b-15{
		margin-bottom: 15px;
	}
	.line-hei-22{
		line-height: 24px;
	}
	.content{
		color: #969696;
		padding: 15px;
	}
	.color-0{
		color: #000000;
	}
	.m-t-30{
		margin-top: 30px;
	}
	.m-t-15{
		margin-top: 15px;
	}
	.font-wei{
		font-weight: bold;
	}
</style>