import Vue from 'vue'
import App from './App.vue'
import GoEasy from 'goeasy';

import router from './router'
import store from './store'
import echarts from 'echarts'
import BaiduMap from 'vue-baidu-map'
import VueGeolocation from 'vue-browser-geolocation'
import axios from './utils/request/axios.js'
import VueTouch from "vue-touch";
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

document.addEventListener('plusready', function () {
    // eslint-disable-next-line no-undef
    var webview = plus.webview.currentWebview();
    var first = null;
    // eslint-disable-next-line no-undef
    plus.key.addEventListener('backbutton', function () {
        webview.canBack(function (e) {
            if (e.canBack) {
                webview.back(-1);
            } else {
                //首页返回键处理
                //处理逻辑：3秒内，连续两次按返回键，则退出应用；

                //首次按键，提示‘再按一次退出应用’
                if (new Date().getTime() - first < 3000) {
                    // console.log("关闭程序")
                    // eslint-disable-next-line no-undef
                    plus.runtime.quit();
                } else {
                    //自定义的弹窗 （引入的vant.js 的toast）

                    // // eslint-disable-next-line no-undef
                    // Toast({
                    //     message: '再按一次退出应用',
                    //     position: 'bottom'
                    // })
                    // first = new Date().getTime();
                    // eslint-disable-next-line no-undef
                    plus.runtime.quit();
                }
            }
        })
    });
});

Vue.prototype.$echarts = echarts
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: 'HsokRrR2m8zGqAKZh0odOCvv8sCDUOVN'
});
Vue.use(VueGeolocation);
Vue.prototype.$axios = axios
Vue.use(VueTouch, {name: "v-touch"});
Vue.use(VueAwesomeSwiper)

const goEasy = GoEasy.getInstance({
	host:'hangzhou.goeasy.io',//应用所在的区域地址: 【hangzhou.goeasy.io |singapore.goeasy.io】
	appkey:'BC-1b686402c8c14f2f92df37c72712ff25',	// common key,
    modules:['im','pubsub'],
	// true表示支持通知栏提醒，false则表示不需要通知栏提醒
    allowNotification: true //仅有效于app,小程序和H5将会被自动忽略
});

goEasy.onClickNotification((message) => {
	let currentUrl;
	const routes = Vue.$route.path();

	if ( routes &&  routes.length ) {
		const curRoute = routes[routes.length - 1].route;
		const curParam = routes[routes.length - 1].options;
		currentUrl = '/' + curRoute + `?to=${curParam.to}`;
	}

    let newUrl;
    switch(message.toType) {
        case GoEasy.IM_SCENE.PRIVATE:
            newUrl = '/chat-window?to=' + message.senderId;
            break;
        case GoEasy.IM_SCENE.GROUP:
            newUrl = '/chat-window?to=' + message.groupId;
            break;
    }

    if (currentUrl !== newUrl) {
        Vue.$router.push(newUrl)
    }

});

Vue.prototype.GoEasy = GoEasy;
Vue.prototype.goEasy = goEasy;
Vue.prototype.formatDate = function (t) {
    t = t || Date.now();
    let time = new Date(t);
    let str = time.getMonth() < 9 ? ('0' + (time.getMonth() + 1)) : (time.getMonth() + 1);
    str += '-';
    str += time.getDate() < 10 ? ('0' + time.getDate()) : time.getDate();
    str += ' ';
    str += time.getHours();
    str += ':';
    str += time.getMinutes() < 10 ? ('0' + time.getMinutes()) : time.getMinutes();
    return str;
};

import './assets/font/SourceHanSansCN-Normal.css';

import './assets/less/global.less';

//使用
new Vue({
    el: '#app',
    router,

    store,
    components: {
        App
    },
    template: '<App/>'
})
