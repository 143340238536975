<template>
	<div>
		<!--导航条-->
		<van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
			<template #left>
				<van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff" />
			</template>
			<template #title>
				<span class="navbar-title">收藏</span>
			</template>
		</van-nav-bar>

		<!-- 住房列表 -->
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="listLoadMore"
				class="van-clearfix">
				<div v-for="(item, index) in homeList" :key="index" class="homeList">
					<div class="home-info" @click="checkDetail(item.houseId)">
						<div class="room-haoma">{{item.roomNum}}</div>
						<div class="home-pic">
							<image :src="item.houseImg" lazy-load="true"></image>
						</div>
						<div class="home-detail">
							<div class="homeName">
								<span style="font-size: 30upx;">{{ item.houseName || '1房1厅 '}}
									<span style="font-size: 24upx;">/{{ item.acreage || 3453 }}m²</span>
								</span>
							</div>
							<div class="home-address">
								<span space="nbsp">{{ item.provinceString || '水费：6.5/方 电费：1.5元/度 租期：6个月 押一付一' }}
									{{ item.areaName || '房间配套：厨房 阳台 冰箱 洗衣机 空调' }}|{{ item.areaName || '' }}
								</span>
								<span>|{{item.buildNum || '2'}}栋{{item.roomNum || 201}}</span>
							</div>
							<div class="home-price" v-if="houseType == 1">
								<span>{{ item.price || 23 }}万({{ item.acreage ||354 }}m²|{{ item.unitPrice  || 23 }}元/m²)</span>
							</div>
							<div class="u-flex u-row-between">
								<div class="home-price">
									<span>{{ item.rental || 354}}元/月</span>
								</div>
								<div class="home-tips">
									<van-tag type="warning" mode="dark" @click="closeCollection(item)">取消收藏</van-tag>
								</div>
							</div>
						</div>
					</div>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
	import Vue from 'vue';
	import qs from 'qs';
	import {
		Tag,
		NavBar,
		Swipe,
		SwipeItem,
		Lazyload,
		Button,
		Popup,
		DatetimePicker,
		Toast,
		DropdownMenu,
		DropdownItem
	} from 'vant';
	Vue.use(Tag);
	Vue.use(NavBar);
	Vue.use(Swipe);
	Vue.use(SwipeItem);
	Vue.use(Lazyload);
	Vue.use(Button);
	Vue.use(Popup);
	Vue.use(DatetimePicker);
	Vue.use(Toast);
	Vue.use(DropdownMenu);
	Vue.use(DropdownItem);

	export default {
		name: 'homeList',
		components: {

		},
		data() {
			return {
				loading: false,
				finished: false,
				refreshing: false,

				pageSize: 10,
				pageNum: 0,

				content: '',
				top: '98rpx',
				hasFlag: true,
				status: 'loading',
				contentText: {
					contentdown: '查看更多',
					contentrefresh: '加载中',
					contentnomore: '没有更多'
				},
				downOption: {
					auto: false //是否在初始化后,自动执行下拉回调callback; 默认true
				},
				page: 0,
				background: {
					backgroundColor: '#FBDF3E',
				},
				homeType: '',
				houseType: '',
				homeList: [],
				shareImg: 'https://img.bajiaostar.com/2e0268c4601672d79e140d888a1f9e40',
				activeRow: {}
			};
		},
		methods: {
			onClickFallback() {
				this.$router.back();
			},
			listLoadMore() {
				this.pageNum += 1;
				this.getRoomList();
			},
			onRefresh() {
				// 清空列表数据V
				this.finished = false;
				this.pageNum = 1;
				this.homeList = [];
				// 重新加载数据
				// 将 loading 设置为 true，表示处于加载状态
				this.loading = true;
				//this.getRoomList();
			},
			async getRoomList() {
				this.$axios({
					url: 'collection/list',
					method: "POST",
					data: qs.stringify({
						pageNum: this.pageNum,
						pageSize: this.pageSize
					})
				}).then(res => {
					console.log(res)
					if (res.status === 10007) {
						this.$router.push('/login');
					}
					
					if (this.pageNum === 1) {
						this.homeList = [];
					}
					if (res.data.list.length == 0) {
						this.loading = false; // 关闭加载状态
						this.finished = true; // 加载结束
						return false;
					} 
					let item = res.data.list || []
					item.forEach((val) => {
						this.homeList.push(val);
					}) 
					console.log(this.homeList);
			 
					// 如果合并之后的数组长度大于返回的数据总条数
					if (this.homeList.length >= res.data.total) { //数据总条数
					  this.finished = true; // 加载结束
					}

					this.loading = false;
				}).catch(error => {
					if (error.status === 10007) {
						this.$router.push('/login');
					}
					console.log(error)
				})
			},
			closeCollection(item) {
				this.$axios({
					url: 'collection/del',
					method: "POST",
					headers: {
						'Content-Type': 'application/json;charset=UTF-8'
					},
					data: {
						houseId: item.houseId,
						roomId: item.roomId,
					}
				}).then(res => {
					if (res.code === 20001) {
						if (res.message === '请先登录') {
							this.$router.push('/login');
						} else {
							Toast.fail('取消收藏失败');
						}
					} else {
						Toast.success('取消收藏成功');
						let index = this.homeList.findIndex((v)=>{
							return v.id == item.id
						})
						this.homeList.splice(index,1);
					}
				
					if (res.status === 10007) {
						this.$router.push('/login');
					}
				}).catch(error => {
					Toast.fail('收藏失败');
					console.log(error)
				})
			}
		}
	};
</script>

<style lang="scss">
	// body {
	// 	background-color: #EBEBEB;
	// }

	.header {
		width: 100%;
		background: #ffffff;
		position: fixed;
		top: 0;
		height: 98px;
		z-index: 99;
		display: flex;
		flex-direction: column;
		box-shadow: 0 2upx 8upx rgba(0, 0, 0, 0.06);
	}

	.search-bar {
		width: 100%;
		height: 53px;
		display: flex;
		flex-direction: row;
		position: fixed;
		top: 0;

		top: var(--window-top);

		background: #ffffff;
		z-index: 99;
	}

	.search-input {
		width: 302px;
		height: 36px;
		border-radius: 8rpx;
		background: #f1f2f4;
		display: flex;
		flex-direction: row;
		margin-left: 15px;
		align-items: center;
		margin-top: 9px;
	}

	.search-icon {
		margin-left: 15px;
		margin-right: 5px;
		width: 14px;
		height: 14px;
		display: flex;
		align-items: center;
	}

	.search-icon image {
		width: 14px;
		height: 14px;
	}

	.search-content {
		color: #a6a6a6;
		font-size: 12px;
		letter-spacing: 0.14px;
		margin-top: 2px;
		width: 243px;
	}

	.close-icon {
		margin-right: 10px;
		width: 18px;
		height: 36px;
		display: flex;
		align-items: center;
	}

	.close-icon image {
		width: 18px;
		height: 18px;
	}

	.findHome {
		margin-left: 15px;
		margin-top: 9px;
		width: 25px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 25px;
			height: 25px;
		}
	}

	.homeList {
		width: 100%;
		background: #ffffff;

		.home-info {
			display: flex;
			flex-direction: row;
			height: 125px;
			margin-left: 0px;
			margin-top: 5px;
			border-bottom: 1px solid #f7f7f7;
		}

		.van-tag--warning {
			padding: 5px 5px;
		}
	}

	.room-haoma {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-right: 8px;
		margin-top: 20px;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		padding: 0 0px;
		height: 90px;
		background-color: #FF924D;
		color: #FFFFFF;
		font-size: 16px;
		word-break: break-all;
	}

	.home-pic {
		width: 120px;
		height: 90px;
		margin-right: 15px;
		margin-top: 20px;
		border-radius: 2px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-image: url('https://img.bajiaostar.com/FnwyfqiIK3_RD5UR1k66KQ_y0VFx');
	}

	.home-detail {
		margin-top: 20px;
		display: flex;
		flex-direction: column;

		.homeName {
			width: 210px;
			font-size: 14px;
			color: #5b5b5b;
			text-align: justify;
			letter-spacing: 0;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			font-weight: bold;
		}

		.home-address {
			width: 210px;
			font-size: 12px;
			color: #a6a6a6;
			letter-spacing: 0;
			text-align: justify;
			margin-top: 4px;
			display: flex;

			&>text:first-child {
				flex: 2;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&>text:nth-child(2) {
				flex: 1;
			}
		}

		.home-tips {
			display: flex;
			flex-direction: row;
			margin-top: 10rpx;
			height: 36rpx;

			.tips-1 {
				background: rgba(241, 78, 44, 0.15);
				border-radius: 8rpx;
				font-size: 20rpx;
				color: #f14e2c;
				letter-spacing: 0;
				text-align: justify;
				margin-right: 30rpx;
				height: 36rpx;
				line-height: 36rpx;
			}

			.tips-2 {
				background: rgba(74, 144, 226, 0.15);
				border-radius: 8rpx;
				font-size: 20rpx;
				color: #4a90e2;
				letter-spacing: 0;
				text-align: justify;
				margin-right: 30rpx;
				height: 36rpx;
				line-height: 36rpx;
			}

			.tips-3 {
				background: rgba(211, 160, 221, 0.15);
				border-radius: 8rpx;
				font-size: 20rpx;
				color: #800080;
				letter-spacing: 0;
				text-align: justify;
				height: 36rpx;
				line-height: 36rpx;
			}

			.tips4 {
				background: #48d2ab;
				border-radius: 8rpx;
				font-size: 20rpx;
				color: #ffffff;
				letter-spacing: 0;
				text-align: justify;
				margin-right: 30rpx;
				height: 36rpx;
				line-height: 36rpx;
			}

			.tips5 {
				background: rgba(74, 144, 226, 0.15);
				border-radius: 8rpx;
				font-size: 20rpx;
				color: #4a90e2;
				letter-spacing: 0;
				text-align: justify;
				margin-right: 30rpx;
				height: 36rpx;
				line-height: 36rpx;
			}
		}

		.home-price {
			font-size: 28rpx;
			color: #fe9e2e;
			letter-spacing: 0;
			font-size: 24rpx;
			margin-top: 12rpx;
		}
	}

	.tips-1 text {
		margin: 0 10rpx;
	}

	.tips-2 text {
		margin: 0 10rpx;
	}

	.tips-3 text {
		margin: 0 10rpx;
	}

	.tips4 text {
		margin: 0 10rpx;
	}

	.tips5 text {
		margin: 0 10rpx;
	}

	.noData {
		background: #ffffff;
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 100rpx;
	}
</style>
