<!--房东首页-->
<!-- <template #left>
    <van-image round width="2.5em" height="2.5em" fit="cover" src="https://img.yzcdn.cn/vant/cat.jpeg"/>
    <div class="navbar-user">
      <div class="navbar-userName">Mr.Huang</div>
      <div class="navbar-userType">云匙号:YS0001</div>
    </div>
   </template> -->
<template>
	<div id="home-container">
		<van-nav-bar style="height: 46px;" id="navbar" :fixed="true" :border="false" title="房态" class="navbar">
			<!-- <template #right>
				<van-icon duration="0" round @click="onClickMoreButton" name="ellipsis" size="2em" color="#332c2b" />
			</template> -->
		</van-nav-bar>

		<van-popup :overlay-style="{'background-color': 'transparent'}" class="more-box" position="top"
			get-container="#app" v-model="showMoreListFlag">
			<div class="more">
				<div class="more-item">
					<div class="more-item-icon-box">
						<img src="../assets/icon/vip.png">
					</div>
					<div class="more-item-text">开通会员</div>
				</div>
				<div class="more-item">
					<div class="more-item-icon-box">
						<img src="../assets/icon/analysis.png">
					</div>
					<div class="more-item-text">房源分析</div>
				</div>
				<div class="more-item">
					<div class="more-item-icon-box">
						<img src="../assets/icon/history.png">
					</div>
					<div class="more-item-text">已看房源</div>
				</div>
				<div class="more-item">
					<div class="more-item-icon-box">
						<img src="../assets/icon/share.png">
					</div>
					<div class="more-item-text">云匙分享</div>
				</div>
				<div class="more-item">
					<div class="more-item-icon-box">
						<img src="../assets/icon/help.png">
					</div>
					<div class="more-item-text">帮助反馈</div>
				</div>
			</div>
		</van-popup>

		<van-tabs :lazy-render="true" :offset-top="tabsTop" :sticky="true" :animated="true" :swipeable="true"
			v-model="selectTabIndex" color="#26a5f9">
			<van-tab title="预计支出">
				<div class="block-rent">
					<div class="block-rent-top">
						<div class="block-rent-title">
							<div class="block-rent-item-color" style="background-color: red" />
							<span>本月预计费用</span>
							<span class="block-rent-title-remark">
								<span class="block-rent-title-remark-name">下次缴纳日期</span>
								<span
									class="block-rent-title-remark-value">{{orderMonthlyRentData?.[0]?.heTong.fromdate | formatTime}}</span>
							</span>
						</div>
						<div class="block-rent-remark">
							<div class="block-rent-remark-left">
								<div class="block-rent-remark-item">
									<span class="block-rent-remark-item-title">房 号</span>
									<span
										class="block-rent-remark-item-number">{{orderMonthlyRentData?.[0]?.roomNum}}</span>
								</div>
								<div class="block-rent-remark-item">
									<span class="block-rent-remark-item-title">合同到期</span>
									<span class="block-rent-remark-item-number">{{orderMonthlyRentData?.[0]?.heTong.todate | formatTime}}</span>
								</div>
								<div class="block-rent-remark-item">
									<span class="block-rent-remark-item-title">房屋地址</span>
									<span
										class="block-rent-remark-item-number">{{orderMonthlyRentData?.[0]?.orderItemVOList?.[0].roomAddress}}</span>
								</div>
							</div>
							<div class="block-rent-remark-right">
								<div class="block-rent-remark-item">
									<span class="block-rent-remark-item-title">租 金</span>
									<span
										class="block-rent-remark-item-number">{{orderMonthlyRentData?.[0].monthlyPrice}}</span>
									<span class="block-rent-remark-item-unit">元</span>
								</div>
								<div class="block-rent-remark-item">
									<span class="block-rent-remark-item-title">电 价</span>
									<span
										class="block-rent-remark-item-number">{{orderMonthlyRentData?.[0].electricUnitPrice}}</span>
									<span class="block-rent-remark-item-unit">元/度</span>
								</div>
								<div class="block-rent-remark-item">
									<span class="block-rent-remark-item-title">水 价</span>
									<span
										class="block-rent-remark-item-number">{{orderMonthlyRentData?.[0].waterUnitPrice}}</span>
									<span class="block-rent-remark-item-unit">元/方</span>
								</div>
							</div>
						</div>
					</div>

					<div v-for="(item, index) in orderMonthlyRentData" :key="index">
						<div class="block-rent-content">
							<div class="block-rent-list">
								<div class="block-rent-item">
									<div class="block-rent-item-left">
										<div class="block-rent-item-left-top">
											<img class="block-rent-item-left-top-img" src="../assets/icon/rent.png">
											<div class="block-rent-item-left-top-text">房租租金</div>
										</div>
										<div class="block-rent-item-left-bottom">
											<div class="block-rent-item-left-bottom-value">
												<div class="block-rent-item-left-bottom-value-data">
													{{item.monthlyPrice}}元
												</div>
												<div class="block-rent-item-left-bottom-value-text">
													租金费用
												</div>
											</div>
											<div class="block-rent-item-left-bottom-point">
												<div class="block-rent-item-left-bottom-point-data">
													0%
												</div>
												<div class="block-rent-item-left-bottom-point-text">
													同比增长
												</div>
											</div>
										</div>
									</div>
									<div class="block-rent-item-right">
										<div ref="rentPie" style="height: 100%;width: 100%" />
									</div>
								</div>
								<div class="block-rent-item">
									<div class="block-rent-item-left">
										<div class="block-rent-item-left-top">
											<img class="block-rent-item-left-top-img"
												src="../assets/icon/electric_yellow.png">
											<div class="block-rent-item-left-top-text">本月电费</div>
										</div>
										<div class="block-rent-item-left-bottom">
											<div class="block-rent-item-left-bottom-value">
												<div class="block-rent-item-left-bottom-value-data">
													{{item.electricPrice}}元
												</div>
												<div class="block-rent-item-left-bottom-value-text">
													电费费用
												</div>
											</div>
											<div class="block-rent-item-left-bottom-point">
												<div class="block-rent-item-left-bottom-point-data">
													5.5%
												</div>
												<div class="block-rent-item-left-bottom-point-text">
													同比增长
												</div>
											</div>
										</div>
									</div>
									<div class="block-rent-item-right">
										<div ref="electricPie" style="height: 100%;width: 100%" />
									</div>
								</div>
								<div class="block-rent-item">
									<div class="block-rent-item-left">
										<div class="block-rent-item-left-top">
											<img class="block-rent-item-left-top-img"
												src="../assets/icon/water_blue.png">
											<div class="block-rent-item-left-top-text">本月水费</div>
										</div>
										<div class="block-rent-item-left-bottom">
											<div class="block-rent-item-left-bottom-value">
												<div class="block-rent-item-left-bottom-value-data">
													{{item.waterPrice}}元
												</div>
												<div class="block-rent-item-left-bottom-value-text">
													水费费用
												</div>
											</div>
											<div class="block-rent-item-left-bottom-point">
												<div class="block-rent-item-left-bottom-point-data">
													5.5%
												</div>
												<div class="block-rent-item-left-bottom-point-text">
													同比增长
												</div>
											</div>
										</div>
									</div>
									<div class="block-rent-item-right">
										<div ref="waterPie" style="height: 100%;width: 100%" />
									</div>
								</div>
								<div class="block-rent-item">
									<div class="block-rent-item-left">
										<div class="block-rent-item-left-top">
											<img class="block-rent-item-left-top-img"
												src="../assets/icon/other_green.png">
											<div class="block-rent-item-left-top-text">本月管理费</div>
										</div>
										<div class="block-rent-item-left-bottom">
											<div class="block-rent-item-left-bottom-value">
												<div class="block-rent-item-left-bottom-value-data">
													{{item.adminFee}}元
												</div>
												<div class="block-rent-item-left-bottom-value-text">
													管理费用
												</div>
											</div>
											<div class="block-rent-item-left-bottom-point">
												<div class="block-rent-item-left-bottom-point-data">
													0%
												</div>
												<div class="block-rent-item-left-bottom-point-text">
													同比增长
												</div>
											</div>
										</div>
									</div>
									<div class="block-rent-item-right">
										<div ref="managePie" style="height: 100%;width: 100%" />
									</div>
								</div>
							</div>
						</div>

						<div class="block-rent-pay">
							<van-divider />
							<div class="top">
								<div class="total">
									<div class="text">应付:</div>
									<div class="number">￥{{item.totalPrice}}</div>
								</div>
							</div>
							<div class="bottom">
								<!-- <div class="remark">延期时间:1个月<br />2020-11-15到期</div> -->
								<div class="block-rent-pay-total-button">
									<!-- <van-button class="pay-button" size="mini" color="#26a5f9" @click="sendMessageToOwner(item)">
										<template #default>
											<span style="font-size: 14px;" >延迟支付</span>
										</template>
									</van-button> -->
									<van-button class="pay-button" size="mini" color="#00c468" @click="payOrder(item)">
										<template #default>
											<span style="font-size: 14px;">确认支付</span>
										</template>
									</van-button>
								</div>
							</div>
						</div>
					</div>

					<div class="block-rent-together" v-if="false">
						<div class="block-rent-together-title">
							<div class="block-rent-item-color" style="background-color: #FF8C00" />
							<div class="block-rent-together-title-text">合租管理</div>
						</div>
						<div class="block-rent-together-content">
							<div class="block-rent-together-item">
								<div class="block-rent-together-item-title">
									<img class="block-rent-together-item-title-img"
										src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=757545797,2214471709&fm=11&gp=0.jpg">
									<div class="block-rent-together-item-title-text">刘昌(租主)</div>
								</div>
								<div class="block-rent-together-item-chart">
									<div ref="rentTogetherPie1" style="height: 100%;width: 100%" />
								</div>
								<div class="block-rent-together-item-data">
									<div class="block-rent-together-item-data-text">已支付:</div>
									<div class="block-rent-together-item-data-value">1280元</div>
								</div>
							</div>
							<div class="block-rent-together-item">
								<div class="block-rent-together-item-title">
									<img class="block-rent-together-item-title-img"
										src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=757545797,2214471709&fm=11&gp=0.jpg">
									<div class="block-rent-together-item-title-text">刘欣轩(租客)</div>
								</div>
								<div class="block-rent-together-item-chart">
									<div ref="rentTogetherPie2" style="height: 100%;width: 100%" />
								</div>
								<div class="block-rent-together-item-data">
									<div class="block-rent-together-item-data-text">已支付:</div>
									<div class="block-rent-together-item-data-value">1280元</div>
								</div>
							</div>
							<div class="block-rent-together-item">
								<div class="block-rent-together-item-title">
									<img class="block-rent-together-item-title-img"
										src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=757545797,2214471709&fm=11&gp=0.jpg">
									<div class="block-rent-together-item-title-text">刘飞飞(租客)</div>
								</div>
								<div class="block-rent-together-item-chart">
									<div ref="rentTogetherPie3" style="height: 100%;width: 100%" />
								</div>
								<div class="block-rent-together-item-data">
									<div class="block-rent-together-item-data-text">未支付:</div>
									<div class="block-rent-together-item-data-value">815元</div>
								</div>
							</div>
						</div>
						<van-divider style="margin:5px 0;" />
						<div class="block-rent-together-button-box">
							<van-button style="margin-left: auto;width: 30%" block size="small" color="#ff943d">
								推送账单
							</van-button>
						</div>
					</div>

					<div class="block-rent-content-detail">
						<div class="block-rent-content-detail-title">
							<div class="block-rent-item-color" style="background-color: #9400D3" />
							<div class="block-rent-content-detail-name">实时数据</div>
							<div class="switchContentButtonBox">
								<van-button @click="onClickSwitchContentButton" block round size="mini"
									:color="switchContentButtonColor">
									电费/水费
								</van-button>
							</div>
						</div>
						<!-- <div class="block-rent-content-detail-content">
							<div ref="bar" style="height: 100%" />
						</div> -->
						<div class="block-rent-content-detail-bottom">
							<van-row class="block-rent-content-detail-content-title">
								<van-col span="6">{{ showLineIndex == 0 ? '上月度数' : '上月方数' }}</van-col>
								<van-col span="6">{{ showLineIndex == 0 ? '本月度数' : '本月方数' }}</van-col>
								<van-col span="6">已使用</van-col>
								<van-col span="6">产生费用</van-col>
							</van-row>
							<div v-for="(item, index) in orderMonthlyRentData" :key="index">

								<van-row v-if="showLineIndex==0">

									<van-col class="num-box" span="6">
										<img v-for="num in item.preMonthElectricDeg.toString().split('')" :key="num"
											class="img-number" :src="require('../assets/icon/num_'+num+'.png')" />

									</van-col>
									<van-col class="num-box" span="6">
										<img v-for="num in item.currentMonthElectricDeg.toString().split('')" :key="num"
											class="img-number" :src="require('../assets/icon/num_'+num+'.png')" />
									</van-col>
									<van-col class="num-box" span="6">
										<img v-for="num in item.differElectricDeg?.toString().split('')" :key="num"
											class="img-number" :src="require('../assets/icon/num_'+num+'.png')" />
									</van-col>
									<van-col span="6">￥{{item.electricPrice}}</van-col>
								</van-row>
								<van-row v-if="showLineIndex==1">
									<van-col class="num-box" span="6">
										<img v-for="num in item.preMonthWaterDeg?.toString().split('')" :key="num"
											class="img-number" :src="require('../assets/icon/num_'+num+'.png')" />
									</van-col>
									<van-col class="num-box" span="6">
										<img v-for="num in item.currentMonthWaterDeg?.toString().split('')" :key="num"
											class="img-number" :src="require('../assets/icon/num_'+num+'.png')" />
									</van-col>
									<van-col class="num-box" span="6">
										<img v-for="num in item.differWaterDeg?.toString().split('')" :key="num"
											class="img-number" :src="require('../assets/icon/num_'+num+'.png')" />
									</van-col>
									<van-col span="6">￥{{item.waterPrice}}</van-col>
								</van-row>
							</div>
						</div>
						<div class="block-rent-content-detail-content">
							<div ref="useLine" style="height: 100%" />
						</div>
					</div>
				</div>

				<div class="block-rent-lately">
					<div class="block-rent-lately-top">
						<div class="block-rent-lately-title">
							<div class="block-rent-item-color" style="background-color: #00CED1" />
							历史费用走势图
						</div>
						<div class="block-rent-lately-button">
							<!--              <van-icon @click="showLatelyPicker" name="underway-o" size="24px"></van-icon>-->
							<img @click="showLatelyPicker" src="../assets/icon/calendar.png" style="width: 24px">
						</div>
					</div>
					<div class="block-rent-lately-line">
						<div ref="line" style="height: 100%" />
					</div>

					<van-popup v-model="showLatelyPickerFlag" round position="bottom">
						<van-picker show-toolbar :columns="latelyOptions" @cancel="showLatelyPickerFlag = false"
							@confirm="choseLatelyOption" />
					</van-popup>

					<div class="trend">
						<div class="title">
							<van-divider style=";margin-left: auto;" />
							<div class="text">历史费用详情</div>
							<van-divider style="margin-right: auto" />
						</div>
						<div class="bill-box">
							<div class="title">
								<div class="item" style="width: 28%;">日期</div>
								<div class="item" style="width: 18%;">电费</div>
								<div class="item" style="width: 18%;">水费</div>
								<div class="item" style="width: 18%;">其他</div>
								<div class="item" style="width: 18%;">合计</div>
							</div>
							<div class="row" v-for="(i,index) in billItems.slice(0,billCount)" :key="index"
								:style="billIndex==index?'background-color:#F8F8F8;color:black;':''">
								<div class="item-box" @click="onClickBillItem(index)">
									<div class="item" style="width: 28%;">{{ i.time }}</div>
									<div class="item" style="color: #ff943d;width: 18%;">{{ i.electric }}</div>
									<div class="item" style="color: #26a5f9;width: 18%;">{{ i.water }}</div>
									<div class="item" style="width: 18%;">{{ i.other }}</div>
									<div class="item" style="width: 18%;">{{ i.total }}</div>
								</div>
								<div class="line-box" v-if="billIndex==index" style="margin-top: 14px;">
									<div ref="usageLine" style="height: 100%" />
								</div>
								<div class="line-box" v-if="billIndex==index">
									<div ref="costLine" style="height: 100%" />
								</div>
							</div>
						</div>
					</div>

				</div>
			</van-tab>
			<van-tab title="分租管理">
				<van-empty description="这里空空如也" />
			</van-tab>
			<!-- <van-tab title="自有房管理">
				<van-empty description="这里空空如也" />
			</van-tab> -->
		</van-tabs>
		
		<pay-way ref="payWay"></pay-way>
	</div>
</template>

<script>
	import  payWay  from '../../components/payWay/payWay.vue'
	import {formatDates} from '@/utils/date.js'
	import Vue from 'vue';
	import {
		mapState
	} from 'vuex'
	import {
		NavBar,
		Icon,
		Image as VanImage,
		Tab,
		Tabs,
		Col,
		Dialog,
		Row,
		Empty,
		Divider,
		Picker,
		Popup,
		Toast,
		Sticky
	} from 'vant';

	Vue.use(NavBar);
	Vue.use(Icon);
	Vue.use(VanImage);
	Vue.use(Tab);
	Vue.use(Tabs);
	Vue.use(Col);
	Vue.use(Dialog);
	Vue.use(Row);
	Vue.use(Empty);
	Vue.use(Divider);
	Vue.use(Picker);
	Vue.use(Popup);
	Vue.use(Sticky);
	Vue.use(Toast);
	export default {
		name: "home",
		data() {
			return {
				orderMonthlyRentData: [],
				historyOrderListData:[],
				showBaseDataFlag: false,
				selectTabIndex: 0,
				showLatelyPickerFlag: false,
				latelyOptions: ['最近三个月', '最近六个月', '最近一年'],
				tabsTop: 46,
				showLineIndex: 0,
				switchContentButtonColor: '#26a5f9',
				showContrastFlag: false,
				showMoreListFlag: false,
				billCount: 12,
				billIndex: -1,
				billItems: [{
						time: '12/15~01/15',
						electric: 35,
						water: 8,
						other: 0,
						total: 1973
					},
					{
						time: '11/15~12/15',
						electric: 243,
						water: 47,
						other: 50,
						total: 1973
					},
					{
						time: '10/15~11/15',
						electric: 212,
						water: 49,
						other: 50,
						total: 1973
					},
					{
						time: '09/15~10/15',
						electric: 375,
						water: 48,
						other: 0,
						total: 1973
					},
					{
						time: '08/15~09/15',
						electric: 392,
						water: 46,
						other: 50,
						total: 1973
					},
					{
						time: '07/15~08/15',
						electric: 356,
						water: 79,
						other: 50,
						total: 1973
					},
					{
						time: '06/15~07/15',
						electric: 388,
						water: 53,
						other: 50,
						total: 1973
					},
					{
						time: '05/15~06/15',
						electric: 245,
						water: 47,
						other: 50,
						total: 1973
					},
					{
						time: '04/15~05/15',
						electric: 267,
						water: 54,
						other: 50,
						total: 1973
					},
					{
						time: '03/15~04/15',
						electric: 235,
						water: 74,
						other: 50,
						total: 1973
					},
					{
						time: '02/15~03/15',
						electric: 240,
						water: 68,
						other: 0,
						total: 1973
					},
					{
						time: '01/15~02/15',
						electric: 220,
						water: 83,
						other: 50,
						total: 1973
					},
				]
			};
		},
		mounted() {
			var vm = this;
			this.$nextTick(function() {
				this.drawRentPie();
				this.drawElectricPie();
				this.drawWaterPie();
				this.drawManagePie();
				this.drawrentTogetherPie(this.$refs.rentTogetherPie1, 46.5);
				this.drawrentTogetherPie(this.$refs.rentTogetherPie2, 24.3);
				this.drawrentTogetherPie(this.$refs.rentTogetherPie3, 29.2);
				this.drawLine();
				this.drawBar();
				this.drawUseLine();
			});
			window.addEventListener('scroll', this.handleScroll);

			this.getOrder();
			this.getHistoryOrderList();
			
		},
		computed: {
			...mapState({
				vuex_user: state => state.vuex_user,
			})
		},
		filters: {
			formatTime(val) {
				let data = new Date(val)
				return formatDates(data, 'yyyy-MM-dd');
			}
		},
		watch: {},
		components:{
			payWay,
		},
		methods: {
			// 获取月租信息
			getOrder() {
				var vm = this;
				this.$axios({
					url: "order/monthlyRent",
					method: "GET",
					headers: {
						'content-type': 'application/x-www-form-urlencoded'
					},
					params: {
						userId: this.vuex_user.userId
					}
				}).then(res => {
					console.log(res)
					let {
						status,
						data
					} = res
					if (status === 10007) {
						this.$router.push('/login');
					} else {
						this.orderMonthlyRentData = data
						if(new Date(this.orderMonthlyRentData?.[0]?.heTong.fromdate).getDate() != new Date().getDate()){
							Dialog.confirm({
								title: '房租缴费通知',
								showCancelButton:false,
								message: `您好,以下房租已到缴费日期,请及时缴费！\n<div style="font-size:25px">${vm.orderMonthlyRentData[0].heTong.roomAddress || ''}</div>`
								
							}).then(() => {
								console.log(123)
							})
							.catch(() => {
								// on cancel
							});
						}
					}
				}).catch(error => {
					console.log(error, 'error');
					Toast.fail('获取月租信息失败');
				})
			},
			getHistoryOrderList() {
				console.log(this.vuex_user)
				this.$axios({
					url: "order/history/list",
					method: "GET",
					headers: {
						'content-type': 'application/x-www-form-urlencoded'
					},
					params: {
						userId: this.vuex_user.userId
					}
				}).then(res => {
					console.log(res)
					let {
						status,
						data
					} = res
					if (status === 10007) {
						this.$router.push('/login');
					} else {
						this.historyOrderListData = data
						let billItems = data.map((item) => {
							return {
								// time: '12/15~01/15',
								time:this.formatDateFn(item.month, item.day),
								electric: item.electricPrice,
								water: item.waterPrice,
								other: item.monthlyPrice,
								total: item.totalPrice
							}
						})
						this.billItems = billItems;
					}
				}).catch(error => {
					console.log(error, 'error');
					Toast.fail('获取历史费用详情数据失败');
				})
			},
			formatDateFn(month,day){
				let nextMonth = null
				if(month == 12){
					nextMonth = 1
				}else{
					nextMonth = Number(month)+1
				}
				
				return `${month}/${day}~${nextMonth}/${day}`
			},
			// 延迟支付
			sendMessageToOwner(item){
				
				this.createCustomMessage(item);
			},
			createCustomMessage (item) {
				let customMessage = this.goEasy.im.createCustomMessage({
					type : 'delayedPay',
					payload : {
						avatar :  this.vuex_user.avatar,
						name : this.vuex_user.name
					},
					to : {
						id : item.heTong.chuzuUserId,
						type : this.GoEasy.IM_SCENE.PRIVATE,
						data : {
							// name : item.name,
							// avatar: item.avatar
						}
					},
					notification : {
						title : this.vuex_user.name + '发来房租延迟支付请求',
						body : '[请求信息]'     // 字段最长 50 字符
					}
				});
				this.sendMessage(customMessage);
				this.customMessageFormVisible = false;
			},
			sendMessage(message) {
				this.goEasy.im.sendMessage({
					message: message,
					onSuccess: function(message) {
						console.log('发送成功.', message);
					},
					onFailed: function(error) {
						if (error.code === 507) {
							console.log(
								'发送语音/图片/视频/文件失败，没有配置OSS存储，详情参考：https://www.goeasy.io/cn/docs/goeasy-2.x/im/message/media/send-media-message.html'
							);
						} else {
							console.log('发送失败:', error);
						}
					}
				});
			},
			payOrder(item) {
				this.$refs.payWay.show = true
				// this.$axios({
				// 	url: "pay",
				// 	method: "get",
				// 	data: {
				// 		orderNo: item.orderNo
				// 	}
				// }).then(res => {
				// 	console.log(res)
				// 	let {
				// 		code
				// 	} = res
				// 	if (code === 10007) {
				// 		this.$router.push('/login');
				// 	} else {
				// 		Toast.success('支付成功');
				// 	}
				// }).catch(error => {
				// 	console.log(error, 'error');
				// 	Toast.fail('支付失败');
				// })
			},
			showBaseData() {
				this.showBaseDataFlag = !this.showBaseDataFlag;
				if (this.showBaseDataFlag) {
					this.tabsTop = 103;
				} else {
					setTimeout(() => {
						this.tabsTop = 44;
					}, 115)
				}
			},
			showLatelyPicker() {
				this.$nextTick(() => {
					let father = document.getElementById('home-container') // 获取页面父容器
					window.scrollTo(0, father.scrollHeight);
				})
				this.showLatelyPickerFlag = !this.showLatelyPickerFlag;
			},
			choseLatelyOption(value, index) {
				this.showLatelyPickerFlag = false;
				switch (index) {
					case 0:
						this.billCount = 3;
						break;
					case 1:
						this.billCount = 6;
						break;
					case 2:
						this.billCount = 12;
						break;
				}
				this.drawLine();
			},
			handleScroll() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body
					.scrollTop // 滚动条偏移量
				if (scrollTop > 0) {
					this.showBaseDataFlag = false;
					this.tabsTop = 44;
				}
			},
			onClickSwitchContentButton() {
				this.showLineIndex == 1 ? this.showLineIndex = 0 : this.showLineIndex = 1;
				this.showLineIndex == 1 ? this.switchContentButtonColor = '#26a5f9' : this.switchContentButtonColor =
					'#ff943d';
				this.drawBar();
				this.drawUseLine();
			},
			onClickMoreButton() {
				this.showMoreListFlag = true;
			},
			onClickBillItem(index) {
				this.billIndex == index ? this.billIndex = -1 : this.billIndex = index;
				if (this.billIndex != -1) {
					this.$nextTick(() => {
						this.drawUsageLine();
						this.drawCostLine();
					})
				}
			},
			drawRentPie() {
				let myChart = this.$echarts.init(this.$refs.rentPie);
				let datas = {
					value: 80.88,
					unit: '%'
				};
				myChart.setOption({
					series: [{
						center: [
							"50%",
							"50%",
						],
						radius: [
							"85%",
							"85%"
						],
						clockWise: false,
						hoverAnimation: false,
						type: "pie",
						avoidLabelOverlap: false,
						itemStyle: {
							normal: {
								label: {
									show: true,
									textStyle: {
										fontSize: 12,
										color: '#332c2b',
									},
									position: "center"
								},
								labelLine: {
									show: false
								},
								borderColor: "#DC143C",
								borderWidth: 5
							},
						},
						data: [{
							name: "",
							value: 100 - datas.value,
							itemStyle: {
								normal: {
									label: {
										"show": false
									},
									labelLine: {
										"show": false
									},
									borderColor: "#EFEFEF",
									borderWidth: 3
								},
							}
						}, {
							value: datas.value,
							name: datas.value + datas.unit
						}]
					}]
				});
			},
			drawElectricPie() {
				let myChart = this.$echarts.init(this.$refs.electricPie);
				let datas = {
					value: 11.95,
					unit: '%'
				};
				myChart.setOption({
					series: [{
						center: [
							"50%",
							"50%",
						],
						radius: [
							"85%",
							"85%"
						],
						clockWise: false,
						hoverAnimation: false,
						type: "pie",
						avoidLabelOverlap: false,
						itemStyle: {
							normal: {
								label: {
									show: true,
									textStyle: {
										fontSize: 12,
										color: '#332c2b',
									},
									position: "center"
								},
								labelLine: {
									show: false
								},
								borderColor: "#FF8C00",
								borderWidth: 5
							},
						},
						data: [{
							name: "",
							value: 100 - datas.value,
							itemStyle: {
								normal: {
									label: {
										"show": false
									},
									labelLine: {
										"show": false
									},
									borderColor: "#EFEFEF",
									borderWidth: 3
								},
							}
						}, {
							value: datas.value,
							name: datas.value + datas.unit
						}]
					}]
				});
			},
			drawWaterPie() {
				let myChart = this.$echarts.init(this.$refs.waterPie);
				let datas = {
					value: 0.8,
					unit: '%'
				};
				myChart.setOption({
					series: [{
						center: [
							"50%",
							"50%",
						],
						radius: [
							"85%",
							"85%"
						],
						clockWise: false,
						hoverAnimation: false,
						type: "pie",
						avoidLabelOverlap: false,
						itemStyle: {
							normal: {
								label: {
									show: true,
									textStyle: {
										fontSize: 12,
										color: '#332c2b',
									},
									position: "center"
								},
								labelLine: {
									show: false
								},
								borderColor: "#26a5f9",
								borderWidth: 5
							},
						},
						data: [{
							name: "",
							value: 100 - datas.value,
							itemStyle: {
								normal: {
									label: {
										"show": false
									},
									labelLine: {
										"show": false
									},
									borderColor: "#EFEFEF",
									borderWidth: 3
								},
							}
						}, {
							value: datas.value,
							name: datas.value + datas.unit
						}]
					}]
				});
			},
			drawManagePie() {
				let myChart = this.$echarts.init(this.$refs.managePie);
				let datas = {
					value: 1.64,
					unit: '%'
				};
				myChart.setOption({
					series: [{
						center: [
							"50%",
							"50%",
						],
						radius: [
							"85%",
							"85%"
						],
						clockWise: false,
						hoverAnimation: false,
						type: "pie",
						avoidLabelOverlap: false,
						itemStyle: {
							normal: {
								label: {
									show: true,
									textStyle: {
										fontSize: 12,
										color: '#332c2b',
									},
									position: "center"
								},
								labelLine: {
									show: false
								},
								borderColor: "#13C489",
								borderWidth: 5
							},
						},
						data: [{
							name: "",
							value: 100 - datas.value,
							itemStyle: {
								normal: {
									label: {
										"show": false
									},
									labelLine: {
										"show": false
									},
									borderColor: "#EFEFEF",
									borderWidth: 3
								},
							}
						}, {
							value: datas.value,
							name: datas.value + datas.unit
						}]
					}]
				});
			},
			drawrentTogetherPie(chart, value) {
				let myChart = this.$echarts.init(chart);
				let datas = {
					value: value,
					unit: '%'
				};
				myChart.setOption({
					title: {
						text: '支付占比',
						show: true,
						textStyle: {
							color: '#332c2b',
							fontSize: 8
						},
						top: '52%',
						left: 'center'
					},
					series: [{
						center: [
							"50%",
							"50%",
						],
						radius: [
							"85%",
							"85%"
						],
						clockWise: false,
						hoverAnimation: false,
						type: "pie",
						avoidLabelOverlap: false,
						itemStyle: {
							normal: {
								label: {
									show: true,
									textStyle: {
										fontSize: 12,
										color: '#332c2b',
									},
									position: "center"
								},
								labelLine: {
									show: false
								},
								borderColor: "#FF8C00",
								borderWidth: 5
							},
						},
						data: [{
							name: "",
							value: 100 - datas.value,
							itemStyle: {
								normal: {
									label: {
										"show": false
									},
									labelLine: {
										"show": false
									},
									borderColor: "#EFEFEF",
									borderWidth: 3
								},
							}
						}, {
							value: datas.value,
							name: datas.value + datas.unit
						}]
					}]
				});
			},
			drawLine() {
				let line = this.$refs.line;
				let myChart = this.$echarts.init(line);
				let dataList = [];
				let electricList = [];
				let waterList = [];
				let otherList = [];

				for (let i = this.billItems.slice(0, this.billCount).length; i--; i > +0) {
					dataList.push(this.billItems[i].time);
					electricList.push(this.billItems[i].electric);
					waterList.push(this.billItems[i].water);
					otherList.push(this.billItems[i].other);
				}

				myChart.setOption({
					xAxis: {
						type: 'category',
						// data: ['1/15-2/15', '2/15-3/15', '3/15-4/15', '4/15-5/15', '5/15-6/15', '6/15-7/15', '7/15-8/15', '8/15-9/15', '9/15-10/15', '10/15-11/15', '11/15-12/15', '12/15-1/15'],
						data: dataList,
						axisLabel: {
							fontSize: '10',
							interval: 0,
							rotate: 40
						},
					},
					grid: {
						y: 30,
						y2: 50,
						x: 40,
						x2: 10
					},
					color: ['#FF8C00', '#26a5f9', '#13C489'],
					legend: {
						data: ['电费', '水费', '其他费用'],
						x: 'center',
					},
					tooltip: {
						trigger: 'axis',
						confine: true
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						// data: [220, 240, 235, 267, 245, 388, 356, 392, 375, 212, 243, 35],
						data: electricList,
						type: 'line',
						smooth: true,
						name: '电费'
					}, {
						// data: [83, 68, 74, 54, 74, 53, 79, 46, 48, 49, 47, 8],
						data: waterList,
						type: 'line',
						smooth: true,
						name: '水费'
					}, {
						// data: [50, 0, 50, 50, 50, 50, 50, 50, 0, 50, 50, 0],
						data: otherList,
						type: 'line',
						smooth: true,
						name: '其他费用'
					}]
				})
			},
			drawBar() {
				let bar = this.$refs.bar;
				let myChart = this.$echarts.init(bar);
				if (this.showLineIndex == 0) {
					myChart.setOption({
						tooltip: {
							// trigger: 'axis',
							// axisPointer: {
							//     type: 'shadow'
							// }
							show: false
						},
						grid: {
							left: '20%',
							right: '10%',
							top: '1%',
							bottom: '10%'
						},
						xAxis: {
							type: 'value',
							boundaryGap: [0, 0.01],
							show: false
						},
						color: ['#ff934d'],
						yAxis: {
							type: 'category',
							data: ['上月用电量', '当前用电量', '上月电费', '当前电费', '昨日用电量', '今日用电量'],
							axisTick: {
								show: false
							},
							axisLine: {
								show: false
							}
						},
						series: [{
							type: 'bar',
							itemStyle: {
								normal: {
									label: {
										show: true, //开启显示
										position: 'right', //在上方显示
									}
								}
							},
							data: [296, 354, 444, 531, 18, 6]
						}]
					})
				} else {
					myChart.setOption({
						tooltip: {
							// trigger: 'axis',
							// axisPointer: {
							//     type: 'shadow'
							// }
							show: false
						},
						grid: {
							left: '20%',
							right: '10%',
							top: '1%',
							bottom: '10%'
						},
						xAxis: {
							type: 'value',
							boundaryGap: [0, 0.01],
							show: false
						},
						color: ['#26a5f9'],
						yAxis: {
							type: 'category',
							data: ['上月用水量', '当前用水量', '上月水费', '当前水费', '昨日用水量', '今日用水量'],
							axisTick: {
								show: false
							},
							axisLine: {
								show: false
							}
						},
						series: [{
							type: 'bar',
							itemStyle: {
								normal: {
									label: {
										show: true, //开启显示
										position: 'right', //在上方显示
									}
								}
							},
							data: [3.6, 3.0, 28.8, 24.5, 0.3, 0.1]
						}]
					})
				}
			},
			drawUseLine() {
				let line = this.$refs.useLine;
				let myChart = this.$echarts.init(line);
				if (this.showLineIndex == 0) {
					myChart.setOption({
						xAxis: {
							type: 'category',
							data: ['15号', '16号', '17号', '18号', '19号', '20号', '21号', '22号', '23号', '24号', '25号',
								'26号', '27号', '28号', '30号',
							],
							axisLabel: {
								fontSize: '10',
								interval: 0,
								rotate: 40
							},
						},
						grid: {
							y: 30,
							y2: 30,
							x2: 15
						},
						color: ['#FF8C00'],
						legend: {
							data: ['每日用电量', ],
							x: 'center',
						},
						tooltip: {
							trigger: 'axis',
							confine: true
						},
						yAxis: {
							type: 'value'
						},
						series: [{
							data: [17.8, 16.5, 17.3, 14.2, 14.1, 14.6, 14.8, 14.9, 15.0, 16.0, 18.0, 13.0,
								16.5, 15.6, 17.8
							],
							type: 'line',
							smooth: true,
							name: '每日用电量'
						}]
					})
				} else {
					myChart.setOption({
						xAxis: {
							type: 'category',
							data: ['15号', '16号', '17号', '18号', '19号', '20号', '21号', '22号', '23号', '24号', '25号',
								'26号', '27号', '28号', '30号',
							],
							axisLabel: {
								fontSize: '10',
								interval: 0,
								rotate: 40
							},
						},
						grid: {
							y: 30,
							y2: 30
						},
						color: ['#26a5f9'],
						legend: {
							data: ['每日用水量', ],
							x: 'center',
						},
						tooltip: {
							trigger: 'axis',
							confine: true
						},
						yAxis: {
							type: 'value'
						},
						series: [{
							data: [0.6, 0.7, 0.5, 0.4, 0.8, 0.6, 0.7, 0.8, 0.4, 0.6, 0.3, 0.5, 0.8, 0.5,
								0.4
							],
							type: 'line',
							smooth: true,
							name: '每日用水量'
						}]
					})
				}
			},
			drawUsageLine() {
				let line = this.$refs.usageLine[0];
				let myChart = this.$echarts.init(line);

				myChart.setOption({
					xAxis: {
						type: 'category',
						data: ['1号', '2号', '3号', '4号', '5号', '6号', '7号', '8号', '9号', '10号', '11号', '12号', '13号',
							'14号', '15号', '16号', '17号', '18号', '19号', '20号', '21号', '22号', '23号', '24号', '25号',
							'26号', '27号', '28号', '29号', '30号'
						],
						axisLabel: {
							show: false
						},
					},
					grid: {
						y: 30,
						y2: 30,
						x: 30,
						x2: 10
					},
					color: ['#FF8C00', '#26a5f9'],
					legend: {
						data: ['用电量', '用水量'],
						x: 'center',
						selectedMode: 'single'
					},
					tooltip: {
						trigger: 'axis',
						confine: true
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: [16.2, 16.2, 13.2, 12.2, 12.2, 13.2, 15.2, 13.2, 14.2, 13.2, 12.2, 11.2,
							12.2, 13.2, 16.2, 17.8, 16.5,
							17.3, 14.2, 14.1, 14.6, 14.8, 14.9, 15.0, 16.0, 18.0, 13.0, 16.5, 15.6,
							17.8
						],
						type: 'line',
						smooth: true,
						name: '用电量'
					}, {
						data: [0.4, 0.3, 0.6, 0.6, 0.6, 0.6, 0.2, 0.3, 0.5, 0.3, 1, 0.9, 0.8, 0.7, 0.6,
							0.6, 0.7, 0.5, 0.4, 0.8, 0.6, 0.7, 0.8, 0.4, 0.6, 0.3, 0.5, 0.8, 0.5, 0.4
						],
						type: 'line',
						smooth: true,
						name: '用水量'
					}]
				})
			},
			drawCostLine() {
				let line = this.$refs.costLine[0];
				let myChart = this.$echarts.init(line);

				myChart.setOption({
					xAxis: {
						type: 'category',
						data: ['1号', '2号', '3号', '4号', '5号', '6号', '7号', '8号', '9号', '10号', '11号', '12号', '13号',
							'14号', '15号', '16号', '17号', '18号', '19号', '20号', '21号', '22号', '23号', '24号', '25号',
							'26号', '27号', '28号', '29号', '30号'
						],
						axisLabel: {
							show: false
						},
					},
					grid: {
						y: 30,
						y2: 30,
						x: 30,
						x2: 10
					},
					color: ['#FF8C00', '#26a5f9', '#13C489'],
					legend: {
						data: ['电费', '水费', '其它费用'],
						x: 'center',
					},
					tooltip: {
						trigger: 'axis',
						confine: true
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: [24.3, 24.3, 19.8, 18.3, 18.3, 19.8, 22.8, 19.8, 21.3, 19.8, 18.3, 16.8,
							18.3, 19.8, 24.3, 26.7, 24.75, 25.95, 21.3, 21.15, 21.9, 22.2, 22.35, 22.5,
							24.0, 27.0, 19.5, 24.75, 23.4, 26.7
						],
						type: 'line',
						smooth: true,
						name: '电费'
					}, {
						data: [3.2, 2.4, 4.8, 4.8, 4.8, 4.8, 1.6, 2.4, 4.0, 2.4, 8, 7.2, 6.4, 5.6, 4.8,
							4.8, 5.6, 4.0, 3.2, 6.4, 4.8, 5.6, 6.4, 3.2, 4.8, 2.4, 4.0, 6.4, 4.0, 3.2
						],
						type: 'line',
						smooth: true,
						name: '水费'
					}]
				})
			},
		}
	}
</script>

<style scoped>
	::v-deep .van-divider {
		background-color: #FFFFFF;
		margin: 0;
	}

	.navbar {
		background-color: #FFED0A;
		border: 0;
	}

	.navbar-userName {
		color: #332c2b;
		font-size: 16px;
		font-weight: bold;
		text-align: left;
	}

	#home-container {
		background-color: #F8F8F8;
	}

	.block-rent {
		background-color: #F8F8F8;
		border-radius: 0 0 10px 10px;
		margin-bottom: 7pt;
		margin-top: 44px;
	}

	.block-rent-content {
		height: auto;
		margin-top: 7pt;
		padding: 5px 5%;
		background-color: #FFFFFF;
		border-radius: 10px 10px 0 0;
	}

	.block-rent-content-detail-content {
		width: 100%;
		height: 20vh;
	}


	.block-rent-item-color {
		width: 5px;
		height: 15px;
		margin-top: auto;
		margin-bottom: auto;
		margin-right: 5px;
	}

	.block-rent-item-icon img {
		height: 100%;
		margin: auto;
	}

	.navbar-userType {
		color: #332c2b;
		font-size: 0.6em;
		text-align: left;
	}

	.navbar-user {
		margin-left: 3px;
	}

	.block-baseData-item-title {
		margin-left: 3px;
	}

	.block-rent-title {
		display: flex;
		align-items: center;
	}

	.block-rent-remark {
		display: flex;
	}

	.block-rent-remark-left {
		width: 70%;
		margin-left: 10px;
	}

	.block-rent-remark-right {
		width: 30%;
	}

	.block-rent-remark {
		font-size: 14px;
	}

	.block-rent-top {
		padding: 5px;
		border-radius: 0 0 10px 10px;
		margin-bottom: 7pt;
		background-color: #FFFFFF;
	}

	.block-rent-content-detail {
		border-radius: 10px;
		margin-top: 7pt;
		margin-bottom: 7pt;
		background-color: #FFFFFF;
		/*height: 30vh;*/
		padding-top: 5px;
	}

	.van-col {
		text-align: center;
	}

	.block-rent-pay {
		background-color: #FFFFFF;
		padding: 7px 14px;
		border-radius: 0 0 10px 10px;
	}

	.block-rent-pay .bottom {
		display: flex;
	}

	.block-rent-pay .top {
		margin: 10px 7px;
	}

	.block-rent-pay .top .total {
		display: flex;
	}

	.block-rent-pay .top .total .text {
		margin: auto 0;
		margin-left: auto;
	}

	.block-rent-pay .top .total .number {
		font-size: 20px;
		color: #DC143C;
		font-weight: bold;
	}

	.block-rent-pay .bottom .remark {
		font-size: 12px;
		color: #646566;
	}

	.block-rent-pay-total-button {
		margin-left: auto;
		margin-right: 5px;
		margin-top: auto;
		margin-bottom: auto;
	}

	.pay-button {
		height: 3.5em;
		width: 7em;
	}

	.block-rent-lately {
		background-color: #FFFFFF;
		padding: 5px;
		margin-top: 7px;
		border-radius: 10px;
		margin-bottom: 55px;
	}

	.block-rent-lately-line {
		height: 15em;
	}

	.block-rent-lately-top {
		display: flex;
	}

	.block-rent-lately-title {
		align-items: center;
	}

	.block-rent-lately-button {
		margin-left: auto;
		margin-right: 5px;
	}

	.van-tabs {
		height: 100%;
	}

	.van-empty {
		margin-top: 44px;
		height: 87.5vh;
	}

	.block-rent-remark-item {
		font-size: 10px;
		color: #969799;
	}

	.block-rent-remark-item-number {
		font-size: 14px;
		color: #000000;
		margin-left: 3px;
		margin-right: 3px;
	}

	.block-rent-content-detail-title {
		display: flex;
		margin-left: 5px;
	}

	.block-rent-lately-title {
		display: flex;
	}

	.img-number {
		width: 14px;
		margin-top: 3px;
	}

	.num-box {
		text-align: center;
	}

	.font-large {
		font-size: 18px;
	}

	.font-normal {
		font-size: 16px;
	}

	.font-mini {
		font-size: 14px;
	}

	.block-rent-title-remark {
		font-size: 12px;
		color: #969799;
		margin-left: 5px;
	}

	.switchContentButtonBox {
		margin-left: auto;
		margin-right: 5px;
	}

	.block-rent-content-detail-content-title {
		color: #969799;
		font-size: 12px;
		margin-bottom: 5px;
	}

	.more-box {
		width: 32%;
		margin-left: 66%;
		margin-right: 2%;
		margin-top: 44px;
		border-radius: 5px 5px 5px 5px;
		border: 1px solid #969799;
	}

	.more {
		padding: 3px;
	}

	.more-item {
		margin-top: 7pt;
		margin-bottom: 7pt;
		display: flex;
		align-items: center;
	}

	.more-item-icon-box {
		width: 2em;
	}

	.more-item-icon-box img {
		width: 70%;
		margin-top: 15%;
		margin-left: 25%;
	}

	.more-item-text {
		margin-top: 0;
	}

	::v-deep .van-picker__confirm {
		color: #26a5f9;
	}

	.trend {
		margin: 5px 0;
	}

	.trend .title {
		height: 2em;
		display: flex;
	}

	.trend .title>>>.van-divider {
		width: 78px;
		border-color: #000000;
	}

	.trend .title .text {
		text-align: center;
		margin: auto 14px;
	}

	.trend .bill-box .title,
	.trend .bill-box .row .item-box {
		display: flex;
		height: 2em;
		color: #646566;
	}

	.trend .bill-box .title .item,
	.trend .bill-box .row .item-box .item {
		text-align: center;
		font-size: 14px;
		margin: auto 0;
	}

	.trend .line-box {
		height: 20vh;
	}

	.block-rent-item-left-top {
		display: flex;
	}

	.block-rent-item-left-top>* {
		margin-top: auto;
		margin-bottom: auto;
	}

	.block-rent-item-left-top-img {
		width: 1.5em;
	}

	.block-rent-item-left-bottom {
		display: flex;
	}

	.block-rent-item-left-bottom>* {
		text-align: center;
	}

	.block-rent-item-left-bottom-value {
		width: 40%;
	}

	.block-rent-item-left-bottom-value-data {
		color: #DC143C;
		font-size: 20px;
	}

	.block-rent-item-left-bottom-point-data {
		color: #00c468;
		font-size: 20px;
	}

	.block-rent-item-left-bottom-value-text,
	.block-rent-item-left-bottom-point-text {
		font-size: 12px;
		color: #646566;
	}

	.block-rent-item-left-bottom-point {
		display: block;
		width: 60%;
	}

	.block-rent-item {
		display: flex;
	}

	.block-rent-item-left {
		display: block;
		width: 80%;
	}

	.block-rent-item-right {
		width: 20%;
	}

	.block-rent-together {
		height: auto;
		margin-top: 7pt;
		padding: 5px;
		background-color: #FFFFFF;
		border-radius: 10px;
	}

	.block-rent-together-title {
		display: flex;
	}

	.block-rent-together-content {
		display: flex;
	}

	.block-rent-together-item {
		width: 33.33%;

	}

	.block-rent-together-item-title {
		display: flex;
	}

	.block-rent-together-item-title-img {
		width: 1.4em;
		border-radius: 0.7em;
		margin-left: auto;
	}

	.block-rent-together-item-title-text {
		font-size: 12px;
		margin-left: 5px;
		margin-right: auto;
	}

	.block-rent-together-item-data {
		display: flex;
		font-size: 14px;
	}

	.block-rent-together-item-data-text {
		margin-left: auto;
	}

	.block-rent-together-item-data-value {
		margin-right: auto;
		color: #DC143C;
	}

	.block-rent-together-button-box {
		padding: 5px;
	}
</style>
