<template>
  <div id="bookingDetail-container">
    <div class="navbar">
      <div class="button-box" style="margin-left: 7px;margin-right: auto;" @click="onClickFallback">
        <img src="../assets/icon/left.png">
      </div>
      <div class="button-box" style="margin-left: auto;margin-right: 7px;" @click="showJumpPopup=true">
        <img src="../assets/icon/more.png">
      </div>
    </div>
    <div id='container'/>

    <div class="bottom" :style="bottomIsOpen?'height:60%':'height:17em'">
      <v-touch @swipeup="onBottomUp">
        <div class="basic">
          <div class="button-box">
            <img v-if="!bottomIsOpen" @click="bottomIsOpen=true" src="../assets/icon/open.png"/>
            <img v-if="bottomIsOpen" @click="bottomIsOpen=false" src="../assets/icon/close.png">
          </div>
          <div class="top">
            <div class="img-box" @click="onClickHouse">
              <img
                  src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1599130045348&di=5c32f43df9be14a7d61bb06c164afabb&imgtype=0&src=http%3A%2F%2Fimg5.k68.cn%2Fk68over2008-10%2F108295%2Fk68_cn_201032612928_02.jpg"/>
            </div>
            <div class="text-box" @click="onClickHouse">
              <div class="name">大运&nbsp;星河时代</div>
              <div class="count">待出租：25间</div>
              <div class="type">整租&nbsp;|&nbsp;1室1厅</div>
              <div class="price">
                ￥1365/月
              </div>
            </div>
            <div class="button-box">
              <img @click="onClickCallPhone" src="../assets/icon/phone.png">
              <img @click="onClickChat" src="../assets/icon/message.png">
              <img @click="onClickHouse" src="../assets/icon/right.png"/>
            </div>
          </div>
          <van-divider/>
          <div class="bottom">
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/electric.png"/>
              </div>
              <div class="text">￥1.5元/度</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/water.png"/>
              </div>
              <div class="text">￥7元/方</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/money.png"/>
              </div>
              <div class="text">押二付一</div>
            </div>
            <div class="item">
              <div class="img-box">
                <img src="../assets/icon/build.png"/>
              </div>
              <div class="text">7/14层</div>
            </div>
            <div class="item" style="width: 100%">
              <div class="img-box" style="margin-left: 0;">
                <img src="../assets/icon/address.png"/>
              </div>
              <div class="text">距您{{ distance >= 1000 ? Math.round(distance / 10) / 100 + '公里' : distance + '米' }}&nbsp;·&nbsp;龙园路与碧新路交叉口</div>
            </div>
          </div>
        </div>
      </v-touch>

      <div class="jump-button-box">
        <van-button @click="showJumpPopup=true" class="button" text="立即出发" color="#26a5f9"/>
      </div>

      <!--房屋配套-->
      <div class="equipment-info">
        <div class="title">房屋配套</div>
        <div class="item-box">
          <div class="item" v-for="i in equipmentItems" :key="i.text">
            <div class="icon-box">
              <img :src="i.url"/>
            </div>
            <div class="text">
              {{ i.text }}
            </div>
          </div>
        </div>
        <van-divider/>
        <div class="remark">
          【交通出行】
          <br/>
          公交途径5个站点。小区东门步行约200米就是水蓝湾公交总站，可直达大运，吉祥地铁站。1000米内有三个公交站点，地铁16号线在建中。连接水官高速、武深高速
          清平高速、南坪快速、丹坪快速相连，公交地铁也可以通往深圳各个区域，快速方便。
          <br/>
          【周边配套】
          <br/>
          小区门门口有华润万家超市，钱大妈，瑜伽馆，各种教育兴趣班，早餐店，满足生活需要。人人乐购物广场、华润万家、天虹购物商场、万科广场、星河COCOPARK公园有龙城公园、龙潭公园、龙园公园，龙园公园。地铁16号线、仁恒商圈综合体、龙岗儿童乐园
          <br/>
          【小区介绍】
          <br/>
          小区位于龙岗区龙平西路与黄阁北路交汇处，是2007年建成的，28万m²低密度社区，小区居住舒适，有游泳池，网球场，地上地下停车位充足，绿化达到80%，物业为招商物业公司，每天都有保安巡逻，治安很好。
        </div>

      </div>

      <!--该房东其他房源/周边房源数据分析-->
      <van-tabs class="other-box" v-model="otherIndex">
        <van-tab class="other-house-box" title="周边房源">
          <div class="item-box">
            <div class="item" v-for="(i,index) in otherHouseItems" :key="i.id"
                 :style="index%2==0?'margin-right:2%':'margin-left:2%'">
              <div class="img-box">
                <img class="img" :src="i.imgUrl"/>
              </div>
              <div class="title">
                {{ i.type }}租 · {{ i.livingRoomCount }}室{{ i.bedRoomCount }}厅 · {{ i.area }}m²
              </div>
              <div class="text">
                {{ i.district }}区/{{ i.residence }}
              </div>
              <div class="price">
                <div class="number">{{ i.price }}</div>
                <div class="unit">元/月</div>
              </div>
            </div>
          </div>
          <div class="button-box">
            <van-button color="#26a5f9" text="查看更多"/>
          </div>
        </van-tab>
        <van-tab class="nearby-house-box" title="周边房源数据分析">
          <div class="title">
            <div class="switch-button-box">
              <van-button @click="nearbyIndex==0?nearbyIndex=1:nearbyIndex=0" text="切换交通/配套" color="#26a5f9"
                          size="small"/>
            </div>
            <div class="input-box">
              <van-field v-model="destinationName" ref="destinationInput" @keydown="onInputDestination"
                         @blur="onBlurDestination" @focus="onFocusDestination" placeholder="输入工作/常用地址"/>
            </div>
            <div class="selected-button-box">
              <van-button @click="showFavorite" text="查看已筛选" color="#dc143c" size="small"/>
            </div>
          </div>
          <div class="primary-box" v-if="nearbyIndex==0">
            <div class="title">
              <div class="item">房源</div>
              <div class="item">面积</div>
              <div class="item">租金</div>
              <div class="item">水/电</div>
              <div class="item">其他费用</div>
              <div class="item">透光率</div>
              <div class="item">筛选</div>
            </div>
            <div class="row" v-for="(i,index) in showFiltered?primaryItemsFiltered:primaryItems" :key="i.name"
                 :style="index%2==0?'background-color:#EFEFEF':''">
              <div class="item">{{ i.name }}</div>
              <div class="item">
                <div class="text">{{ i.area }}</div>
                <div class="img-box">
                  <img v-if="index!=0&&i.area>primaryItems[0].area" src="../assets/icon/rise.png">
                  <img v-if="index!=0&&i.area<primaryItems[0].area" src="../assets/icon/decline.png">
                </div>
              </div>
              <div class="item">
                <div class="text">{{ i.price }}</div>
                <div class="img-box">
                  <img v-if="index!=0&&i.price>primaryItems[0].price" src="../assets/icon/rise.png">
                  <img v-if="index!=0&&i.price<primaryItems[0].price" src="../assets/icon/decline.png">
                </div>
              </div>
              <div class="item">
                <div class="text">{{ i.water }}/{{ i.electricity }}</div>
                <div class="img-box">
                  <img v-if="index!=0&&(i.water>primaryItems[0].water||i.electricity>primaryItems[0].electricity)"
                       src="../assets/icon/rise.png">
                  <img v-if="index!=0&&(i.water<primaryItems[0].water||i.electricity<primaryItems[0].electricity)"
                       src="../assets/icon/decline.png">
                </div>
              </div>
              <div class="item">
                <div class="text">{{ i.other }}</div>
                <div class="img-box">
                  <img v-if="index!=0&&i.other>primaryItems[0].other" src="../assets/icon/rise.png">
                  <img v-if="index!=0&&i.other<primaryItems[0].other" src="../assets/icon/decline.png">
                </div>
              </div>
              <div class="item">
                <div class="text">{{ Math.floor(i.lighting * 100) }}%</div>
                <div class="img-box">
                  <img v-if="index!=0&&i.lighting>primaryItems[0].lighting" src="../assets/icon/rise.png">
                  <img v-if="index!=0&&i.lighting<primaryItems[0].lighting" src="../assets/icon/decline.png">
                </div>
              </div>
              <div class="item" style="display: flex;">
                <div class="img-box">
                  <img v-if="!i.isFavoriteFlag" style="height: 1.5em;" src="../assets/icon/collection_line.png">
                  <img v-if="i.isFavoriteFlag" style="height: 1.5em;" src="../assets/icon/collectioned.png">
                </div>
              </div>
            </div>
          </div>
          <div class="secondary-box" v-if="nearbyIndex==1">
            <div class="title">
              <div class="item">房源</div>
              <div class="item">距离</div>
              <div class="item">公交路线</div>
              <div class="item">
                <van-dropdown-menu>
                  <van-dropdown-item v-model="supportIndex" :options="supportItems"/>
                </van-dropdown-menu>
              </div>
              <div class="item">筛选</div>
            </div>
            <div class="row" v-for="(i,index) in showFiltered?secondItemsFiltered:secondItems" :key="index"
                 :style="index%2==0?'background-color:#EFEFEF':''">
              <div class="item">
                <div class="text">{{ i.name }}</div>
              </div>
              <div class="item" style="display: flex;text-align: right">
                <div class="text" style="width: 70%">{{
                    i.distance >= 1000 ? Math.round(i.distance / 1000) + '公里' : i.distance + '米'
                  }}
                </div>
                <div class="img-box" style="width: 30%;">
                  <img style="height: 1em;" v-if="index!=0&&i.distance>secondItems[0].distance"
                       src="../assets/icon/rise.png">
                  <img style="height: 1em;" v-if="index!=0&&i.distance<secondItems[0].distance"
                       src="../assets/icon/decline.png">
                </div>
              </div>
              <div class="item">
                <div class="text">{{ i.route }}</div>
              </div>
              <div class="item">
                <div class="text" v-if="supportIndex==0">{{ i.supermarket }}</div>
                <div class="text" v-if="supportIndex==1">{{ i.hospital }}</div>
                <div class="text" v-if="supportIndex==2">{{ i.school }}</div>
              </div>
              <div class="item" style="display: flex;">
                <img v-if="!i.isFavoriteFlag" style="margin: auto;" src="../assets/icon/collection_line.png">
                <img v-if="i.isFavoriteFlag" style="margin: auto;" src="../assets/icon/collectioned.png">
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>

    </div>

    <van-popup v-model="showJumpPopup" position="bottom">
      <div class="jump-popup-menu">
        <div class="item" @click="onJumpToBaiduMap">百度地图</div>
        <van-divider/>
        <div class="item" @click="onJumpToAMap">高德地图</div>
        <van-divider/>
        <div class="item" @click="showJumpPopup=false">取消</div>
      </div>
    </van-popup>

  </div>
</template>
<script>
import AMap from 'AMap'

export default {
  name: "booking-detail",
  data() {
    return {
      positionPoint: {
        lat: 0,
        lng: 0
      },
      distance: 0,
      bottomIsOpen: false,
      equipmentItems: [
        {
          text: '床',
          url: require('../assets/icon/bed.png')
        },
        {
          text: '衣柜',
          url: require('../assets/icon/wardrobe.png')
        },
        {
          text: '洗衣机',
          url: require('../assets/icon/washing_machine.png')
        }, {
          text: '空调',
          url: require('../assets/icon/air_conditioner.png')
        }
      ],
      ownerInfo: {
        headUrl: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2092511787,1157776987&fm=26&gp=0.jpg',
        name: '心在当下',
        houseCount: 25,
        isCollection: false
      },
      //跳转菜单 (百度/高德)
      showJumpPopup: false,

      //该房东房源0 周边房源1
      otherIndex: 0,

      //周边房源交通分析0 配套分析1
      nearbyIndex: 0,

      //该房东其他房源
      otherHouseItems: [
        {
          id: 1001,
          type: '合',
          livingRoomCount: 1,
          bedRoomCount: 1,
          area: 36,
          price: 2600,
          imgUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1074388120,3557796269&fm=26&gp=0.jpg',
          district: '龙岗',
          residence: '天玑城-南苑'
        }, {
          id: 1002,
          type: '整',
          livingRoomCount: 1,
          bedRoomCount: 0,
          area: 24,
          price: 1800,
          imgUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1599889296316&di=5ccb17479ddc5ee0a882ac2270ca1aed&imgtype=0&src=http%3A%2F%2Fa-ssl.duitang.com%2Fuploads%2Fitem%2F201810%2F21%2F20181021015406_mhrjn.thumb.700_0.png',
          district: '龙岗',
          residence: '远洋新天地'
        }
      ],

      primaryItems: [
        {
          name: '荷坳·乐城',
          area: 18.2,
          price: 1353,
          electricity: 1.5,
          water: 7,
          other: 50,
          lighting: 0.75,
          isFavoriteFlag: false
        }, {
          name: '南联·天玑城',
          area: 32,
          price: 2650,
          electricity: 1.6,
          water: 8,
          other: 150,
          lighting: 0.85,
          isFavoriteFlag: true
        }, {
          name: '南联·远海新干线',
          area: 27.3,
          price: 2804,
          electricity: 1.5,
          water: 7,
          other: 300,
          lighting: 1,
          isFavoriteFlag: false
        }, {
          name: '坪地·大东城',
          area: 31.2,
          price: 2360,
          electricity: 1.5,
          water: 7,
          other: 200,
          lighting: 0.65,
          isFavoriteFlag: true
        }, {
          name: '大运·星河时代',
          area: 21.5,
          price: 2684,
          electricity: 1.5,
          water: 7,
          other: 250,
          lighting: 0.55,
          isFavoriteFlag: true
        }
      ],

      destinationName: '',

      //出发点 用于周边房源对比 默认值locationPoint 后根据destinationName获取
      destinationPoint: {
        lat: 0,
        lng: 0
      },

      supportItems: [
        {text: '商场', value: 0},
        {text: '医疗', value: 1},
        {text: '小学', value: 2},
      ],

      supportIndex: 0,

      secondItems: [
        {
          name: '荷坳·乐城',
          price: 1353,
          distance: 0,
          route: '',
          position: {
            lat: 22.679862,
            lng: 114.229512,
          },
          supermarket: '',
          hospital: '',
          school: '',
          isFavoriteFlag: false
        }, {
          name: '南联·天玑城',
          price: 2650,
          distance: 0,
          route: '',
          position: {
            lat: 22.730305,
            lng: 114.273681,
          },
          supermarket: '',
          hospital: '',
          school: '',
          isFavoriteFlag: true
        }, {
          name: '南联·远海新干线',
          price: 2804,
          distance: 0,
          route: '',
          position: {
            lat: 22.724443,
            lng: 114.267964,
          },
          supermarket: '',
          hospital: '',
          school: '',
          isFavoriteFlag: false
        }, {
          name: '坪地·恒大帝豪城',
          price: 2360,
          distance: 0,
          route: '',
          position: {
            lat: 22.713494,
            lng: 114.346889,
          },
          supermarket: '',
          hospital: '',
          school: '',
          isFavoriteFlag: true
        }, {
          name: '大运·星河时代',
          price: 2684,
          distance: 0,
          route: '',
          position: {
            lat: 22.698533,
            lng: 114.233945,
          },
          supermarket: '',
          hospital: '',
          school: '',
          isFavoriteFlag: true,
        }
      ],

      primaryItemsFiltered: [],

      secondItemsFiltered: [],

      showFiltered: false,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    onClickFallback: function () {
      this.$router.back();
    },
    onBottomUp: function () {
      this.bottomIsOpen = true;
    },
    onClickHouse() {
      this.$router.push('/rent-detail');
    },
    onClickChat() {
      this.$router.push('/chat-window');
    },
    onClicksSupport() {
      this.$router.push('/supporting');
    },
    onJumpToBaiduMap() {
      this.showJumpPopup = false;
      window.location.href = 'bdapp://map/direction?origin=我的位置&destination=深圳南联天玑城&coord_type=bd09ll&mode=transit&sy=0';
    },
    onJumpToAMap() {
      this.showJumpPopup = false;
      window.location.href = 'amapuri://route/plan/?did=&dlat=22.72441051&dlon=114.26689445&dname=深圳南联天玑城&dev=0&t=1';
    },
    onClickCallPhone() {
      window.location.href = 'tel://15897741992';
    },
    onInputDestination(key) {
      if (key.keyCode == 13) {
        this.$refs.destinationInput.blur();
      }
    },
    amap2baiduPoint(point, callback) {
      this.$axios({
        // url: "/baiduapi/geoconv/v1/",
        url: "http://api.map.baidu.com/geoconv/v1/",
        method: "GET",
        params: {
          coords: point.lng + ',' + point.lat,
          from: 3,
          to: 5,
          ak: 'EknQesPTKV54qEOYGm49uF0mern5O6bt'
        }
      }).then(res => {
        if (res.data.status == 0) {
          let result = {
            lng: res.data.result[0].x,
            lat: res.data.result[0].y,
          }
          callback(result);
        }
      })
    },
    afterPosition(point) {
      for (let i = 0; i < this.secondItems.length; i++) {
        this.neabySearch('幼儿园$小学$中学$大学$学校', this.secondItems[i].position, 2000, 1, ((data) => {
          this.secondItems[i].school = data[0].name;
        }));

        this.neabySearch('商场$超市$便利店', this.secondItems[i].position, 2000, 1, ((data) => {
          this.secondItems[i].supermarket = data[0].name;
        }));

        this.neabySearch('医院$社康$药店', this.secondItems[i].position, 2000, 1, ((data) => {
          this.secondItems[i].hospital = data[0].name;
        }));

        this.routePlanning(point, this.secondItems[i].position, ((text, distance) => {
              this.secondItems[i].route = text;
              this.secondItems[i].distance = distance;
            })
        );
      }
    },
    onBlurDestination() {
      //http://api.map.baidu.com/geocoding/v3/?address=%E6%B7%B1%E5%9C%B3%E5%B8%82%E5%B0%9A%E5%9F%8E%E5%A4%A7%E5%8E%A6&output=json&ak=EknQesPTKV54qEOYGm49uF0mern5O6bt&callback=showLocation
      this.$axios({
        // url: "/baiduapi/geocoding/v3",
        url: "http://api.map.baidu.com/geocoding/v3/",
        method: "GET",
        params: {
          address: this.destinationName,
          output: 'json',
          ak: 'EknQesPTKV54qEOYGm49uF0mern5O6bt'
        }
      }).then(res => {
        if (res.data.status == 0) {
          this.destinationPoint.lat = res.data.result.location.lat;
          this.destinationPoint.lng = res.data.result.location.lng;

          for (let i = 0; i < this.secondItems.length; i++) {
            this.routePlanning(this.destinationPoint, this.secondItems[i].position, ((text, distance) => {
              this.secondItems[i].route = text;
              this.secondItems[i].distance = distance;
            }))
          }
        }
      })
    },
    routePlanning(origin, destination, callback) {
      this.$axios({
        // url: "/baiduapi/directionlite/v1/transit",
        url: "http://api.map.baidu.com/directionlite/v1/transit",
        method: "GET",
        params: {
          origin: origin.lat + ',' + origin.lng,
          destination: destination.lat + ',' + destination.lng,
          ak: 'EknQesPTKV54qEOYGm49uF0mern5O6bt'
        }
      }).then(res => {
        let routeText = '';
        let routeDistance = 0;
        if (res.data.status == 0) {
          for (let i = 0; i < res.data.result.routes[0].steps.length; i++) {
            routeDistance = res.data.result.routes[0].distance;
            if (res.data.result.routes[0].steps[i][0].vehicle.name.length > 0) {
              routeText = routeText == '' ? res.data.result.routes[0].steps[i][0].vehicle.name : routeText += '→' + res.data.result.routes[0].steps[i][0].vehicle.name;
            }
          }
        }
        callback(routeText, routeDistance);
      })
    },
    neabySearch(query, position, radius, size, callback) {
      this.$axios({
        // url: "/baiduapi/place/v2/search",
        url: "http://api.map.baidu.com/place/v2/search",
        method: "GET",
        params: {
          query: query,
          location: position.lat + ',' + position.lng,
          radius: radius,
          page_size: size,
          output: 'json',
          ak: 'HsokRrR2m8zGqAKZh0odOCvv8sCDUOVN'
        }
      }).then(res => {
        callback(res.data.results);
      })
    },
    onFocusDestination() {
      this.nearbyIndex = 1;
    },
    init: function () {
      this.getPosition((result) => {
        this.positionPoint.lat = result.lat;
        this.positionPoint.lng = result.lng;
        this.amap2baiduPoint(this.positionPoint, (data) => {
          this.baiduPoint = data;
          console.log(this.baiduPoint);
          this.afterPosition(this.baiduPoint);
        })
        let end = {
          lng: 114.26244, lat: 22.72708
        }
        this.getRoute(this.positionPoint, end);
      });
    },
    getPosition: function (callback) {
      AMap.plugin('AMap.Geolocation', function () {
        let geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,//是否使用高精度定位，默认:true
          timeout: 10000,          //超过10秒后停止定位，默认：5s
          position: 'RB',    //定位按钮的停靠位置
          buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          zoomToAccuracy: true,   //定位成功后是否自动调整地图视野到定位点

        });
        geolocation.getCurrentPosition(function (status, result) {
          if (status == 'complete') {
            onComplete(result);
          }
        });
      });

      //解析定位结果
      function onComplete(data) {
        let result = {};
        result.lat = data.position.lat;
        result.lng = data.position.lng;
        callback(result);
      }


    },
    getRoute: function (start, end) {
      let that = this;
      let map = new AMap.Map("container", {
        zoom: 14,
      });

      let transferOption = {
        map: map,
        city: '深圳市',
        nightflag: true, // 是否计算夜班车
        policy: AMap.TransferPolicy.LEAST_TIME, // 其它policy取值请参照 https://lbs.amap.com/api/javascript-api/reference/route-search#m_TransferPolicy
      }

      let transfer = new AMap.Transfer(transferOption)

      //根据起、终点坐标查询公交换乘路线
      transfer.search(new AMap.LngLat(start.lng, start.lat), new AMap.LngLat(end.lng, end.lat), function (status, result) {
        // result即是对应的公交路线数据信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_TransferResult
        // console.log(result.plans[0].distance);
        that.distance = result.plans[0].distance;
        // if (status === 'complete') {
        //   console.log('绘制公交路线完成');
        // } else {
        //   console.log('公交路线数据查询失败' + result)
        // }
      });
    },
    showFavorite: function () {
      this.showFiltered = !this.showFiltered;
      console.log(this.showFiltered);
      this.primaryItemsFiltered = [];
      for (let i = 0; i < this.primaryItems.length; i++) {
        if (this.primaryItems[i].isFavoriteFlag) {
          this.primaryItemsFiltered.push(this.primaryItems[i]);
        }
      }

      this.secondItemsFiltered = [];
      for (let i = 0; i < this.secondItems.length; i++) {
        if (this.secondItems[i].isFavoriteFlag) {
          this.secondItemsFiltered.push(this.secondItems[i]);
        }
      }
    }
  }
}
</script>

<style scoped>
#bookingDetail-container {
  height: 100%;
  width: 100%;
}

#container {
  height: 70%;
  width: 100%;
}

::v-deep .amap-logo, ::v-deep .amap-copyright {
  z-index: -10;
}

.navbar {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  height: 2em;
}

.navbar > .button-box {
  height: 100%;
  margin: 2%;
  padding: 2%;
}

.navbar > .button-box > img {
  height: 100%;
}

#bookingDetail-container > .bottom {
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: #FFFFFF;
  transition: height .1s linear;

  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.jump-popup-menu .item {
  text-align: center;
  margin: 1em 0;
}

.jump-popup-menu >>> .van-divider {
  margin: 0;
}

.bottom > .jump-button-box {
  padding: 2%;
}

.bottom > .jump-button-box > .button {
  width: 100%;
  border-radius: 10px;
}

.basic {
  padding: 2%;
}

.basic > .button-box {
  height: 1.5em;
}

.basic > .button-box > img {
  height: 100%;
  display: block;
  margin: auto;
}

.basic > .top {
  display: flex;
}

.basic > .top > .img-box {
  height: 5em;
  width: 35%;
  margin-right: 5px;
}

.basic > .top > .img-box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.basic > .top > .text-box > * {
  font-size: 14px;
  color: #646566;
}

.basic > .top > .text-box > .name {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
}

.basic > .top > .text-box > .price {
  color: #DC143C;
  font-size: 16px;
}


.basic > .top > .button-box {
  margin: auto 0;
  margin-left: auto;
  display: flex;
}

.basic > .top > .button-box > img {
  height: 1.5em;
  width: 1.5em;
  margin: 0 5px;
}

.basic > .bottom {
  display: flex;
  flex-wrap: wrap;
}

.basic > .bottom > .item {
  display: flex;
  width: 25%;
  font-size: 14px;

}

.basic > .bottom > .item > .img-box {
  width: 1.2em;
  height: 1.2em;
  margin: auto 0;
  margin-left: auto;
  margin-bottom: 5px;
}

.basic > .bottom > .item > .img-box > img {
  width: 100%;
}

.basic > .bottom > .item > .text {
  margin-right: auto;
}

.equipment-info {
  margin: 2%;
  padding: 2%;
  background-color: #FFFFFF;
  border-radius: 5px;
}

.equipment-info >>> .van-divider {
  margin: 3px 0;
}

.equipment-info .title {
  font-weight: bold;
}

.equipment-info .item-box {
  display: flex;
}

.equipment-info .item {
  margin-top: 2%;
  width: 3em;
}

.equipment-info .icon-box {
  width: 80%;
  margin: auto;
}

.equipment-info img {
  width: 100%;
}

.equipment-info .text {
  text-align: center;
  font-size: 12px;
}

.equipment-info .remark {
  font-size: 12px;
}


.position-box {
  margin: 2%;
  background-color: #FFFFFF;
  padding: 2%;
  border-radius: 5px;
}

.position-box .title {
  font-weight: bold;
  display: flex;
}

.position-box .title > * {
  margin: auto 0;
}

.position-box .title .position-button-box {
  margin-left: auto;
}

.position-box .map-container {
  width: 100%;
  height: 20em;
}

.position-box .map-container .map-view {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.position-box >>> .anchorBL {
  display: none;
}

.position-box .jump-button-box {
  display: flex;
  margin-top: 5px;
}

.position-box .jump-button-box >>> .van-field {
  width: 50%;
}

.position-box .position-button-box {
  height: 2em;
}

.position-box .position-button {
  height: 100%;
}

.position-box .jump-button-box >>> .van-button {
  display: block;
  margin: auto 7px;
  border-radius: 5px;
  width: 25%;
}

.position-box >>> .van-popup {
  border-radius: 5px 5px 0 0;
}

.position-box .jump-popup-menu .item {
  text-align: center;
  margin: 1em 0;
}

.position-box .jump-popup-menu >>> .van-divider {
  margin: 0;
}


/*.other-house-box {*/
/*  padding: 2%;*/
/*  margin: 2%;*/
/*  background-color: #FFFFFF;*/
/*  border-radius: 5px;*/
/*}*/

.other-box {
  background-color: #FFFFFF;
  border-radius: 5px;
  margin: 2%;
  padding: 1%;
}

.other-box >>> .van-tabs__line {
  background-color: #26a5f9;
  z-index: 1;
}

.other-house-box {
  padding: 2%;
}

.other-house-box .item-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
  margin-top: 2%;
}

.other-house-box .item {
  width: 48%;
}

.other-house-box .img-box {
  width: 100%;
  height: 120px;
}

.other-house-box img {
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.other-house-box .item .title {
  font-size: 14px;
  font-weight: bolder;
  margin: 1% 0;

}

.other-house-box .text {
  color: #646566;
  font-size: 12px;
  margin: 1% 0;
}

.other-house-box .price {
  display: flex;
  color: #DC143C;
  margin: 1% 0;
}

.other-house-box .number {
  font-weight: bolder;
}

.other-house-box .unit {
  font-size: 12px;
  margin-top: auto;
  margin-bottom: 1px;
  margin-left: 1px;
}

.other-house-box .button-box {
  margin: 2% 0;
}

.other-house-box >>> .van-button {
  width: 100%;
  border-radius: 10px;
  display: block;
  margin: auto;
}

.nearby-house-box > .title {
  display: flex;
  margin-top: 2%;
  height: 3em;
}

.nearby-house-box .switch-button-box, .nearby-house-box .selected-button-box {
  margin: auto 0;
}

.nearby-house-box .switch-button-box >>> .van-button, .nearby-house-box .selected-button-box >>> .van-button {
  font-size: 10px;
}

.nearby-house-box .selected-button-box {
  margin-left: auto;
}

.nearby-house-box >>> .van-button {
  padding: 0 2px;
  border-radius: 5px;
}

.nearby-house-box .primary-box {
  border-radius: 5px;
  font-size: 12px;
}

.nearby-house-box .secondary-box {
  border-radius: 5px;
  font-size: 12px;
}

.nearby-house-box .secondary-box >>> .van-dropdown-menu__bar {
  height: 100%;
  background-color: #26a5f9;
}

.nearby-house-box .secondary-box >>> .van-ellipsis {
  color: #FFFFFF;
  font-size: 11px;
}

.nearby-house-box .secondary-box >>> .van-dropdown-menu__title--active {
  color: #FFFFFF;
}

.nearby-house-box .secondary-box >>> .van-dropdown-item__option--active, .nearby-house-box .secondary-box >>> .van-dropdown-item__icon {
  color: #26a5f9;
}

.nearby-house-box .primary-box .title, .nearby-house-box .secondary-box .title {
  display: flex;
  color: #FFFFFF;
  background-color: #26a5f9;
  font-size: 11px;
  height: 3em;
}

.nearby-house-box .primary-box .title .item, .nearby-house-box .secondary-box .title .item {
  margin: auto 0;
}

.nearby-house-box .primary-box .row {
  display: flex;
  min-height: 3em;
}

.nearby-house-box .primary-box .title .item,
.nearby-house-box .primary-box .row .item {
  width: 15%;
  text-align: center;
  margin: auto;
}

.nearby-house-box .primary-box .title .item .text,
.nearby-house-box .primary-box .row .item .text {
  width: 70%;
  text-align: right;
}

.nearby-house-box .primary-box .title .item .img-box,
.nearby-house-box .primary-box .row .item .img-box {
  width: 30%;
  margin: auto;
}

.nearby-house-box .secondary-box .title .item {
  width: 20%;
  text-align: center;
}

.nearby-house-box .primary-box .row .item {
  display: flex;
}

.nearby-house-box .primary-box .row img {
  height: 1em;
  display: block;
}

.nearby-house-box .secondary-box .row {
  display: flex;
  min-height: 3em;
}

.nearby-house-box .secondary-box .row .item {
  width: 20%;
  text-align: center;
  margin: auto;
}

.nearby-house-box .secondary-box .row .item .img-box {
  width: 30%;
  margin: auto;
}

.nearby-house-box .secondary-box img {
  height: 1.5em;
  display: block;
  margin: auto;
}

</style>
