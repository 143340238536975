<!--租房页面重构-->
<template>
	<div id="rent-container">
		<!--搜索框 置顶-->
		<van-sticky>
			<van-search v-model="searchValue" placeholder="请输入小区、商圈、地铁" />
		</van-sticky>
		<!--标签页 置顶-->
		<van-tabs offset-top="54px" :sticky="true" v-model="tabsIndex" :color="highlightColor" :lazy-render="true"
			:swipeable="true">
			<van-tab title="出租屋" :style="'background-color:'+backgroundColor">
				<!--选择栏 置顶-->
				<van-sticky offset-top="98px">
					<div class="option-box">
						<div class="option-item" @click="showRegionTree=!showRegionTree">
							<div class="option-item-text" :style="regionIsSelect()?'color:#26a5f9':''">位置</div>
							<div class="option-item-icon">
								<van-icon name="arrow-down" size="16px" :color="regionIsSelect()?'#26a5f9':''" />
							</div>
						</div>
						<div class="option-item" @click="showTypeBox=!showTypeBox">
							<div class="option-item-text" :style="typeIsSelect()?'color:#26a5f9':''">合/整租</div>
							<div class="option-item-icon">
								<van-icon name="arrow-down" size="16px" :color="typeIsSelect()>0?'#26a5f9':''" />
							</div>
						</div>
						<div class="option-item" @click="showPriceBox=!showPriceBox">
							<div class="option-item-text" :style="priceIndex>0?'color:#26a5f9':''">价格</div>
							<div class="option-item-icon">
								<van-icon name="arrow-down" size="16px" :color="priceIndex>0?'#26a5f9':''" />
							</div>
						</div>
						<div class="option-item" @click="showMoreBox=!showMoreBox">
							<div class="option-item-text" :style="moreIsSelect()?'color:#26a5f9':''">更多</div>
							<div class="option-item-icon">
								<van-icon name="arrow-down" size="16px" :color="moreIsSelect()>0?'#26a5f9':''" />
							</div>
						</div>
						<div class="option-item-sort" @click="showSortBox=!showSortBox">
							<img v-if="sortIndex==0" style="width: 1em" src="../assets/icon/sort.png">
							<img v-if="sortIndex>0" style="width: 1em" src="../assets/icon/sort_selected.png">
						</div>
					</div>
				</van-sticky>
				<!--选择栏弹出框-->
				<van-popup v-model="showPopup" position="top">
					<!--位置(树状横向选择框)-->
					<van-tree-select v-if="showRegionTree" :items="regionTypeItems"
						:main-active-index.sync="regionTypeIndex" class="region-tree">
						<!--树状横向选择框 内容插槽-->
						<template #content>
							<!--附近-->
							<div v-if="regionTypeIndex==1" class="regionType-item">
								<div class="nearby-item" v-for="(i,index) in nearbyItems" :key="i.text"
									@click="nearbyIndex=index;showRegionTree=false;">
									<div v-if="nearbyIndex==index" class="item-color" />
									<div class="item-text">{{ i.text }}</div>
								</div>
							</div>

							<!--区域-->
							<div v-if="regionTypeIndex==2" class="regionType-item">
								<van-tree-select :items="regionItems" :main-active-index.sync="districtIndex"
									:active-id.sync="streetIndex" />
							</div>

							<!--地铁-->
							<div v-if="regionTypeIndex==3" class="regionType-item">
								<van-tree-select :items="metroItems" :main-active-index.sync="lineIndex"
									:active-id.sync="stationIndex" />
							</div>

							<!--大学-->
							<div v-if="regionTypeIndex==4" class="regionType-item">
								<div class="university-item" v-for="(i,index) in universityItems" :key="i.text"
									@click="universityIndex=index;showRegionTree=false;">
									<div v-if="universityIndex==index" class="item-color" />
									<div class="item-text">{{ i.text }}</div>
								</div>
							</div>
						</template>
					</van-tree-select>

					<!--合/整租-->
					<div v-if="showTypeBox" class="type-box">
						<div class="type-item">
							<div class="item-title">
								<div class="text">合租</div>
								<div class="remark">共享空间,更有室友陪伴,给您家的感觉</div>
							</div>
							<div class="item-box">
								<van-button @click="onClickJointAll"
									:color="isJointTwoRoom&&isJointThreeRoom&&isJointFourRoom?'#26a5f9':''" round
									size="small" text="全部" />
								<van-button @click="isJointTwoRoom=!isJointTwoRoom" :color="isJointTwoRoom?'#26a5f9':''"
									round size="small" text="2居" />
								<van-button @click="isJointThreeRoom=!isJointThreeRoom"
									:color="isJointThreeRoom?'#26a5f9':''" round size="small" text="3居" />
								<van-button @click="isJointFourRoom=!isJointFourRoom"
									:color="isJointFourRoom?'#26a5f9':''" round size="small" text="4居+" />
							</div>
						</div>
						<van-divider />
						<div class="type-item">
							<div class="item-title">
								<div class="text">整租</div>
								<div class="remark">畅享自主空间,我的空间我做主</div>
							</div>
							<div class="item-box">
								<van-button @click="onClickWholeAll"
									:color="isWholeOneRoom&&isWholeTwoRoom?'#26a5f9':''" round size="small" text="全部" />
								<van-button @click="isWholeOneRoom=!isWholeOneRoom" :color="isWholeOneRoom?'#26a5f9':''"
									round size="small" text="1居" />
								<van-button @click="isWholeTwoRoom=!isWholeTwoRoom" :color="isWholeTwoRoom?'#26a5f9':''"
									round size="small" text="2居+" />
							</div>
						</div>
						<van-divider />
						<div class="button-box">
							<van-button class="reset" @click="resetTypeSelect" text="重置" />
							<van-button class="ok" @click="showTypeBox=false;" text="确定" color="#26a5f9" />
						</div>
					</div>

					<!--价格-->
					<div v-if="showPriceBox" class="price-box">
						<div class="price-item" @click="priceIndex=0;showPopup=false;">不限</div>
						<div class="price-item" @click="priceIndex=1;showPopup=false;"
							:style="priceIndex==1?'color:#26a5f9':''">
							1500以下
						</div>
						<div class="price-item" @click="priceIndex=2;showPopup=false;"
							:style="priceIndex==2?'color:#26a5f9':''">
							1500-2500元
						</div>
						<div class="price-item" @click="priceIndex=3;showPopup=false;"
							:style="priceIndex==3?'color:#26a5f9':''">
							2500-4000元
						</div>
						<div class="price-item" @click="priceIndex=4;showPopup=false;"
							:style="priceIndex==4?'color:#26a5f9':''">
							4000-6000元
						</div>
						<div class="price-item" @click="priceIndex=5;showPopup=false;"
							:style="priceIndex==5?'color:#26a5f9':''">
							6000-8000元
						</div>
						<div class="price-item" @click="priceIndex=6;showPopup=false;"
							:style="priceIndex==6?'color:#26a5f9':''">
							8000-10000元
						</div>
						<div class="price-item" @click="priceIndex=7;showPopup=false;"
							:style="priceIndex==7?'color:#26a5f9':''">
							10000元以上
						</div>
					</div>

					<!--更多-->
					<div v-if="showMoreBox" class="more-box">
						<div class="more-item">
							<div class="item-title">房屋特色</div>
							<div class="item-box">
								<van-button @click="isNearMetro=!isNearMetro" :color="isNearMetro?'#26a5f9':''" round
									size="small" text="近地铁" />
								<van-button @click="isNearBusiness=!isNearBusiness" :color="isNearBusiness?'#26a5f9':''"
									round size="small" text="近商圈" />
								<van-button @click="isMonthlyPayment=!isMonthlyPayment"
									:color="isMonthlyPayment?'#26a5f9':''" round size="small" text="可月租" />
								<van-button @click="isHaveBalcony=!isHaveBalcony" :color="isHaveBalcony?'#26a5f9':''"
									round size="small" text="独立阳台" />
								<van-button @click="isHaveToilet=!isHaveToilet" :color="isHaveToilet?'#26a5f9':''" round
									size="small" text="独立卫生间" />
								<van-button @click="isHaveElevator=!isHaveElevator" :color="isHaveElevator?'#26a5f9':''"
									round size="small" text="有电梯" />
							</div>
						</div>
						<van-divider />
						<div class="more-item">
							<div class="item-title">房屋朝向</div>
							<div class="item-box">
								<van-button @click="faceEast=!faceEast" :color="faceEast?'#26a5f9':''" round
									size="small" text="东" />
								<van-button @click="faceSouth=!faceSouth" :color="faceSouth?'#26a5f9':''" round
									size="small" text="南" />
								<van-button @click="faceWest=!faceWest" :color="faceWest?'#26a5f9':''" round
									size="small" text="西" />
								<van-button @click="faceNorth=!faceNorth" :color="faceNorth?'#26a5f9':''" round
									size="small" text="北" />
							</div>
						</div>
						<van-divider />
						<div class="more-item">
							<div class="item-title">房屋面积</div>
							<div class="item-box">
								<van-button @click="belowTen=!belowTen" :color="belowTen?'#26a5f9':''" round
									size="small" text="10m²以下" />
								<van-button @click="tenToFifteen=!tenToFifteen" :color="tenToFifteen?'#26a5f9':''" round
									size="small" text="10-15m²" />
								<van-button @click="fifteenToTwenty=!fifteenToTwenty"
									:color="fifteenToTwenty?'#26a5f9':''" round size="small" text="15-20m²" />
								<van-button @click="overTwenty=!overTwenty" :color="overTwenty?'#26a5f9':''" round
									size="small" text="20m²以上" />
							</div>
						</div>
						<van-divider />
						<div class="button-box">
							<van-button class="reset" @click="resetMoreSelect()" text="重置" />
							<van-button class="ok" @click="showMoreBox=false;" text="确定" color="#26A5F9" />
						</div>
					</div>

					<!--排序(ICON)-->
					<div v-if="showSortBox" class="sort-box">
						<div class="sort-item" @click="sortIndex=0;showPopup=false;">默认排序</div>
						<div class="sort-item" @click="sortIndex=1;showPopup=false;"
							:style="sortIndex==1?'color:#26a5f9':''">
							价格从低到高
						</div>
						<div class="sort-item" @click="sortIndex=2;showPopup=false;"
							:style="sortIndex==2?'color:#26a5f9':''">
							价格从高到底
						</div>
						<div class="sort-item" @click="sortIndex=3;showPopup=false;"
							:style="sortIndex==3?'color:#26a5f9':''">
							面积从小到大
						</div>
						<div class="sort-item" @click="sortIndex=4;showPopup=false;"
							:style="sortIndex==4?'color:#26a5f9':''">
							面积从大到小
						</div>
					</div>
				</van-popup>

				<div class="rent-content">
					<div @click="onClickRentItem(i)" class="rent-item" v-for="i in rentItems" :key="i.id">
						<div class="item-img-box">
							<img v-if="i.roomHeadImg" class="item-img" :src="i.roomHeadImg" />
							<img v-else class="item-img"
								src="https://pic1.ajkimg.com/display/anjuke/89b4390f76e68f71cd7553eef18af628/240x180c.jpg?t=1&srotate=1" />
						</div>
						<div class="item-info">
							<div class="item-title van-ellipsis">
								<div class="title-item">{{ i.rentType ==1? '整':'合' }}租</div>
								<div class="title-item">{{ i.city }}</div>
								<div class="title-item">{{ i.address }}</div>
							</div>

							<div class="item-type">
								<div class="title-item">{{ i.roomType }}</div>
								<div class="title-item">朝{{ i.roomDirection }}</div>
								<div class="type-item">{{ i.housingType }}</div>·
								<div class="type-item">{{ i.roomArea }}m²</div>·
								<div class="type-item">{{ i.houseFloor|| 5 }}/{{ i.totalFloor|| 23 }}层</div>
							</div>

							<div class="item-tags">
								<div class="item-tag" v-for="t in i.tags" :key="t.text">
									{{ t.text }}
								</div>
							</div>

							<div class="item-price">
								<div class="price-actual">￥{{ i.monthRent }}
									<div class="price-actual-unit">/月</div>
								</div>
								<div class="price-original">￥{{ i.originalPrice || 2300 }}/月</div>
							</div>

							<!-- <div class="item-distance van-ellipsis">
								<div class="item-positioningDistance">距您{{
                    i.positioningDistance >= 1000 ? Math.round(i.positioningDistance / 100) / 10 + '公里' : i.positioningDistance + '米'
                  }},
								</div>
								<div class="item-metroDistance">距{{ i.metroName }}{{
                    i.metroDistance >= 1000 ? Math.round(i.metroDistance / 100) / 10 + '公里' : i.metroDistance + '米'
                  }}
								</div>
							</div> -->

						</div>
					</div>
				</div>

			</van-tab>
			<van-tab title="公寓" :style="'backgroud-color:'+backgroundColor">
			</van-tab>
			<van-tab title="小区" :style="'backgroud-color:'+backgroundColor">
			</van-tab>
		</van-tabs>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {
		Search,
		Icon,
		Popup,
		TreeSelect,
		Button,
		Divider,
		Toast
	} from 'vant';

	Vue.use(Search);
	Vue.use(Icon);
	Vue.use(Popup);
	Vue.use(TreeSelect);
	Vue.use(Button);
	Vue.use(Divider);
	Vue.use(Toast);
	export default {
		name: "rent",
		data() {
			return {
				//高亮色
				highlightColor: '#26A5F9',
				//背景色
				backgroundColor: '#F8F8F8',

				//头部搜索框
				searchValue: '',
				//标签页选择下标
				tabsIndex: 0,

				//是否显示顶部弹出层
				showPopup: false,

				//是否显示(位置 合/整租 价格 更多 排序)选择框
				showRegionTree: false,
				showTypeBox: false,
				showPriceBox: false,
				showMoreBox: false,
				showSortBox: false,

				//排序选择下标(0为不限)
				sortIndex: 0,

				//价格区间选择下标(0为不限)
				priceIndex: 0,

				//房屋特色
				isNearMetro: false,
				isNearBusiness: false,
				isMonthlyPayment: false,
				isHaveBalcony: false,
				isHaveToilet: false,
				isHaveElevator: false,

				//房屋朝向
				faceEast: false,
				faceSouth: false,
				faceWest: false,
				faceNorth: false,

				//房屋面积
				belowTen: false,
				tenToFifteen: false,
				fifteenToTwenty: false,
				overTwenty: false,

				//合租房型
				isJointTwoRoom: false,
				isJointThreeRoom: false,
				isJointFourRoom: false,

				//整租房型
				isWholeOneRoom: false,
				isWholeTwoRoom: false,

				//位置类型下标
				regionTypeIndex: 0,
				//位置类型选项
				regionTypeItems: [{
						text: '不限'
					},
					{
						text: '附近'
					},
					{
						text: '区域'
					},
					{
						text: '地铁'
					},
					{
						text: '大学'
					}
				],

				//附近下标
				nearbyIndex: 0,
				//附近选项
				nearbyItems: [{
						text: '不限'
					},
					{
						text: '1km'
					},
					{
						text: '2km'
					},
					{
						text: '3km'
					},
					{
						text: '4km'
					},
					{
						text: '5km'
					}
				],

				//行政区选项
				districtIndex: 0,
				//街道选项
				streetIndex: 0,
				//区域选项
				regionItems: [{
						text: '南山区',
						children: [{
								id: 518000,
								text: '不限'
							},
							{
								id: 518001,
								text: '桃园'
							},
							{
								id: 518002,
								text: '大新'
							},
							{
								id: 518003,
								text: '侨城东'
							},
							{
								id: 518004,
								text: '长岭陂'
							},
							{
								id: 518005,
								text: '世界之窗'
							},
							{
								id: 518006,
								text: '白石洲'
							},
							{
								id: 518007,
								text: '赤湾'
							},
							{
								id: 518008,
								text: '大学城'
							},
							{
								id: 518009,
								text: '东角头'
							},
							{
								id: 518010,
								text: '高新园'
							},
							{
								id: 518011,
								text: '海上世界'
							},
							{
								id: 518012,
								text: '后海'
							},
							{
								id: 518013,
								text: '华侨城'
							},
							{
								id: 518014,
								text: '科苑'
							},
							{
								id: 518015,
								text: '鲤鱼门'
							},
							{
								id: 518016,
								text: '留仙洞'
							},
							{
								id: 518017,
								text: '前海湾'
							},
							{
								id: 518018,
								text: '侨城北'
							},
							{
								id: 518019,
								text: '蛇口港'
							},
							{
								id: 518020,
								text: '深大'
							},
							{
								id: 518021,
								text: '水湾'
							},
							{
								id: 518022,
								text: '塘朗'
							},
							{
								id: 518023,
								text: '湾厦'
							},
							{
								id: 518024,
								text: '西丽'
							},
							{
								id: 518025,
								text: '登良'
							},
							{
								id: 518026,
								text: '海月'
							},
							{
								id: 518027,
								text: '红树湾'
							},
							{
								id: 518028,
								text: '南山'
							},
							{
								id: 518029,
								text: '南油'
							},
							{
								id: 518030,
								text: '珠光'
							},
							{
								id: 518031,
								text: '龙井'
							},
							{
								id: 518032,
								text: '西丽湖'
							},
							{
								id: 518033,
								text: '茶光'
							},
							{
								id: 518034,
								text: '桃源村'
							},
							{
								id: 518035,
								text: '深云'
							},
							{
								id: 518036,
								text: '深湾'
							},
							{
								id: 518038,
								text: '深圳湾公园'
							},
							{
								id: 518039,
								text: '红树湾南'
							},
							{
								id: 518040,
								text: '梦海'
							},
							{
								id: 518041,
								text: '怡海'
							},
							{
								id: 518042,
								text: '荔林'
							},
							{
								id: 518043,
								text: '荔湾'
							},
							{
								id: 518044,
								text: '南油西'
							},
							{
								id: 518045,
								text: '四海'
							},
							{
								id: 518046,
								text: '妈湾'
							},
							{
								id: 518047,
								text: '高新南'
							},
							{
								id: 518048,
								text: '西丽火车站'
							},
							{
								id: 518049,
								text: '松坪'
							},
							{
								id: 518050,
								text: '科兴'
							},
							{
								id: 518051,
								text: '粤海门'
							},
							{
								id: 518052,
								text: '深圳湾口岸'
							},
							{
								id: 518053,
								text: '石鼓'
							},
							{
								id: 518054,
								text: '登良东'
							},
							{
								id: 518055,
								text: '深大南'
							},
							{
								id: 518056,
								text: '南山书城'
							},
							{
								id: 518057,
								text: '中山公园'
							},
							{
								id: 518058,
								text: '南头古城'
							},
							{
								id: 518059,
								text: '创业路'
							},
							{
								id: 518060,
								text: '白芒'
							},
							{
								id: 518061,
								text: '同乐'
							},
							{
								id: 518062,
								text: '工业六路'
							},
							{
								id: 518063,
								text: '桂湾'
							},
							{
								id: 518064,
								text: '前湾'
							},
							{
								id: 518065,
								text: '前湾公园'
							},
							{
								id: 518066,
								text: '铁路公园'
							},
							{
								id: 518067,
								text: '左炮台'
							}
						]
					},
					{
						text: '福田区',
						children: [{
								id: 518068,
								text: '不限'
							},
							{
								id: 518069,
								text: '华强路'
							},
							{
								id: 518070,
								text: '莲花村'
							},
							{
								id: 518071,
								text: '安托山'
							},
							{
								id: 518072,
								text: '福民'
							},
							{
								id: 518073,
								text: '红岭'
							},
							{
								id: 518074,
								text: '车公庙'
							},
							{
								id: 518075,
								text: '福田'
							},
							{
								id: 518076,
								text: '福田口岸'
							},
							{
								id: 518077,
								text: '岗厦'
							},
							{
								id: 518078,
								text: '岗厦北'
							},
							{
								id: 518079,
								text: '购物公园'
							},
							{
								id: 518080,
								text: '景田'
							},
							{
								id: 518081,
								text: '科学馆'
							},
							{
								id: 518082,
								text: '上梅林'
							},
							{
								id: 518083,
								text: '石厦'
							},
							{
								id: 518084,
								text: '市民中心'
							},
							{
								id: 518085,
								text: '香梅北'
							},
							{
								id: 518086,
								text: '香蜜湖'
							},
							{
								id: 518087,
								text: '益田'
							},
							{
								id: 518088,
								text: '竹子北'
							},
							{
								id: 518089,
								text: '华强北'
							},
							{
								id: 518090,
								text: '华新'
							},
							{
								id: 518091,
								text: '会展中心'
							},
							{
								id: 518092,
								text: '莲花北'
							},
							{
								id: 518093,
								text: '莲花西'
							},
							{
								id: 518094,
								text: '侨香'
							},
							{
								id: 518095,
								text: '少年宫'
							},
							{
								id: 518096,
								text: '深康'
							},
							{
								id: 518097,
								text: '通新岭'
							},
							{
								id: 518098,
								text: '香蜜'
							},
							{
								id: 518099,
								text: '燕南'
							},
							{
								id: 518100,
								text: '上沙'
							},
							{
								id: 518101,
								text: '沙尾'
							},
							{
								id: 518102,
								text: '下沙'
							},
							{
								id: 518103,
								text: '下梅林'
							},
							{
								id: 518104,
								text: '香梅'
							},
							{
								id: 518105,
								text: '梅村'
							},
							{
								id: 518106,
								text: '孖岭'
							},
							{
								id: 518107,
								text: '农林'
							},
							{
								id: 518108,
								text: '梅景'
							},
							{
								id: 518109,
								text: '皇岗村'
							},
							{
								id: 518110,
								text: '皇岗口岸'
							},
							{
								id: 518111,
								text: '福邻'
							},
							{
								id: 518112,
								text: '赤尾'
							},
							{
								id: 518113,
								text: '华强南'
							},
							{
								id: 518114,
								text: '黄木岗'
							},
							{
								id: 518115,
								text: '八卦岭'
							},
							{
								id: 518116,
								text: '冬瓜岭'
							},
							{
								id: 518117,
								text: '福保'
							},
							{
								id: 518118,
								text: '翰林'
							}
						]
					},
					{
						text: '罗湖区',
						children: [{
								id: 518119,
								text: '不限'
							},
							{
								id: 518120,
								text: '布心'
							},
							{
								id: 518121,
								text: '新秀'
							},
							{
								id: 518122,
								text: '怡景'
							},
							{
								id: 518123,
								text: '草埔'
							},
							{
								id: 518124,
								text: '翠竹'
							},
							{
								id: 518125,
								text: '国贸'
							},
							{
								id: 518126,
								text: '湖贝'
							},
							{
								id: 518127,
								text: '黄贝岭'
							},
							{
								id: 518128,
								text: '老街'
							},
							{
								id: 518129,
								text: '罗湖'
							},
							{
								id: 518130,
								text: '水贝'
							},
							{
								id: 518131,
								text: '田贝'
							},
							{
								id: 518132,
								text: '大剧院'
							},
							{
								id: 518133,
								text: '晒布'
							},
							{
								id: 518134,
								text: '太安'
							},
							{
								id: 518135,
								text: '笋岗'
							},
							{
								id: 518136,
								text: '洪湖'
							},
							{
								id: 518137,
								text: '银湖'
							},
							{
								id: 518138,
								text: '泥岗'
							},
							{
								id: 518139,
								text: '园岭'
							},
							{
								id: 518140,
								text: '红岭南'
							},
							{
								id: 518141,
								text: '鹿丹村'
							},
							{
								id: 518142,
								text: '人民南'
							},
							{
								id: 518143,
								text: '向西村'
							},
							{
								id: 518144,
								text: '文锦'
							},
							{
								id: 518145,
								text: '红岭北'
							},
							{
								id: 518146,
								text: '梧桐山'
							},
							{
								id: 518147,
								text: '莲塘'
							},
							{
								id: 518148,
								text: '仙湖路'
							},
							{
								id: 518149,
								text: '莲塘口岸'
							}
						]
					},
					{
						text: '盐田区',
						children: [{
								id: 518150,
								text: '不限'
							},
							{
								id: 518151,
								text: '大梅沙'
							},
							{
								id: 518152,
								text: '盐田路'
							},
							{
								id: 518153,
								text: '沙头角'
							},
							{
								id: 518154,
								text: '盐田港'
							},
							{
								id: 518155,
								text: '海山'
							},
							{
								id: 518156,
								text: '东部华侨城'
							},
							{
								id: 518157,
								text: '深外'
							},
							{
								id: 518158,
								text: '盐田食街'
							},
							{
								id: 518159,
								text: '小梅沙'
							}
						]
					},
					{
						text: '宝安区',
						children: [{
								id: 518160,
								text: '不限'
							},
							{
								id: 518161,
								text: '宝安中心'
							},
							{
								id: 518162,
								text: '翻身'
							},
							{
								id: 518163,
								text: '固戍'
							},
							{
								id: 518164,
								text: '后瑞'
							},
							{
								id: 518165,
								text: '机场东'
							},
							{
								id: 518166,
								text: '坪洲'
							},
							{
								id: 518167,
								text: '西乡'
							},
							{
								id: 518168,
								text: '新安'
							},
							{
								id: 518169,
								text: '兴东'
							},
							{
								id: 518170,
								text: '宝华'
							},
							{
								id: 518171,
								text: '宝体'
							},
							{
								id: 518172,
								text: '洪浪北'
							},
							{
								id: 518173,
								text: '临海'
							},
							{
								id: 518174,
								text: '灵芝'
							},
							{
								id: 518175,
								text: '宝安'
							},
							{
								id: 518176,
								text: '碧海湾'
							},
							{
								id: 518177,
								text: '马安山'
							},
							{
								id: 518178,
								text: '机场'
							},
							{
								id: 518179,
								text: '机场北'
							},
							{
								id: 518180,
								text: '福永'
							},
							{
								id: 518181,
								text: '桥头'
							},
							{
								id: 518182,
								text: '塘尾'
							},
							{
								id: 518183,
								text: '沙井'
							},
							{
								id: 518184,
								text: '后亭'
							},
							{
								id: 518185,
								text: '松岗'
							},
							{
								id: 518186,
								text: '碧头'
							},
							{
								id: 518187,
								text: '雅宝'
							},
							{
								id: 518188,
								text: '罗租'
							},
							{
								id: 518189,
								text: '流塘'
							},
							{
								id: 518190,
								text: '海上田园东'
							},
							{
								id: 518191,
								text: '上屋'
							},
							{
								id: 518192,
								text: '官田'
							},
							{
								id: 518193,
								text: '臣田'
							},
							{
								id: 518194,
								text: '新安公园'
							},
							{
								id: 518195,
								text: '上川'
							},
							{
								id: 518196,
								text: '应人石'
							},
							{
								id: 518197,
								text: '宝石路'
							},
							{
								id: 518198,
								text: '宝安客运中心'
							},
							{
								id: 518199,
								text: '臣田北'
							},
							{
								id: 518200,
								text: '洲石路'
							},
							{
								id: 518201,
								text: '钟屋'
							},
							{
								id: 518202,
								text: '黄田'
							},
							{
								id: 518203,
								text: '翠岗工业园'
							},
							{
								id: 518204,
								text: '怀德'
							},
							{
								id: 518205,
								text: '永和'
							},
							{
								id: 518206,
								text: '和平'
							},
							{
								id: 518207,
								text: '重庆路'
							},
							{
								id: 518208,
								text: '会展南'
							},
							{
								id: 518209,
								text: '松岗公园'
							},
							{
								id: 518210,
								text: '会展北'
							},
							{
								id: 518211,
								text: '溪头'
							},
							{
								id: 518212,
								text: '科技馆'
							},
							{
								id: 518213,
								text: '国际会议中心'
							}
						]
					},
					{
						text: '龙岗区',
						children: [{
								id: 518214,
								text: '坂田北'
							},
							{
								id: 518215,
								text: '平湖'
							},
							{
								id: 518216,
								text: '禾花'
							},
							{
								id: 518217,
								text: '双拥街'
							},
							{
								id: 518218,
								text: '龙城中路'
							},
							{
								id: 518219,
								text: '天建花园'
							},
							{
								id: 518220,
								text: '光雅园'
							},
							{
								id: 518221,
								text: '石芽岭'
							},
							{
								id: 518222,
								text: '甘坑'
							},
							{
								id: 518223,
								text: '凉帽山'
							},
							{
								id: 518224,
								text: '华为'
							},
							{
								id: 518225,
								text: '上李朗'
							},
							{
								id: 518226,
								text: '坳背'
							},
							{
								id: 518227,
								text: '大运北'
							},
							{
								id: 518228,
								text: '宝龙'
							},
							{
								id: 518229,
								text: '龙平'
							},
							{
								id: 518230,
								text: '龙东村'
							},
							{
								id: 518231,
								text: '内环路'
							},
							{
								id: 518232,
								text: '坪西路'
							},
							{
								id: 518233,
								text: '富坪街'
							},
							{
								id: 518234,
								text: '六联'
							}
						]
					},
					{
						text: '光明区',
						children: [{
								id: 518235,
								text: '不限'
							},
							{
								id: 518236,
								text: '长圳'
							},
							{
								id: 518237,
								text: '南庄'
							},
							{
								id: 518238,
								text: '观光路'
							},
							{
								id: 518239,
								text: '新明医院'
							},
							{
								id: 518240,
								text: '中山大学'
							},
							{
								id: 518250,
								text: '光明大街'
							},
							{
								id: 518260,
								text: '翠湖'
							},
							{
								id: 518261,
								text: '楼村'
							},
							{
								id: 518262,
								text: '公明广场'
							},
							{
								id: 518263,
								text: '合水口'
							}
						]
					},
					{
						text: '坪山区',
						children: [{
								id: 518264,
								text: '不限'
							},
							{
								id: 518265,
								text: '沙坑'
							},
							{
								id: 518266,
								text: '坑梓'
							},
							{
								id: 518267,
								text: '六联村'
							},
							{
								id: 518268,
								text: '坪山广场'
							},
							{
								id: 518269,
								text: '沙田'
							},
							{
								id: 518270,
								text: '坪山围'
							},
							{
								id: 518271,
								text: '坪山中学'
							},
							{
								id: 518272,
								text: '文化中心'
							},
							{
								id: 518273,
								text: '江岭'
							},
							{
								id: 518274,
								text: '朱洋坑'
							},
							{
								id: 518275,
								text: '新屋'
							},
							{
								id: 518276,
								text: '横塘'
							},
							{
								id: 518277,
								text: '田头'
							},
							{
								id: 518278,
								text: '田心'
							}
						]
					},
					{
						text: '龙华区',
						children: [{
								id: 518279,
								text: '不限'
							},
							{
								id: 518280,
								text: '深圳北'
							},
							{
								id: 518281,
								text: '白石龙'
							},
							{
								id: 518282,
								text: '民乐'
							},
							{
								id: 518283,
								text: '民治'
							},
							{
								id: 518284,
								text: '清湖'
							},
							{
								id: 518285,
								text: '上塘'
							},
							{
								id: 518286,
								text: '红山'
							},
							{
								id: 518287,
								text: '龙华(深圳地铁站)'
							},
							{
								id: 518288,
								text: '龙胜'
							},
							{
								id: 518289,
								text: '梅林关'
							},
							{
								id: 518290,
								text: '茜坑'
							},
							{
								id: 518291,
								text: '竹村'
							},
							{
								id: 518292,
								text: '观澜'
							},
							{
								id: 518293,
								text: '长湖'
							},
							{
								id: 518294,
								text: '观澜湖'
							},
							{
								id: 518295,
								text: '羊台山东'
							},
							{
								id: 518296,
								text: '元芳'
							},
							{
								id: 518297,
								text: '上芳'
							},
							{
								id: 518298,
								text: '清湖北'
							},
							{
								id: 518299,
								text: '松元厦'
							},
							{
								id: 518300,
								text: '牛湖'
							}
						]
					}
				],


				//地铁线选项
				lineIndex: 0,
				//地铁站选项
				stationIndex: 0,
				//地铁选项
				metroItems: [{
						text: '1号线',
						children: [{
								id: 100,
								text: '不限'
							},
							{
								id: 101,
								text: '机场东'
							},
							{
								id: 102,
								text: '后瑞'
							},
							{
								id: 103,
								text: '固戍'
							},
							{
								id: 104,
								text: '西乡'
							},
							{
								id: 105,
								text: '坪洲'
							},
							{
								id: 106,
								text: '宝体'
							},
							{
								id: 107,
								text: '宝安中心'
							},
							{
								id: 108,
								text: '新安'
							},
							{
								id: 109,
								text: '前海湾'
							},
							{
								id: 110,
								text: '鲤鱼门'
							},
							{
								id: 111,
								text: '大新'
							},
							{
								id: 112,
								text: '桃园'
							},
							{
								id: 113,
								text: '深大'
							},
							{
								id: 114,
								text: '高新园'
							},
							{
								id: 115,
								text: '白石洲'
							},
							{
								id: 116,
								text: '世界之窗'
							},
							{
								id: 117,
								text: '华侨城'
							},
							{
								id: 118,
								text: '侨城东'
							},
							{
								id: 119,
								text: '竹子林'
							},
							{
								id: 120,
								text: '车公庙'
							},
							{
								id: 121,
								text: '香蜜湖'
							},
							{
								id: 122,
								text: '购物公园'
							},
							{
								id: 123,
								text: '会展中心'
							},
							{
								id: 124,
								text: '岗厦'
							},
							{
								id: 125,
								text: '华强路'
							},
							{
								id: 126,
								text: '科学馆'
							},
							{
								id: 127,
								text: '大剧院'
							},
							{
								id: 128,
								text: '老街'
							},
							{
								id: 129,
								text: '国贸'
							},
							{
								id: 130,
								text: '罗湖'
							},
						]
					},
					{
						text: '2号线',
						children: [{
								id: 200,
								text: '不限'
							},
							{
								id: 201,
								text: '赤湾'
							},
							{
								id: 202,
								text: '蛇口岗'
							},
							{
								id: 203,
								text: '海上世界'
							},
							{
								id: 204,
								text: '水湾'
							},
							{
								id: 205,
								text: '东角头'
							},
							{
								id: 206,
								text: '湾厦'
							},
							{
								id: 207,
								text: '海月'
							},
							{
								id: 208,
								text: '登良'
							},
							{
								id: 209,
								text: '后海'
							},
							{
								id: 210,
								text: '科苑'
							},
							{
								id: 211,
								text: '红树湾'
							},
							{
								id: 212,
								text: '世界之窗'
							},
							{
								id: 213,
								text: '侨城北'
							},
							{
								id: 214,
								text: '深康'
							},
							{
								id: 215,
								text: '安托山'
							},
							{
								id: 216,
								text: '侨香'
							},
							{
								id: 217,
								text: '香蜜'
							},
							{
								id: 218,
								text: '香梅北'
							},
							{
								id: 219,
								text: '景田'
							},
							{
								id: 220,
								text: '莲花西'
							},
							{
								id: 221,
								text: '福田'
							},
							{
								id: 222,
								text: '市民中心'
							},
							{
								id: 223,
								text: '岗厦北'
							},
							{
								id: 224,
								text: '华强北'
							},
							{
								id: 225,
								text: '燕南'
							},
							{
								id: 226,
								text: '大剧院'
							},
							{
								id: 227,
								text: '湖贝'
							},
							{
								id: 228,
								text: '黄贝岭'
							},
							{
								id: 229,
								text: '新秀'
							}
						]
					},
					{
						text: '3号线',
						children: [{
							id: 300,
							text: '不限'
						}, {
							id: 301,
							text: '益田'
						}, {
							id: 302,
							text: '石厦'
						}, {
							id: 303,
							text: '购物公园'
						}, {
							id: 304,
							text: '福田'
						}, {
							id: 305,
							text: '少年宫'
						}, {
							id: 306,
							text: '莲花村'
						}, {
							id: 307,
							text: '华新'
						}, {
							id: 308,
							text: '通新岭'
						}, {
							id: 309,
							text: '红岭'
						}, {
							id: 310,
							text: '老街'
						}, {
							id: 311,
							text: '晒布'
						}, {
							id: 312,
							text: '翠竹'
						}, {
							id: 313,
							text: '田贝'
						}, {
							id: 314,
							text: '水贝'
						}, {
							id: 315,
							text: '草埔'
						}, {
							id: 316,
							text: '布吉'
						}, {
							id: 317,
							text: '木棉湾'
						}, {
							id: 318,
							text: '大芬'
						}, {
							id: 319,
							text: '丹竹头'
						}, {
							id: 320,
							text: '六约'
						}, {
							id: 321,
							text: '塘坑'
						}, {
							id: 322,
							text: '横岗'
						}, {
							id: 323,
							text: '永湖'
						}, {
							id: 324,
							text: '荷坳'
						}, {
							id: 325,
							text: '大运'
						}, {
							id: 326,
							text: '爱联'
						}, {
							id: 327,
							text: '吉祥'
						}, {
							id: 328,
							text: '龙城广场'
						}, {
							id: 329,
							text: '南联'
						}, {
							id: 330,
							text: '双龙'
						}]
					},
					{
						text: '4号线',
						children: [{
							id: 400,
							text: '不限'
						}, {
							id: 401,
							text: '福田口岸'
						}, {
							id: 402,
							text: '福民'
						}, {
							id: 403,
							text: '会展中心'
						}, {
							id: 404,
							text: '市民中心'
						}, {
							id: 405,
							text: '少年宫'
						}, {
							id: 406,
							text: '莲花北'
						}, {
							id: 407,
							text: '上梅林'
						}, {
							id: 408,
							text: '民乐'
						}, {
							id: 409,
							text: '白石龙'
						}, {
							id: 410,
							text: '深圳北'
						}, {
							id: 411,
							text: '红山'
						}, {
							id: 412,
							text: '上塘'
						}, {
							id: 413,
							text: '龙胜'
						}, {
							id: 414,
							text: '龙华'
						}, {
							id: 415,
							text: '清湖'
						}, ]
					},
					{
						text: '5号线',
						children: [{
							id: 500,
							text: '不限'
						}, {
							id: 501,
							text: '赤湾'
						}, {
							id: 502,
							text: '荔湾'
						}, {
							id: 503,
							text: '铁路公园'
						}, {
							id: 504,
							text: '妈湾'
						}, {
							id: 505,
							text: '前湾公园'
						}, {
							id: 506,
							text: '前湾'
						}, {
							id: 507,
							text: '桂湾'
						}, {
							id: 508,
							text: '前海湾'
						}, {
							id: 509,
							text: '临海'
						}, {
							id: 510,
							text: '宝华'
						}, {
							id: 511,
							text: '宝安中心'
						}, {
							id: 512,
							text: '翻身'
						}, {
							id: 513,
							text: '灵芝'
						}, {
							id: 514,
							text: '洪浪北'
						}, {
							id: 515,
							text: '兴东'
						}, {
							id: 516,
							text: '留仙洞'
						}, {
							id: 517,
							text: '西丽'
						}, {
							id: 518,
							text: '大学城'
						}, {
							id: 519,
							text: '塘朗'
						}, {
							id: 520,
							text: '长岭陂'
						}, {
							id: 521,
							text: '深圳北'
						}, {
							id: 522,
							text: '民治'
						}, {
							id: 523,
							text: '五和'
						}, {
							id: 524,
							text: '坂田'
						}, {
							id: 525,
							text: '杨美'
						}, {
							id: 526,
							text: '上水径'
						}, {
							id: 527,
							text: '下水径'
						}, {
							id: 528,
							text: '长龙'
						}, {
							id: 529,
							text: '布吉'
						}, {
							id: 530,
							text: '百鸽笼'
						}, {
							id: 531,
							text: '布心'
						}, {
							id: 532,
							text: '太安'
						}, {
							id: 533,
							text: '怡景'
						}, {
							id: 534,
							text: '黄贝岭'
						}]
					},
					{
						text: '6号线',
						children: [{
							id: 600,
							text: '不限'
						}, {
							id: 601,
							text: '科学馆'
						}, {
							id: 602,
							text: '通新岭'
						}, {
							id: 603,
							text: '体育中心'
						}, {
							id: 604,
							text: '八卦岭'
						}, {
							id: 605,
							text: '银湖'
						}, {
							id: 606,
							text: '翰岭'
						}, {
							id: 607,
							text: '梅林关'
						}, {
							id: 608,
							text: '深圳北'
						}, {
							id: 609,
							text: '红山'
						}, {
							id: 610,
							text: '上芬'
						}, {
							id: 611,
							text: '元芬'
						}, {
							id: 612,
							text: '阳台山东'
						}, {
							id: 613,
							text: '官田'
						}, {
							id: 614,
							text: '上屋'
						}, {
							id: 615,
							text: '长圳'
						}, {
							id: 616,
							text: '凤凰城'
						}, {
							id: 617,
							text: '光明大街'
						}, {
							id: 618,
							text: '光明'
						}, {
							id: 619,
							text: '科学公园'
						}, {
							id: 620,
							text: '楼村'
						}, {
							id: 621,
							text: '红花山'
						}, {
							id: 622,
							text: '公明广场'
						}, {
							id: 623,
							text: '合水口'
						}, {
							id: 624,
							text: '薯田埔'
						}, {
							id: 625,
							text: '松岗公园'
						}, {
							id: 626,
							text: '溪头'
						}, {
							id: 627,
							text: '松岗'
						}]
					},
					{
						text: '7号线',
						children: [{
							id: 700,
							text: '不限'
						}, {
							id: 701,
							text: '西丽湖'
						}, {
							id: 702,
							text: '西丽'
						}, {
							id: 703,
							text: '茶光'
						}, {
							id: 704,
							text: '珠光'
						}, {
							id: 705,
							text: '龙井'
						}, {
							id: 706,
							text: '桃源村'
						}, {
							id: 707,
							text: '深云'
						}, {
							id: 708,
							text: '安托山'
						}, {
							id: 709,
							text: '农林'
						}, {
							id: 710,
							text: '车公庙'
						}, {
							id: 711,
							text: '上沙'
						}, {
							id: 712,
							text: '沙尾'
						}, {
							id: 713,
							text: '石厦'
						}, {
							id: 714,
							text: '皇岗村'
						}, {
							id: 715,
							text: '福民'
						}, {
							id: 716,
							text: '皇岗口岸'
						}, {
							id: 717,
							text: '福邻'
						}, {
							id: 718,
							text: '赤尾'
						}, {
							id: 719,
							text: '华强南'
						}, {
							id: 720,
							text: '华强北'
						}, {
							id: 721,
							text: '华新'
						}, {
							id: 722,
							text: '黄木岗'
						}, {
							id: 723,
							text: '八卦岭'
						}, {
							id: 724,
							text: '红岭北'
						}, {
							id: 725,
							text: '笋岗'
						}, {
							id: 726,
							text: '洪湖'
						}, {
							id: 727,
							text: '田贝'
						}, {
							id: 728,
							text: '太安'
						}]
					},
					{
						text: '9号线',
						children: [{
							id: 900,
							text: '不限'
						}, {
							id: 901,
							text: '前湾'
						}, {
							id: 902,
							text: '梦海'
						}, {
							id: 903,
							text: '怡海'
						}, {
							id: 904,
							text: '荔林'
						}, {
							id: 905,
							text: '南油西'
						}, {
							id: 906,
							text: '南油'
						}, {
							id: 907,
							text: '南山书院'
						}, {
							id: 908,
							text: '深大南'
						}, {
							id: 909,
							text: '粤海门'
						}, {
							id: 910,
							text: '高新南'
						}, {
							id: 911,
							text: '红树湾南'
						}, {
							id: 912,
							text: '深湾'
						}, {
							id: 913,
							text: '深圳湾公园'
						}, {
							id: 914,
							text: '下沙'
						}, {
							id: 915,
							text: '车公庙'
						}, {
							id: 916,
							text: '香梅'
						}, {
							id: 917,
							text: '景田'
						}, {
							id: 918,
							text: '梅景'
						}, {
							id: 919,
							text: '下梅林'
						}, {
							id: 920,
							text: '梅村'
						}, {
							id: 921,
							text: '上梅林'
						}, {
							id: 922,
							text: '孖岭'
						}, {
							id: 923,
							text: '银湖'
						}, {
							id: 924,
							text: '泥岗'
						}, {
							id: 925,
							text: '红岭北'
						}, {
							id: 926,
							text: '园岭'
						}, {
							id: 927,
							text: '红岭'
						}, {
							id: 928,
							text: '红岭南'
						}, {
							id: 929,
							text: '鹿丹村'
						}, {
							id: 930,
							text: '人民南'
						}, {
							id: 931,
							text: '向西村'
						}, {
							id: 932,
							text: '文锦'
						}]
					},
					{
						text: '10号线',
						children: [{
							id: 1000,
							text: '不限'
						}, {
							id: 1001,
							text: '福民'
						}, {
							id: 1002,
							text: '岗厦'
						}, {
							id: 1003,
							text: '莲花村'
						}, {
							id: 1004,
							text: '冬瓜岭'
						}, {
							id: 1005,
							text: '孖岭'
						}, {
							id: 1006,
							text: '雅宝'
						}, {
							id: 1007,
							text: '南坑'
						}, {
							id: 1008,
							text: '光雅园'
						}, {
							id: 1009,
							text: '五和'
						}, {
							id: 1010,
							text: '坂田北'
						}, {
							id: 1011,
							text: '贝尔路'
						}, {
							id: 1012,
							text: '华为'
						}, {
							id: 1013,
							text: '岗头'
						}, {
							id: 1014,
							text: '雪象'
						}, {
							id: 1015,
							text: '甘坑'
						}, {
							id: 1016,
							text: '凉帽山'
						}, {
							id: 1017,
							text: '上李朗'
						}, {
							id: 1018,
							text: '木古'
						}, {
							id: 1019,
							text: '华南城'
						}, {
							id: 1020,
							text: '禾花'
						}, {
							id: 1021,
							text: '平湖'
						}, {
							id: 1022,
							text: '双拥街'
						}, ]
					},
					{
						text: '11号线',
						children: [{
							id: 1100,
							text: '不限'
						}, {
							id: 1101,
							text: '福田'
						}, {
							id: 1102,
							text: '车公庙'
						}, {
							id: 1103,
							text: '红树湾南'
						}, {
							id: 1104,
							text: '后海'
						}, {
							id: 1105,
							text: '南山'
						}, {
							id: 1106,
							text: '前海湾'
						}, {
							id: 1107,
							text: '宝安'
						}, {
							id: 1108,
							text: '碧海湾'
						}, {
							id: 1109,
							text: '机场'
						}, {
							id: 1110,
							text: '机场北'
						}, {
							id: 1111,
							text: '福永'
						}, {
							id: 1112,
							text: '桥头'
						}, {
							id: 1113,
							text: '塘尾'
						}, {
							id: 1114,
							text: '马安山'
						}, {
							id: 1115,
							text: '沙井'
						}, {
							id: 1116,
							text: '后亭'
						}, {
							id: 1117,
							text: '松岗'
						}, {
							id: 1118,
							text: '碧头'
						}]
					}
				],

				//大学下标
				universityIndex: -1,
				//大学选项
				universityItems: [{
						text: '深圳大学'
					},
					{
						text: '南方科技大学'
					},
					{
						text: '深圳大学(西丽校区)'
					},
					{
						text: '香港中文大学(深圳)'
					},
					{
						text: '暨南大学(深圳校区)'
					},
					{
						text: '哈尔滨工业大学(深圳校区)'
					}
				],

				//房源信息
				rentItems: [{
						id: '52556',
						imgUrl: 'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2055524462,3993149242&fm=26&gp=0.jpg',
						region: '荷坳',
						residential: 'THETOWN乐城',
						direction: '东',
						roomType: '主卧',
						positioningDistance: 1623,
						metroName: '3号线荷坳站',
						metroDistance: 512,
						rentType: '合',
						housingType: '4室1厅',
						roomArea: 18,
						houseFloor: 16,
						totalFloor: 22,
						price: 1351,
						originalPrice: 1930,
						tags: [{
							text: '新房源',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '近地铁',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '独立阳台',
							color: '#',
							backgroundColor: '#'
						}]
					},
					{
						id: '52557',
						imgUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1599130045348&di=5c32f43df9be14a7d61bb06c164afabb&imgtype=0&src=http%3A%2F%2Fimg5.k68.cn%2Fk68over2008-10%2F108295%2Fk68_cn_201032612928_02.jpg',
						region: '大运',
						residential: '星河时代',
						direction: '南',
						roomType: '次卧',
						positioningDistance: 4700,
						metroName: '3号线大运站',
						metroDistance: 786,
						rentType: '合',
						housingType: '3室2厅',
						roomArea: 18,
						houseFloor: 7,
						totalFloor: 12,
						price: 1523,
						originalPrice: 2631,
						tags: [{
							text: '近地铁',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '高绿化',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '押一付一',
							color: '#',
							backgroundColor: '#'
						}]
					},
					{
						id: '8',
						imgUrl: 'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=2110560044,1629409326&fm=26&gp=0.jpg',
						region: '龙城公园',
						residential: '顺景花园',
						direction: '东',
						roomType: '主卧',
						positioningDistance: 3200,
						metroName: '3号线龙城广场站',
						metroDistance: 6812,
						rentType: '合',
						housingType: '4室1厅',
						roomArea: 22,
						houseFloor: 3,
						totalFloor: 6,
						price: 1568,
						originalPrice: 2674,
						tags: [{
							text: '近小学',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '独立卫生间',
							color: '#',
							backgroundColor: '#'
						}]
					},
					{
						id: '52559',
						imgUrl: 'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3884775638,3547246448&fm=26&gp=0.jpg',
						region: '龙岗中心城',
						residential: '风临域',
						metroName: '3号线龙城广场站',
						direction: '西',
						roomType: '',
						positioningDistance: 2345,
						metroDistance: 3612,
						rentType: '整',
						housingType: '1室1厅',
						roomArea: 36,
						houseFloor: 28,
						totalFloor: 30,
						price: 2954,
						originalPrice: 4123,
						tags: [{
							text: '新房源',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '近学校',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '有电视',
							color: '#',
							backgroundColor: '#'
						}]
					},
					{
						id: '52560',
						imgUrl: 'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=350860651,1149384638&fm=26&gp=0.jpg',
						region: '南联',
						residential: '天玑城-南苑',
						direction: '东',
						roomType: '主卧',
						positioningDistance: 1623,
						metroName: '3号龙岗线南联站',
						metroDistance: 123,
						rentType: '合',
						housingType: '5室3厅',
						roomArea: 26,
						houseFloor: 18,
						totalFloor: 20,
						price: 2651,
						originalPrice: 4513,
						tags: [{
							text: '新装修',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '近地铁',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '近商圈',
							color: '#',
							backgroundColor: '#'
						}]
					},
					{
						id: '52561',
						imgUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1599130203525&di=87a58e531e6ed4f1363778e5d66377f2&imgtype=0&src=http%3A%2F%2Fchengde.zhuangyi.com%2FU_Image%2F2016%2F0809%2Fe%2F20160809150949_3348.jpg',
						region: '南联',
						residential: '怡龙枫景园',
						direction: '东',
						roomType: '主卧',
						positioningDistance: 1823,
						metroName: '3号龙岗线南联站',
						metroDistance: 1584,
						rentType: '合',
						housingType: '3室1厅',
						roomArea: 12,
						houseFloor: 16,
						totalFloor: 22,
						price: 1462,
						originalPrice: 1852,
						tags: [{
							text: '免费网络',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '近地铁',
							color: '#',
							backgroundColor: '#'
						}, {
							text: '近公园',
							color: '#',
							backgroundColor: '#'
						}]
					}
				],
			}
		},
		mounted() {
			this.getRoomList();
		},
		watch: {
			showRegionTree: function(newValue) {
				if (newValue) {
					this.closeAllBox('RegionTree');
					this.showPopup = true;
				}
				this.checkShowPopup();
			},
			showTypeBox: function(newValue) {
				if (newValue) {
					this.closeAllBox('TypeBox');
					this.showPopup = true;
				}
				this.checkShowPopup();
			},
			showPriceBox: function(newValue) {
				if (newValue) {
					this.closeAllBox('PriceBox');
					this.showPopup = true;
				}
				this.checkShowPopup();
			},
			showMoreBox: function(newValue) {
				if (newValue) {
					this.closeAllBox('MoreBox');
					this.showPopup = true;
				}
				this.checkShowPopup();
			},
			showSortBox: function(newValue) {
				if (newValue) {
					this.closeAllBox('SortBox');
					this.showPopup = true;
				}
				this.checkShowPopup();
			},
			showPopup: function(newValue) {
				if (!newValue) {
					this.showRegionTree = false;
					this.showTypeBox = false;
					this.showPriceBox = false;
					this.showMoreBox = false;
					this.showSortBox = false;
				}
			},
			streetIndex: function() {
				this.showRegionTree = false;
			},
			stationIndex: function() {
				this.showRegionTree = false;
			},
			regionTypeIndex: function(newValue) {
				if (newValue == 0) {
					this.showRegionTree = false;
				}
			}
		},
		methods: {
			getRoomList() {
				this.$axios({
					url: "room/list/isRent",
					method: "GET"
				}).then(res => {
					console.log(res)
					if (res.data && res.status === 10000) {
						this.rentItems = res.data
					} else {
						Toast.fail(res.msg || '登录失败');
					}
				}).catch(error => {
					console.log(error, 'error')
				})
			},
			checkShowPopup() {
				if (!this.showPriceBox && !this.showRegionTree && !this.showTypeBox && !this.showMoreBox && !this
					.showSortBox) {
					this.showPopup = false;
				}
			},
			closeAllBox(exception) {
				if (exception != 'RegionTree')
					this.showRegionTree = false;
				if (exception != 'TypeBox')
					this.showTypeBox = false;
				if (exception != 'PriceBox')
					this.showPriceBox = false;
				if (exception != 'MoreBox')
					this.showMoreBox = false;
				if (exception != 'SortBox')
					this.showSortBox = false;
			},
			moreIsSelect() {
				if (this.isNearMetro || this.isNearBusiness || this.isMonthlyPayment || this.isHaveBalcony || this
					.isHaveToilet || this.isHaveElevator || this.faceEast || this.faceSouth || this.faceWest || this
					.faceNorth || this.belowTen || this.tenToFifteen || this.fifteenToTwenty || this.overTwenty) {
					return true;
				} else {
					return false;
				}
			},
			typeIsSelect() {
				if (this.isJointTwoRoom || this.isJointThreeRoom || this.isJointFourRoom || this.isWholeOneRoom || this
					.isWholeTwoRoom) {
					return true;
				} else {
					return false;
				}
			},
			regionIsSelect() {
				if (this.regionTypeIndex == 0) {
					return false;
				} else {
					return true;
				}
			},
			resetMoreSelect() {
				this.isNearMetro = false;
				this.isNearBusiness = false;
				this.isMonthlyPayment = false;
				this.isHaveBalcony = false;
				this.isHaveToilet = false;
				this.isHaveElevator = false;
				this.faceEast = false;
				this.faceSouth = false;
				this.faceWest = false;
				this.faceNorth = false;
				this.belowTen = false;
				this.tenToFifteen = false;
				this.fifteenToTwenty = false;
				this.overTwenty = false;
			},
			resetTypeSelect() {
				this.isJointTwoRoom = false;
				this.isJointThreeRoom = false;
				this.isJointFourRoom = false;
				this.isWholeOneRoom = false;
				this.isWholeTwoRoom = false;
			},
			onClickJointAll() {
				if (this.isJointTwoRoom && this.isJointThreeRoom && this.isJointFourRoom) {
					this.isJointTwoRoom = false;
					this.isJointThreeRoom = false;
					this.isJointFourRoom = false;
				} else {
					this.isJointTwoRoom = true;
					this.isJointThreeRoom = true;
					this.isJointFourRoom = true;
				}
			},
			onClickWholeAll() {
				if (this.isWholeOneRoom && this.isWholeTwoRoom) {
					this.isWholeOneRoom = false;
					this.isWholeTwoRoom = false;
				} else {
					this.isWholeOneRoom = true;
					this.isWholeTwoRoom = true;
				}
			},
			onClickRentItem(i) {
				this.$router.push({
					path: '/rent-detail?',
					query: {
						roomItem:JSON.stringify(i)
					}
				});
			}
		}
	}
</script>

<style scoped>
	#rent-container {
		height: 100%;
		background-color: #F8F8F8;
	}

	/*覆盖vant官方样式*/
	/*--------------*/
	.option-item-icon>>>.van-icon {
		display: block;
		margin: auto 0;
	}

	::v-deep .van-overlay {
		top: 129px;
	}


	::v-deep .van-popup {
		top: 129px;
	}

	::v-deep .van-button--small {
		padding: 0;
	}

	.van-sidebar-item--select::before {
		background-color: #26a5f9;
	}

	::v-deep .van-tree-select__item--active {
		color: #26a5f9;
	}

	/*解决滑动闪烁*/
	::v-deep .van-sticky>div {
		position: fixed;
		right: 0;
		left: 0;
		z-index: 99;
	}

	/*--------------*/

	.option-box {
		display: flex;
		background-color: #FFFFFF;
		padding: 7px;
		color: #646566;
	}

	.option-item {
		width: 23%;
		display: flex;
	}

	.option-item-text {
		font-size: 12px;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: auto;
		height: 100%;
	}

	.option-item-icon {
		margin-top: auto;
		margin-bottom: auto;
		margin-right: auto;
		height: 100%;
	}

	.option-item-sort {
		margin: auto;
	}

	.option-item-sort img {
		display: block;
		margin: auto;
	}

	.sort-item,
	.price-item {
		text-align: center;
		margin: 14px auto;
		color: #646566;
	}

	.type-box .button-box {
		box-shadow: 0px 1px 10px 0px #646566;
		padding: 7px 14px;
		display: flex;
	}

	.type-box .button-box .reset {
		width: 30%;
		margin-right: auto;
	}

	.type-box .button-box .ok {
		width: 60%;
		margin-left: auto;
	}

	.type-item .item-title {
		padding: 2%;
		display: flex;
	}

	.type-item .item-title .remark {
		color: #646566;
		font-size: 12px;
		margin-top: auto;
		margin-left: 5px;
	}

	.type-item {
		margin: auto;
	}

	.type-item .item-box .van-button {
		width: 22%;
		margin: 7px 1.5%;
	}

	.more-box .button-box {
		box-shadow: 0px 1px 10px 0px #646566;
		padding: 7px 14px;
		display: flex;
	}

	.more-box .button-box .reset {
		width: 30%;
		margin-right: auto;
	}

	.more-box .button-box .ok {
		width: 60%;
		margin-left: auto;
	}

	.more-item .item-title {
		padding: 2%;
	}

	.more-item {
		margin: auto;
	}

	.more-item .item-box .van-button {
		width: 22%;
		margin: 7px 1.5%;
	}

	.nearby-item,
	.university-item {
		display: flex;
		height: 20px;
		padding: 14px 12px;
		padding-left: 0;
		color: #323233;
		font-size: 14px;
		text-align: center;
	}

	.nearby-item .item-color,
	.university-item .item-color {
		width: 4px;
		height: 16px;
		background-color: #26a5f9;
		position: absolute;
	}

	.nearby-item .item-text,
	.university-item .item-text {
		margin-left: 12px;
	}


	.rent-content {
		/*margin-bottom: 50px;*/
		padding-bottom: 50px;
		padding-top: 128px;
	}

	.rent-item {
		display: flex;
		margin: 7px;
		background-color: #FFFFFF;
		border-radius: 5px;
		padding: 7px;
	}

	.item-img-box {
		width: 40%;
		margin-right: 7px;
		max-height: 94px;
	}

	.item-info {
		width: 60%;
		height: 100%;
	}

	.item-img {
		width: 100%;
		height: 100%;
		border-radius: 5px;
		object-fit: cover;
	}

	.item-title {
		display: flex;
		margin-bottom: 3px;
		font-size: 14px;
		font-weight: 600;
	}

	.item-distance {
		margin: 3px;
		margin-bottom: 0;
		display: flex;
		font-size: 10px;
	}

	.item-price {
		margin: 3px 0;
		display: flex;
	}

	.item-type {
		display: flex;
		margin: 3px 0;
		font-size: 10px;
	}

	.title-item {
		margin: 0 2px;
	}

	.type-item {
		margin: 0 3px;
	}

	.price-actual {
		color: #FF0000;
		font-weight: bold;
		display: flex;
	}

	.price-actual-unit {
		font-weight: normal;
		margin-top: 3px;
		font-size: 12px;
	}

	.price-original {
		color: #b3b3b3;
		font-size: 12px;
		text-decoration: line-through;
		margin-top: 4px;
		margin-left: 3px;
	}

	.item-tags {
		display: flex;
	}

	.item-tag {
		font-size: 8px;
		margin: auto 3px;
		background-color: #efefef;
		padding: 1px 4px;
		border-radius: 2px;
	}
</style>
