var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"account-management-container"}},[_c('van-nav-bar',{staticClass:"navbar",attrs:{"placeholder":true,"fixed":true,"border":false},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left","size":"2em","color":"#ffffff"},on:{"click":_vm.onClickFallback}})]},proxy:true},{key:"title",fn:function(){return [_c('span',{staticClass:"navbar-title"},[_vm._v("账号管理")])]},proxy:true}])}),_c('div',{staticClass:"item-box"},[_c('div',{staticClass:"item"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('van-icon',{attrs:{"name":"arrow","color":"#646566"}})],1)]),_c('van-divider'),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.$router.push({name:'verifi-phone'})}}},[_vm._m(1),_c('div',{staticClass:"right"},[_c('van-icon',{attrs:{"name":"arrow","color":"#646566"}})],1)]),_c('van-divider'),_c('div',{staticClass:"item"},[_vm._m(2),_c('div',{staticClass:"right"},[_c('van-icon',{attrs:{"name":"arrow","color":"#646566"}})],1)]),_c('van-divider'),_c('div',{staticClass:"item"},[_vm._m(3),_c('div',{staticClass:"right"},[_c('van-icon',{attrs:{"name":"arrow","color":"#646566"}})],1)]),_c('van-divider'),_c('div',{staticClass:"item"},[_vm._m(4),_c('div',{staticClass:"right"},[_c('van-icon',{attrs:{"name":"arrow","color":"#646566"}})],1)]),_c('van-divider'),_c('div',{staticClass:"item"},[_vm._m(5),_c('div',{staticClass:"right"},[_c('van-icon',{attrs:{"name":"arrow","color":"#646566"}})],1)]),_c('van-divider')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"text"},[_vm._v("旦巢账号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"text"},[_vm._v("修改手机号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"text"},[_vm._v("二维码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"text"},[_vm._v("修改登录密码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"text"},[_vm._v("登录设备管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"text"},[_vm._v("旦巢安全中心")])])
}]

export { render, staticRenderFns }