import axios from "axios";
import { Toast } from 'vant';
import etc from "./etc.js";

const querystring = require("querystring");
const { baseURL, timeout, withCredentials } = etc;
let access_token = localStorage.getItem("_tn");
let service = axios.create({
  baseURL: baseURL,
  timeout: timeout,
  withCredentials: withCredentials
});

//添加请求拦截器
service.interceptors.request.use(
  config => {
    if (config.method == "get") {
      // GET 请求，通常要加个时间参数
      if (config.params) {
        config.params._ = new Date().getTime();
      } else {
        config.params = {
          _: new Date().getTime()
        };
      }
    } else {
      // 处理[POST、PUT]: content-Type => application/x-www-form-urlencoded;
      if (config.qs) {
        config.headers["Content-Type"] = "application/x-www-form-urlencoded;";
        config.data = querystring.stringify(config.data);
      }
    }
    config.headers["Authorization"] = config.headers["Authorization"]
      ? config.headers["Authorization"]
      : access_token
      ? `bearer ${access_token}`
      : "Basic Y3BzaXM6Y3BzaXM1OVhDVmg=";
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//添加响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    // 由于接口返回不规范，顾返回全部响应
    // if (res.success) {
    //   return Promise.resolve(res)
    // } else {
    //   Message({
    //     message: res.message,
    //     type: "error",
    //     duration: 5 * 1000,
    //   })
    //   return Promise.reject("response error!")
    // }
    return Promise.resolve(res);
  },
  error => {
	console.error(error , '接口请求出错返回的error')
    if (error.response) {
      if (error.response.status == 401) {
        console.error("未登录，请先登录！");
        Toast({
          message: "未登录，请先登录！",
          type: "error",
          duration: 2 * 1000
        });
        // 跳转到登录页面去
        localStorage.removeItem("_tn");
        if (process.env.NODE_ENV === "development"){
          window.location.href = "/#/login?c=a";
        } else {
          // 正式环境目前使用的是c认证模式，如果响应401直接请求主页，然后回自动跳转到单点登录的页面
          window.location.href = "/cpsis/l/redirect?redirect_url="+encodeURIComponent(location.href);
        }

      } else if (error.response.status == 404) {
        console.error("未找到相关资源文件！");
        // Message({
        //   message: "未找到相关资源文件！",
        //   type: "error",
        //   duration: 5 * 1000
        // });
        // window.location.href = "/#/404";
      } else if (error.response.status == 500) {
        console.error("服务端存在错误！");
        Toast({
          message: "服务端存在错误！",
          type: "error",
          duration: 5 * 1000
        });
      } else {
        console.error("未知错误，请联系开发者！");
        Toast({
          message: "未知错误，请联系开发者！",
          type: "error",
          duration: 5 * 1000
        });
      }
    }

    return Promise.reject(error);
  }
);

export default service;
