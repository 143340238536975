<!--聊天窗口-->

<!--
到访 即 发起请求
来访 即 接受请求
-->

<!--
点击申请到访PickerDate获得值
点击确定PickerDate=>RequestDate
PickerDate归零,并且RequestDate无法修改

点击申请到访PickerDate从
-->

<!--
点击同意到访顶部只显示到访时间和显示详情按钮
点击详情按钮可以修改到访逗留时间和取消授权
点击修改逗留时间,pickDate=>visitDate

-->

<template>
	<div ref="chatWindow" id="chat-window">
		<van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
			<template #left>
				<van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff" />
			</template>
			<template #title>
				<span class="navbar-title">刘昌</span>
			</template>
			<template #right>
				<van-icon name="contact" size="2em" color="#ffffff" />
			</template>
		</van-nav-bar>

		<van-sticky offset-top="46px">
			<div class="visit">
				<div class="request" v-if="visitInfo.status==0">
					<div class="request-left">
						<div class="request-left-title">申请来访</div>
						<div class="request-left-date">2020-09-15 至 2020-09-17</div>
					</div>
					<div class="request-right">
						<van-button @click="onClickVisitAgree" class="request-right-button" text="同意" color="#26a5f9"
							size="small" />
						<van-button @click="onClickVisitRefuse" class="request-right-button" text="拒绝" color="#2769fc"
							size="small" />
					</div>
				</div>

				<div @click="onClickVisitDetail" class="agreed" v-if="visitInfo.status==1">
					<div class="agreed-title">
						来访日期
						<span class="agreed-title-date">{{ visitInfo.startDate }} 至 {{ visitInfo.endDate }}</span>
					</div>
					<div class="agreed-button-box">
						<van-icon name="arrow" color="#000000" size="1em" />
					</div>
				</div>
			</div>
		</van-sticky>

		<van-calendar v-model="showCalendarFlag" type="range" title="来访日期选择" :allow-same-day="true"
			first-day-of-week="1" max-range="7" range-prompt="来访逗留时间不能超过七天" color="#26a5f9" @confirm="onConfirm" />

		<van-popup v-model="showVisitDetailFlag">
			<div class="visit-detail">
				<div class="visit-detail-header">来访信息</div>
				<div class="visit-detail-body">
					<div class="visit-name">
						<span class="visit-name-key">来访人</span>
						<span class="visit-name-value">刘昌</span>
					</div>
					<div class="visit-date">
						<div class="visit-date-title">
							<div class="visit-date-title-name">来访时间</div>
							<div class="visit-date-title-icon-box">
								<img @click="onClickChoiseDate" src="../assets/icon/calendar.png" style="width: 24px">
							</div>
						</div>
						<div class="visit-date-content">
							{{ visitInfo.startDate }} 至 {{ visitInfo.endDate }}
						</div>
					</div>
				</div>
				<div class="visit-detail-footer">
					<div class="visit-detail-footer-button-box">
						<van-button class="visit-detail-agree" text="确定" />
						<van-button class="visit-detail-refuse" text="取消" />
					</div>
				</div>
			</div>
		</van-popup>

		<van-popup v-model="showRequestVisitFlag">
			<div class="visit-detail">
				<div class="visit-detail-header">请填写到访信息</div>
				<div class="visit-detail-body">
					<div class="visit-name">
						<span class="visit-name-key">到访人</span>
						<span class="visit-name-value">丁义诊</span>
					</div>
					<div class="visit-date">
						<div class="visit-date-title">
							<div class="visit-date-title-name">到访时间</div>
							<div class="visit-date-title-icon-box">
								<img @click="onClickChoiseDate" src="../assets/icon/calendar.png" style="width: 24px">
							</div>
						</div>
						<div class="visit-date-content">
							{{ visitInfo.startDate }} 至 {{ visitInfo.endDate }}
						</div>
					</div>
				</div>
				<div class="visit-detail-footer">
					<div class="visit-detail-footer-button-box">
						<van-button class="visit-detail-agree" text="确定" />
						<van-button class="visit-detail-refuse" text="取消" />
					</div>
				</div>
			</div>
		</van-popup>

		<div @click="showMenuFlag=false" class="chat-window-content">
			<div class="chat-window-item" v-for="message in messages" :key="message.messageId">
				<!--对方-->
				<div class="item-other" v-if=" Number(message.senderId) !==  currentUser.userId">
					<div class="other-head">
						<!-- <van-image round width="2.5em" fit="over" :src="friend.avatar" /> -->
						<van-image round width="2.5em" fit="over" src="../assets/default-touxiang.png" />	
					</div>
					<div class="other-content">
						<div class="content" v-if="message.type === 'text'" v-html="renderTextMessage(message)"></div>
						<div class="content" v-if="message.type === 'order'">
							<div class="title">
								<image src="../../../static/images/order.png"></image>
								<text>自定义消息</text>
							</div>
							<div class="custom-message-item">编号：{{message.payload.number}}</div>
							<div class="custom-message-item">商品: {{message.payload.goods}}</div>
							<div class="custom-message-item">金额: {{message.payload.price}}</div>
						</div>
						<div class="content" v-if="message.type === 'delayedPay'">
							<div class="visit">
								<div class="request" v-if="visitInfo.status==0">
									<div class="request-left">
										<div class="request-left-title">申请延迟支付房租</div>
										<!-- <div class="request-left-date">2020-09-15 至 2020-09-17</div> -->
									</div>
									<div class="request-right">
										<van-button @click="agreeDelayPay" class="request-right-button" text="同意" color="#26a5f9"
											size="small" />
										<van-button @click="refuseDelayPay" class="request-right-button" text="拒绝" color="#2769fc"
											size="small" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 我 -->
				<div class="item-me" v-if=" Number(message.senderId) ===  currentUser.userId">
					<div class="me-content">
						<div class="content" v-if="message.type === 'text'" v-html="renderTextMessage(message)"></div>
						<div class="content" v-if="message.type === 'order'">
							<div class="title">
								<image src="../../../static/images/order.png"></image>
								<text>自定义消息</text>
							</div>
							<div class="custom-message-item">编号：{{message.payload.number}}</div>
							<div class="custom-message-item">商品: {{message.payload.goods}}</div>
							<div class="custom-message-item">金额: {{message.payload.price}}</div>
						</div>
					</div>
					<div class="me-head">
						<van-image round width="2.5em" fit="over" :src="currentUser.avatar" />
					</div>
				</div>
			</div>
		</div>

		<van-tabbar>
			<van-form @submit="onSend">
				<van-field @focus="showMenuFlag=false" name="message" v-model="inputMessage">
					<template #button>
						<van-button v-if="canSendFlag" text="发送" class="sendButton" :disabled="!canSendFlag" size="mini"
							ref="buttonSubmit" type="info" native-type="submit" color="#26a5f9">
						</van-button>
					</template>
					<template #right-icon>
						<van-icon @click="showMenuFlag=true" v-if="!canSendFlag" name="add-o" size="2em" />
					</template>
				</van-field>
			</van-form>
			<div class="menu" v-if="showMenuFlag">
				<div @click="showRequestVisitFlag=true" class="menu-item">
					<div class="menu-item-icon-box">
						<img class="menu-item-icon" src="../assets/icon/visit.png" />
					</div>
					<div class="menu-item-name">申请到访</div>
				</div>
				<div class="menu-item" @click="onToAuthFamily">
					<div class="menu-item-icon-box">
						<img class="menu-item-icon" src="../assets/icon/cohabitation.png" />
					</div>
					<div class="menu-item-name">申请同居</div>
				</div>
				<div class="menu-item" @click="onToAuthFamily">
					<div class="menu-item-icon-box">
						<img class="menu-item-icon" src="../assets/icon/addFriend.png" />
					</div>
					<div class="menu-item-name">添加同居</div>
				</div>
			</div>
		</van-tabbar>

	</div>
</template>

<script>
	import Vue from 'vue';
	import EmojiDecoder from '../../libs/EmojiDecoder';
	import {
		mapState
	} from 'vuex'
	import {
		NavBar,
		Icon,
		Button,
		Tabbar,
		Sticky,
		Popup,
		Calendar
	} from 'vant';

	Vue.use(NavBar);
	Vue.use(Icon);
	Vue.use(Button);
	Vue.use(Tabbar);
	Vue.use(Sticky);
	Vue.use(Popup);
	Vue.use(Calendar);

	export default {
		name: "chat-window",
		data() {
			const emojiUrl = 'https://imgcache.qq.com/open/qcloud/tim/assets/emoji/';
			const emojiMap = {
				'[么么哒]': 'emoji_3@2x.png',
				'[乒乓]': 'emoji_4@2x.png',
				'[便便]': 'emoji_5@2x.png',
				'[信封]': 'emoji_6@2x.png',
				'[偷笑]': 'emoji_7@2x.png',
				'[傲慢]': 'emoji_8@2x.png'
			};
			return {
				canSendFlag: false,
				inputMessage: '',
				myHeadUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598437256390&di=6509c8f137e0e146b3d2896aa866ef13&imgtype=0&src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20180115%2Fbbb960a21a8c4552a6f2c853f24e7d81.jpeg',
				otherHeadUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1189741410,4055697069&fm=26&gp=0.jpg',
				showVisitDetailFlag: false,
				showCalendarFlag: false,
				showMenuFlag: false,
				showRequestVisitFlag: false,
				pickStartDate: '2020-09-15',
				pickEndDate: '2020-09-17',
				visitInfo: {
					startDate: '2020-09-15',
					endDate: '2020-09-17',
					status: 0
				},
				currentUser: null,
				//消息记录
				messages: [],
				friend: {},
				//定义表情列表
				emoji: {
					url: emojiUrl,
					map: emojiMap,
					visible: false,
					decoder: new EmojiDecoder(emojiUrl, emojiMap),
				},

				messageList: [{
					type: 'message',
					sender: 'me',
					time: '2020-09-01 10:12:55',
					content: '我在布吉看到了一个不错的一房一厅,听说在云匙App上订房有八折优惠'
				}, {
					type: 'message',
					sender: 'other',
					time: '2020-09-01 10:13:34',
					content: '早点说呀,我就不用跑那么多冤枉路了,直接在线看房订房'
				}, {
					type: 'message',
					sender: 'me',
					time: '2020-09-01 10:23:43',
					content: '周末我陪你一起去看'
				}, {
					type: 'message',
					sender: 'other',
					time: '2020-09-01 10:25:12',
					content: '云匙App真不错!'
				}]
			}
		},
		computed: {
			...mapState({
				vuex_user: state => state.vuex_user,
			})
		},
		mounted() {
			let currentUser = this.vuex_user;
			console.log(currentUser)
			if (!currentUser.userId) {
				this.$router.push('/login')
				return;
			}
			this.currentUser = currentUser;

			let friendId = this.$route.query.to
			//从服务器获取最新的好友信息
			this.$axios({
				url: "/getuserinfobycondition",
				method: "POST",
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
				},
				data: {
					userId: friendId
				}
			}).then(res => {
				console.log(res)
				//this.friend = findUserById(friendId);
				let {
					code,
					data
				} = res
				if (code === 20000) {
					this.friend = data;
					this.loadHistoryMessage();
				}
			}).catch(error => {
				console.log(error, 'error');
			})

		},
		methods: {
			//渲染文本消息，如果包含表情，替换为图片
			//todo:本不需要该方法，可以在标签里完成，但小程序有兼容性问题，被迫这样实现
			renderTextMessage(message) {
				return '<span class="text-content">' + this.emoji.decoder.decode(message.payload.text) + '</span>'
			},
			onSend(data) {
				let currentTime = new Date()
				this.messageList.push({
					type: 'message',
					sender: 'me',
					time: currentTime.getFullYear() + "-" + (currentTime.getMonth() + 1) + "-" + currentTime
						.getDate() + " " + currentTime.getHours() + ":" + currentTime.getMinutes() < 10 ? "0" +
						currentTime.getMinutes() : currentTime.getMinutes() + ":" + currentTime.getSeconds(),
					content: data.message
				});

				this.createTextMessage();

				this.$nextTick(function() {
					document.documentElement.scrollTop = this.$refs.chatWindow.scrollHeight;
				});
			},
			createTextMessage() {
				if (this.inputMessage.trim() !== '') {
					console.log(123456)
					console.log(this.inputMessage)
					let body = this.inputMessage;
					if (this.inputMessage.length >= 50) {
						body = this.inputMessage.substring(0, 30) + '...';
					}
					let textMessage = this.goEasy.im.createTextMessage({
						text: this.inputMessage,
						to: {
							id: this.friend.userId,
							type: this.GoEasy.IM_SCENE.PRIVATE,
							data: {
								name: this.friend.name,
								avatar: this.friend.avatar
							}
						},
						notification: {
							title: this.currentUser.name + '发来一段文字',
							body: body
						}
					});
					this.sendMessage(textMessage);
				}
				this.inputMessage = '';
			},
			sendMessage(message) {
				this.messages.push(message);
				this.goEasy.im.sendMessage({
					message: message,
					onSuccess: function(message) {
						console.log('发送成功.', message);
					},
					onFailed: function(error) {
						if (error.code === 507) {
							console.log(
								'发送语音/图片/视频/文件失败，没有配置OSS存储，详情参考：https://www.goeasy.io/cn/docs/goeasy-2.x/im/message/media/send-media-message.html'
							);
						} else {
							console.log('发送失败:', error);
						}
					}
				});
			},
			onConfirm(date) {
				const [start, end] = date;
				console.log(start);
				console.log(end);
				this.showCalendarFlag = false;
				this.pickStartDate = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
				this.pickEndDate = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
			},
			onChangeVisitInfo() {
				if (this.pickStartDate && this.pickEndDate) {
					this.visitInfo.startDate = this.pickStartDate;
					this.visitInfo.endDate = this.pickEndDate;
				} else {
					this.showCalendarFlag = true;
				}
			},
			onClickFallback() {
				this.$router.back();
			},
			onClickVisitAgree() {
				this.visitInfo.status = 1;
			},
			refuseDelayPay(){
				let textMessage = this.goEasy.im.createTextMessage({
					text: '',
					to: {
						id: this.friend.userId,
						type: this.GoEasy.IM_SCENE.PRIVATE,
						data: {
							name: this.friend.name,
							avatar: this.friend.avatar
						}
					},
					notification: {
						title: this.currentUser.name + '发来一段文字',
						body: body
					}
				});
				this.sendMessage(textMessage);
			},
			agreeDelayPay(){
				let textMessage = this.goEasy.im.createTextMessage({
					text: this.inputMessage,
					to: {
						id: this.friend.userId,
						type: this.GoEasy.IM_SCENE.PRIVATE,
						data: {
							name: this.friend.name,
							avatar: this.friend.avatar
						}
					},
					notification: {
						title: this.currentUser.name + '发来一段文字',
						body: body
					}
				});
				this.sendMessage(textMessage);
			},
			onClickVisitRefuse() {
				this.visitInfo.status = 2;
			},
			onClickVisitDetail() {
				this.showVisitDetailFlag = true;
			},
			onClickChoiseDate() {
				this.showCalendarFlag = true;
			},
			onToAuthFamily() {
				this.$router.push('/auth-family');
			},
			loadHistoryMessage() { //历史消息
				let lastMessageTimeStamp = null;
				let lastMessage = this.messages[0];
				if (lastMessage) {
					lastMessageTimeStamp = lastMessage.timestamp;
				}
				this.goEasy.im.history({
					userId: this.friend.userId,
					lastTimestamp: lastMessageTimeStamp,
					limit: 10,
					onSuccess: (result) => {
						console.log("result:", result);
						let messages = result.content;
						this.messages = messages.concat(this.messages);
						console.log('历史聊天信息，', this.messages)
					},
					onFailed: (error) => {
						//获取失败
						console.log('获取历史消息失败:', error);
					}
				});
			},
		},
		watch: {
			inputMessage: function(newValue) {
				newValue.length > 0 ? this.canSendFlag = true : this.canSendFlag = false;
			}
		}
	}
</script>

<style scoped>
	#chat-window {
		height: 100%;
		background-color: #efefef;
	}

	.van-nav-bar__placeholder>>>.van-nav-bar--fixed {
		background-color: #26a5f9;
		border: 0;
	}

	.navbar-title {
		color: #FFFFFF;
		font-weight: bold;
	}

	.chat-window-content {
		margin-bottom: 10%;
		background-color: #ffffff;
	}

	.item-me,
	.item-other {
		display: flex;
		margin: 7pt 3.5pt;
	}

	.me-content,
	.other-content {
		max-width: 60%;
		margin: 0 5px;
	}

	.me-content {
		margin-left: auto;
	}

	.content {
		background-color: #FFFFFF;
		border-radius: 5px;
		padding: 7px;
	}


	.van-form {
		display: flex;
		width: 100%;
		margin: auto;
		border-bottom: 1px solid #ebedf0;
	}

	.sendButton {
		/*margin: auto;*/
		/*height: 100%;*/
		/*width: 20%;*/
	}

	.visit {
		background-color: #FFFFFF;
	}

	.request {
		display: flex;
		padding: 7px;
	}


	.request-right {
		margin: auto 0;
		margin-left: auto;
		display: block;
	}

	.request-left-date {
		font-size: 12px;
		color: #969799;
	}

	.request-right-button {
		height: 2em;
		margin-left: 5px;
		margin-right: 5px;
	}

	.agreed {
		display: flex;
		padding: 7px;
	}

	.agreed-title {
		margin: auto 0;
	}

	.agreed-title-date {
		font-size: 12px;
		color: #969799;
	}

	.agreed-button-box {
		display: block;
		margin: auto 0;
		margin-left: auto;
	}

	.van-popup--center {
		border-radius: 10px;
		width: 80%;
	}

	.visit-detail {}

	.visit-detail-header,
	.visit-detail-body {
		margin: 14px;
	}

	.visit-detail-header {
		text-align: center;
	}

	.visit-name-key {
		color: #969799;
	}

	.visit-name-value {
		margin-left: 12px;
	}

	.visit-date-title {
		display: flex;
	}

	.visit-name,
	.visit-date {
		margin: 7px 0;
	}

	.visit-date-title-name {
		color: #969799;
	}

	.visit-date-title-icon-box {
		display: block;
		margin: auto 0;
		margin-left: auto;
	}

	.visit-date-content {
		text-align: center;
	}

	.visit-detail-footer-button-box {
		display: block;
		margin: auto;
		margin-bottom: 0.5px;
	}

	.visit-detail-agree {
		width: 50%;
		font-size: 18px;
		border-radius: 0;
		border-right: 1px solid #ebedf0;
		border-left: 0;
		border-bottom: 0;
		color: #26a5f9;
	}

	.visit-detail-refuse {
		width: 50%;
		font-size: 18px;
		border-radius: 0;
		border-left: 0;
		border-right: 0;
		border-bottom: 0;
	}

	.van-tabbar {
		display: block;
		height: auto;
	}

	.menu {
		display: flex;
		padding: 14px;
	}

	.menu-item {
		display: block;
		margin: 0 14px;
	}

	.menu-item-icon-box {
		background-color: #F8F8F8;
		border-radius: 10px;
		width: 3em;
		height: 3em;
	}

	.menu-item-icon {
		width: 2em;
		margin: 0.5em;
	}

	.menu-item-name {
		text-align: center;
		font-size: 12px;
		color: #969799;
		margin-top: 3px;
	}
</style>
