<!--导航页（封装Tabbar），点击tabbar不做跳转只对子页面做隐藏处理-->
<template>
	<div id="index">
		<div id="dispaly-container">
			<transition name="fade" mode="out-in">
				<home v-if="selectTabbarIndex==0" />
				<chat v-if="selectTabbarIndex==1" @setUnreadAmount="setUnreadTotal" />
				<rent v-if="selectTabbarIndex==2" />
				<my v-if="selectTabbarIndex==3" />
			</transition>
		</div>
		<van-tabbar v-if="showTabbar" :safe-area-inset-bottom="true" v-model="selectTabbarIndex">
			<van-tabbar-item icon="home-o">房态</van-tabbar-item>
			<van-tabbar-item icon="comment-o" :badge="unreadTotal">消息</van-tabbar-item>
			<van-tabbar-item icon="hot-o">租房</van-tabbar-item>
			<van-tabbar-item icon="contact">我的</van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<script>
	import Vue from 'vue';
	import Vuex from 'vuex';
	import restApi from '../../libs/restapi.js';
	import {
		mapState
	} from 'vuex'

	import {
		Tabbar,
		TabbarItem,
		NavBar,
		Sticky,
		Dialog
	} from 'vant';
	import home from '@/views/home.vue';
	import chat from '@/views/chat.vue';
	import rent from "@/views/rent";
	import my from "@/views/my";

	Vue.use(Vuex);
	Vue.use(Tabbar);
	Vue.use(TabbarItem);
	Vue.use(NavBar);
	Vue.use(Sticky);
	export default {
		name: "index",
		data() {
			return {
				//导航栏选中下标
				selectTabbarIndex: this.$store.state.homeSelectTabbarIndex,
				screenWidth: 0,
				screenHeight: 0,
				showTabbar: true,

				currentUser: null,
				unreadTotal : 0
			};
		},
		computed: {
			...mapState({
				vuex_user: state => state.vuex_user,
			})
		},
		created() {
			let currentUser = this.vuex_user;
			console.log('currentUser',currentUser)
			if (!currentUser.userId) {
				this.$router.push('/login')
				return;
			}
			this.currentUser = currentUser;
			console.log('用户数据')
			console.log(this.currentUser)
			if(this.currentUser.isRealName != 1){
				this.init()
			}
			
			if (this.goEasy.getConnectionStatus() === 'disconnected') {
				this.connectGoEasy(); //连接goeasy
				this.subscribeGroup(); //建立连接后，就应该订阅群聊消息，避免漏掉
			}
			
			this.listenConversationUpdate(); //监听会话列表变化

		},
		mounted() {
			window.vue = this;
			this.screenWidth = document.body.clientWidth;
			this.screenHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					// this.screenWidth = document.body.clientWidth;
					// this.screenHeight = document.body.clientHeight;
					if (this.screenHeight - document.body.clientHeight > this.screenHeight * 0.2) {
						this.showTabbar = false;
					} else {
						this.showTabbar = true;
					}
				})();
			};
		},
		methods: {
			init() {
				
				this.$nextTick(() => {
					Dialog.confirm({
							title: '您尚未实名认证',
							message: '未实名的个人账户通过旦巢收钱、临时到访等功能会受到限制,无法正常使用.感谢您的支持.',
						}).then(() => {
							this.$router.push('/real-name');
						})
						.catch(() => {
							// on cancel
						});
				})
			},
			connectGoEasy() {
				this.goEasy.connect({
					id: this.vuex_user.userId,
					data: {
						name: this.vuex_user.name,
						avatar: this.vuex_user.avatar
					},
					onSuccess: () => {
						console.log('GoEasy connect successfully.')
					},
					onFailed: (error) => {
						console.log('Failed to connect GoEasy, code:' + error.code + ',error:' + error
						.content);
					},
					onProgress: (attempts) => {
						console.log('GoEasy is connecting', attempts);
					}
				});
			},
			listenConversationUpdate() {
				// 监听会话列表变化
				this.goEasy.im.on(this.GoEasy.IM_EVENT.CONVERSATIONS_UPDATED, (content) => {
					this.renderConversations(content);
				});
			},
			renderConversations(content){
				this.conversations = content.conversations;
				let unreadTotal = content.unreadTotal;
				this.setUnreadTotal(unreadTotal);
			},
			subscribeGroup() {
				restApi.findGroups(this.vuex_user).then(data => {
					let groupIds = data.map(item => item.id);
					this.goEasy.im.subscribeGroup({
						groupIds: groupIds,
						onSuccess: function() {
							console.log('订阅群消息成功');
						},
						onFailed: function(error) {
							console.log('订阅群消息失败:', error);
						}
					});
				});
			},
			setUnreadTotal(val){
				this.unreadTotal = val
			}
		},
		watch: {
			selectTabbarIndex: function(newValue) {
				this.$store.commit({
					type: 'changeHomeSelectTabbarIndex',
					newValue: newValue
				});
			}
		},
		components: {
			"home": home,
			"chat": chat,
			"rent": rent,
			"my": my,
		}
	}
</script>

<style scoped>
	#index,
	#dispaly-container {
		height: 100%;
	}

	::v-deep .van-tabbar {
		box-shadow: #646566 3px 7px 5px 5px;
	}
</style>
