<template>
	<div>
		<van-dialog v-model="show" title="房租费用" :show-cancel-button="true">
			<!-- <div class="title">房租费用</div> -->
			<van-radio-group v-model="radio">
				<div class="money-box">
					<span class="money-sig">￥</span>
					<span class="money-num">1360.00</span>
				</div>
				<div class="head">
					<img src="../../src/assets/icon/logo2.png" class="img" align="middle">
					旦巢支付
				</div>

				<div class="item u-flex" @click="radio = '1'">
					<div class="u-flex u-col-center">
						<img src="../../src/assets/icon/logo2.png" class="img" align="middle">
						旦巢钱包（零钱：￥6852.05）
					</div>
					<div>
						<van-radio name="1"></van-radio>
					</div>
				</div>
				<div class="item u-flex"  @click="radio = '2'">
					<div class="u-flex u-col-center">
						<img src="../../src/assets/icon/wxpay.png" class="img" align="middle">
						微信支付
					</div>
					<div>
						<van-radio name="2"></van-radio>
					</div>
				</div>
				<div class="item u-flex"  @click="radio = '3'">
					<div class="u-flex u-col-center">
						<img src="../../src/assets/icon/zhifubaoPay.png" class="img" align="middle">
						支付宝支付
					</div>
					<div>
						<van-radio name="3"></van-radio>
					</div>
				</div>
			</van-radio-group>
		</van-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				radio: '1',
			}
		},
		methods: {

		},
	}
</script>

<style scoped lang="scss">
	.title {
		font-size: 25px;
		font-weight: bold;
	}

	.img {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		margin-right: 5px;
	}

	.money-box {
		margin-top: 10px;
		text-align: center;
		margin-bottom: 15px;

		.money-sig {
			color: red;
			font-size: 16px;
			font-weight: bold;
		}

		.money-num {
			color: red;
			font-weight: bold;
			font-size: 28px;
		}
	}

	.head {
		background-color: #FDECD8;
		color: #FF934D;
		padding: 10px 15px;
		display: flex;
		align-items: center;
	}

	.item {
		font-size: 17px;
		background-color: #ffffff;
		color: #000000;
		padding: 10px 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #eeeeee;
	}
</style>
