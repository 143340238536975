<!--消息-->
<template>
	<div id="chat">
		<van-nav-bar :fixed="true" :placeholder="true" :border="false" class="navbar">
			<template #title>
				<span class="navbar-title">消息</span>
			</template>
			<template #right>
				<van-icon @click="onClickContact" name="friends-o" size="2em" color="#ffffff" />
			</template>
		</van-nav-bar>

		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
				<van-cell v-for="(conversation, key) in conversations" :key="key" :class="{'topping':conversation.toppingFlag}">
					<div @click="navigateToChat(conversation)" class="chat-item">
						<div class="img-box">
							<van-image width="3em" fit="cover" :src="conversation.data.avatar || '../assets/logo.png'" />
						</div>
						<div class="chat-abstract">
							<div class="chat-top">
								<div class="chat-userName-official" v-if="conversation.userType==0">
									{{ conversation.data.name }}
								</div>
								<div class="chart-userName-permit" v-if="conversation.userType==1">
									{{ conversation.data.name }}
								</div>
								<div class="chat-userName" v-if="conversation.userType!=0&&conversation.userType!=1">
									{{ conversation.data.name }}
								</div>
								<div class="chat-lastMessageTime">
									{{ formatDate(conversation.lastMessage.timestamp) }}
								</div>
							</div>

							<div class="chat-lastMessage">
								<div class="item-info-top_content" v-if="!conversation.lastMessage.recalled">
									<span class="unread-text">{{conversation.lastMessage.read === false && conversation.lastMessage.senderId === currentUser.userId?'[未读]':''}}</span>
									<span v-if="conversation.type === 'private'">{{conversation.lastMessage.senderId === currentUser.userId? '我': conversation.data.name}}:</span>
									<span v-else>{{conversation.lastMessage.senderId === currentUser.userId? '我': conversation.lastMessage.senderData.name}}:</span>
									<span v-if="conversation.lastMessage.type === 'text'">{{conversation.lastMessage.payload.text}}</span>
									<span v-else-if="conversation.lastMessage.type === 'video'">[视频消息]</span>
									<span v-else-if="conversation.lastMessage.type === 'audio'">[语音消息]</span>
									<span v-else-if="conversation.lastMessage.type === 'image'">[图片消息]</span>
									<span v-else-if="conversation.lastMessage.type === 'file'">[文件消息]</span>
									<span v-else-if="conversation.lastMessage.type === 'order'">[自定义消息:订单]</span>
									<span v-else>[[未识别内容]]</span>
								</div>
							</div>
						</div>
					</div>
				</van-cell>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
	// TODO: Need 增加最后一条消息的长度限制,超出长度就以省略号代替
	import Vue from 'vue';
	import {
		mapState
	} from 'vuex'
	import {
		NavBar,
		List,
		PullRefresh,
		Cell
	} from 'vant';

	Vue.use(NavBar);
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Cell);
	export default {
		name: "chat",
		data() {
			return {
				conversations : [],
				currentUser: null,
				
				list: [{
						'userType': -1,
						'toppingFlag': true,
						'userName': '龙岗街道办',
						'userHeadUrl': 'https://img0.baidu.com/it/u=2997356521,3968363353&fm=26&fmt=auto',
						'lastMessage': '解读《深圳市住房和建设局关于切实规范住房租金》',
						'lastMessageTime': '刚刚'
					},
					{
						'userType': -1,
						'toppingFlag': true,
						'userName': '龙新派出所',
						'userHeadUrl': 'https://img2.baidu.com/it/u=2623550607,2466863248&fm=26&fmt=auto',
						'lastMessage': '龙新派出所提醒您：电动车禁止入户，请停放....',
						'lastMessageTime': '刚刚'
					},
					{
						'userType': -1,
						'toppingFlag': true,
						'userName': '龙岗区荷坳颐安乐城花园租客群',
						'userHeadUrl': 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598862166766&di=bd12850298ad9b04b3dad21f03ec9e7d&imgtype=0&src=http%3A%2F%2Fku.90sjimg.com%2Felement_origin_min_pic%2F16%2F11%2F27%2F50c25bbdc8737bae1eedef1f7ba74b86.jpg',
						'lastMessage': '心在当下:3楼楼梯口需要清扫@房东',
						'lastMessageTime': '刚刚'
					},
					{
						'userType': 1,
						'toppingFlag': true,
						'userHeadUrl': 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2092511787,1157776987&fm=26&gp=0.jpg',
						'userName': '房东',
						'lastMessage': '16日电力检修,10点之前恢复供电,还请谅解!',
						'lastMessageTime': '8-15'
					},
					{
						'userType': 0,
						'userName': '云匙团队',
						'userHeadUrl': 'https://img.yzcdn.cn/vant/logo.png',
						'lastMessage': '欢迎使用云钥App!',
						'lastMessageTime': '昨天'
					},
					{
						'userType': 0,
						'userName': '云匙智能助手',
						'userHeadUrl': 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598437730947&di=d09cf5b43fae09bfa215969f264a0b66&imgtype=0&src=http%3A%2F%2Fku.90sjimg.com%2Felement_origin_min_pic%2F17%2F09%2F25%2F82c236d84cde152b2f2cd5f40de05d5c.jpg',
						'lastMessage': '[深圳湾一号201室]已为您自动开门!',
						'lastMessageTime': '刚刚'
					},

					{
						'userType': 2,
						'userHeadUrl': 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598437256390&di=6509c8f137e0e146b3d2896aa866ef13&imgtype=0&src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20180115%2Fbbb960a21a8c4552a6f2c853f24e7d81.jpeg',
						'userName': '刘昌',
						'lastMessage': '云匙App真不错!',
						'lastMessageTime': '9:12'
					}
				],
				loading: false,
				finished: true,
				refreshing: false,
			};
		},
		created(){
			let currentUser = this.vuex_user;
			this.currentUser = currentUser;
	
			this.loadConversations(); //加载会话列表
		},
		computed: {
			...mapState({
				vuex_user: state => state.vuex_user,
			})
		},
		methods: {
			// 加载最新的会话列表
			loadConversations() {
				this.goEasy.im.latestConversations({
					onSuccess: (result) => {
						let content = result.content;
						this.renderConversations(content);
						
					},
					onFailed: (error) => {
						console.log('获取最新会话列表失败, error:', error);
					}
				});
			},
			renderConversations(content){
				this.conversations = content.conversations;
				console.log('conversations000000000000', this.conversations)
				let unreadTotal = content.unreadTotal;
				this.setUnreadAmount(unreadTotal);
			},
			setUnreadAmount (unreadTotal) {
				this.unreadTotal = unreadTotal;
				this.$emit('setUnreadAmount', unreadTotal)
			},
			onClickContact() {
				//this.$router.push('/chat-contact');
				this.$router.push('/search');
			},
			navigateToChat (conversation) {
				this.$router.push('/chat-window?to=' + conversation.userId);
			},
			onLoad() {
				// setTimeout(() => {
				//     if (this.refreshing) {
				//         this.list = [];
				//         this.refreshing = false;
				//     }
				//
				//     for (let i = 0; i < 10; i++) {
				//         this.list.push(this.list.length + 1);
				//     }
				//     this.loading = false;
				//
				//     if (this.list.length >= 40) {
				//         this.finished = true;
				//     }
				// }, 1000);
			},
			onRefresh() {
				// // 清空列表数据
				// this.finished = false;
				//
				// // 重新加载数据
				// // 将 loading 设置为 true，表示处于加载状态
				// this.loading = true;
				// this.onLoad();
			},
		},
	}
</script>

<style scoped>
	#chat {
		height: 100%;
		background-color: #F8F8F8;
	}

	.van-nav-bar__placeholder>>>.van-nav-bar--fixed {
		border: 0;
		background-color: #FFED0A;
	}

	.navbar-title {
		color: #000000;
	}

	.van-nav-bar .van-icon {
		color: #000 !important;
	}

	.chat-item {
		display: flex;
	}

	.chat-top {
		display: flex;
	}

	.chat-abstract {
		margin-left: 5px;
		margin-top: auto;
		margin-bottom: auto;
		width: 100%;
	}

	.chat-userName {
		font-size: 14px;
		font-weight: bold;
		width: 88%;
	}

	.chat-userName-official {
		font-size: 14px;
		font-weight: bold;
		color: #26a5f9;
		width: 88%;
	}


	.chart-userName-permit {
		font-size: 14px;
		font-weight: bold;
		color: #13C489;
		width: 88%;
	}

	.chat-lastMessage,
	.chat-lastMessageTime {
		font-size: 12px;
		color: #969799;
	}

	.topping {
		background-color: #EFEFEF;
	}
</style>
