import Vue from 'vue';
import Vuex from 'vuex';

//挂载Vuex
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		homeSelectTabbarIndex: 0,
		bookingSelectTabbarIndex: 0,
		walletSelectTabbarIndex: 0,
		vuex_token: '',
		vuex_user: {
			name: '明月'
		},
		vuex_version: '1.0.1'
	},
	mutations: {
		changeHomeSelectTabbarIndex(state, payload) {
			state.homeSelectTabbarIndex = payload.newValue;
		},
		changeBookingSelectTabbarIndex(state, payload) {
			state.bookingSelectTabbarIndex = payload.newValue;
		},
		changeWalletSelectTabbarIndex(state, payload) {
			state.walletSelectTabbarIndex = payload.newValue;
		},
		changeVuexToken(state,token){
			state.vuex_token = token
		},
		changeVuexUser(state,user){
			state.vuex_user = user
		}
	}
})
