<template>
	<div id="account-management-container">
		<!--导航条-->
		<van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
			<template #left>
				<van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff" />
			</template>
			<template #title>
				<span class="navbar-title">账号管理</span>
			</template>
		</van-nav-bar>

		<div class="item-box">
			<div class="item">
				<div class="left">
					<div class="text">旦巢账号</div>
				</div>
				<div class="right">
					<van-icon name="arrow" color="#646566" />
				</div>
			</div>
			<van-divider />
			<div class="item" @click="$router.push({name:'verifi-phone'})">
				<div class="left">
					<div class="text">修改手机号</div>
				</div>
				<div class="right">
					<van-icon name="arrow" color="#646566" />
				</div>
			</div>
			<van-divider />
			<div class="item">
				<div class="left">
					<div class="text">二维码</div>
				</div>
				<div class="right">
					<van-icon name="arrow" color="#646566" />
				</div>
			</div>
			<van-divider />
			<div class="item">
				<div class="left">
					<div class="text">修改登录密码</div>
				</div>
				<div class="right">
					<van-icon name="arrow" color="#646566" />
				</div>
			</div>
			<van-divider />
			<div class="item">
				<div class="left">
					<div class="text">登录设备管理</div>
				</div>
				<div class="right">
					<van-icon name="arrow" color="#646566" />
				</div>
			</div>
			<van-divider />
			<div class="item">
				<div class="left">
					<div class="text">旦巢安全中心</div>
				</div>
				<div class="right">
					<van-icon name="arrow" color="#646566" />
				</div>
			</div>
			<van-divider />
		</div>
	</div>
</template>

<script>
	export default {
		name: "account-management",
		methods: {
			onClickFallback() {
				this.$router.back();
			},
			onToLogin() {
				this.$router.replace('/');
			}
		}
	}
</script>

<style scoped>
	#account-management-container {
		min-height: 100%;
		background-color: #F8F8F8;
	}

	.van-nav-bar__placeholder>>>.van-nav-bar--fixed {
		background-color: #26a5f9;
		border: 0;
	}

	.van-index-anchor {
		background-color: #efefef;
	}

	.navbar-title {
		color: #FFFFFF;
		font-weight: bold;
	}

	.item-box>.item {
		display: flex;
		height: 3em;
		padding: 0 4%;
		background-color: #FFFFFF;
	}

	.item-box>.item>.left {
		margin: auto 0;
		margin-right: auto;
	}

	.item-box>.item>.right {
		margin: auto 0;
		margin-left: auto;
	}

	.item-box>.item>.right>>>.van-icon {
		display: block;
		margin: auto;
	}

	::v-deep .van-divider {
		margin: 0;
	}
</style>
