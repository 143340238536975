<template>
	<div class="container">
		<!--导航条-->
		<van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
			<template #left>
				<van-icon name="arrow-left" @click="onClickFallback" size="2em" color="#ffffff" />
			</template>
			<template #title>
				<span class="navbar-title">今日入住</span>
			</template>
		</van-nav-bar>
		<div>
			<div class="u-flex u-col-center item">
				<img  src="../../assets/logo3.png" width="60px" height="60px" />
				<div class="u-flex  u-flex-col">
					<div class="u-flex u-row-between">
						<div class="u-flex u-flex-col">
							<div class="u-p-5">用户昵称</div>
							<div class="gray-color">Mr huang</div>
						</div>
						<div class="u-flex u-flex-col">
							<div class="u-p-5">旦巢号</div>
							<div class="gray-color">12335</div>
						</div>
						<div class="u-flex u-flex-col">
							<div class="u-p-5">手机号</div>
							<div class="gray-color">13631458340</div>
						</div>
						<div class="u-flex u-flex-col">
							<div class="u-p-5">居住人数</div>
							<div class="gray-color">2人</div>
						</div>
					</div>
					<div class="address gray-color u-m-t-8 u-p-t-5">
						入住房间：广州市天河北镇大街8号
					</div>
				</div>
			</div>
			<div class="u-flex u-col-center item">
				<img  src="../../assets/logo3.png" width="60px" height="60px" />
				<div class="u-flex  u-flex-col">
					<div class="u-flex u-row-between">
						<div class="u-flex u-flex-col">
							<div class="u-p-5">用户昵称</div>
							<div class="gray-color">Mr huang</div>
						</div>
						<div class="u-flex u-flex-col">
							<div class="u-p-5">旦巢号</div>
							<div class="gray-color">12335</div>
						</div>
						<div class="u-flex u-flex-col">
							<div class="u-p-5">手机号</div>
							<div class="gray-color">13631458340</div>
						</div>
						<div class="u-flex u-flex-col">
							<div class="u-p-5">居住人数</div>
							<div class="gray-color">2人</div>
						</div>
					</div>
					<div class="address gray-color u-m-t-8 u-p-t-5">
						入住房间：广州市天河北镇大街8号
					</div>
				</div>
			</div>
			<div class="u-flex u-col-center item">
				<img  src="../../assets/logo3.png" width="60px" height="60px" />
				<div class="u-flex  u-flex-col">
					<div class="u-flex u-row-between">
						<div class="u-flex u-flex-col">
							<div class="u-p-5">用户昵称</div>
							<div class="gray-color">Mr huang</div>
						</div>
						<div class="u-flex u-flex-col">
							<div class="u-p-5">旦巢号</div>
							<div class="gray-color">12335</div>
						</div>
						<div class="u-flex u-flex-col">
							<div class="u-p-5">手机号</div>
							<div class="gray-color">13631458340</div>
						</div>
						<div class="u-flex u-flex-col">
							<div class="u-p-5">居住人数</div>
							<div class="gray-color">2人</div>
						</div>
					</div>
					<div class="address gray-color u-m-t-8 u-p-t-5">
						入住房间：广州市天河北镇大街8号
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				background: {
					backgroundColor: '#FBDF3E',
				},
				form: {
			
				},
				code: '',
				codeText: '',
				saveLoading: false,
			}
		},
		methods:{
			onClickFallback() {
			  this.$router.back();
			},
		}
	}
</script>

<style scoped lang="scss">
	.container{
		width: 100%;
		height: 100%;
		background-color: #EBEBEB;
		position: absolute;
		.item{
			background-color: #ffffff;
			padding-top: 10px;
			padding-bottom: 10px;
			margin-top: 6px;
			border-radius: 8px;
			.address{
				width: 100%;
				font-size: 14px;
				border-top: 1px solid #bebebe;
			}
			.gray-color{
				color: #969696;
			}
		}
		
	}
</style>