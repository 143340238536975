<template>
  <div id="auth-family-container">
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">家庭认证</span>
      </template>
      <template #right>
        <van-icon @click="onClickMore" name="ellipsis" size="2em" color="#ffffff"></van-icon>
      </template>
    </van-nav-bar>
    <div class="item-box">
      <div class="item">
        <div class="head-box">
          <img src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2291332875,175289127&fm=26&gp=0.jpg"/>
        </div>
        <div class="center-box">
          <div class="top">
            <div class="name">刘***</div>
            <div class="auth-img-box">
              <img src="../assets/icon/auth.png">
            </div>
            <div class="role-img-box">
              <img src="../assets/icon/man.png">
            </div>
            <div class="role-text">弟弟</div>
          </div>
          <div class="center">智能钥匙:&nbsp;2020PHJ356X</div>
          <div class="bottom">身份证号:&nbsp;42090*********0815</div>
        </div>
        <div class="right-box">
          <div class="add-button">
            <div class="img-box">
              <img src="../assets/icon/house_heart.png"/>
            </div>
            <div class="text">添加同居</div>
          </div>
          <div class="bind-button">
            <div class="img-box">
              <img src="../assets/icon/bind.png"/>
            </div>
            <div class="text">绑定钥匙</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottombar" @click="onToAddFamily">
      <div class="img-box">
        <img src="../assets/icon/plus_circle.png"/>
      </div>
      <div class="text">
        添加家人
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "auth-family",
  methods: {
    onClickFallback() {
      this.$router.back();
    },
    onClickMore() {
      //Nothing to do.
    },
    onToAddFamily(){
      this.$router.push('/add-family');
    }
  }
}
</script>

<style scoped>
#auth-family-container {
  min-height: 100%;
  background-color: #F8F8F8;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.van-index-anchor {
  background-color: #efefef;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}

.bottombar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: #FFFFFF;
  height: 3em;
  box-shadow: #646566 3px 7px 5px 5px;
}

.bottombar > .img-box {
  height: 1.5em;
  margin: auto 0;
  margin-left: auto;
}

.bottombar > .img-box > img {
  height: 100%;
}

.bottombar > .text {
  margin: auto 0;
  margin-right: auto;
  margin-left: 7px;
  font-size: 18px;
}

.item-box > .item {
  padding: 2%;
  margin: 2%;
  background-color: #FFFFFF;
  border-radius: 5px;
  display: flex;
  height: 4em;
}

.item-box > .item > .head-box {
  height: 100%;
}

.item-box > .item > .head-box > img {
  height: 100%;
  border-radius: 5px;
}

.item-box > .item > .center-box {
  color: #646566;
  font-size: 12px;
  height: 100%;
  margin: auto;
  padding:0 1%;
}

.item-box > .item > .center-box > * {
  display: block;
}

.item-box > .item > .center-box > .top {
  display: flex;
  font-size: 16px;
}

.item-box > .item > .center-box > .center{
  margin:3px 0;
}

.item-box > .item > .center-box > .bottom{
}

.item-box > .item > .center-box > .top > .auth-img-box, .item-box > .item > .center-box > .top > .role-img-box {
  margin: auto 3px;
  display: block;
  width: 1em;
}

.item-box > .item > .center-box > .top > .auth-img-box img, .item-box > .item > .center-box > .top > .role-img-box img {
  display: block;
  width: 100%;
}

.item-box > .item > .center-box > .top > .role-text{
  font-size: 12px;
  margin:auto 0;
  color: #eaa63f;
}

.item-box > .item > .right-box {
  margin-left: auto;
}

.item-box > .item > .right-box > *{
  height: 50%;
}

.item-box > .item > .right-box > .add-button {
  display: flex;
}

.item-box > .item > .right-box > .add-button > .img-box {
  margin: auto 0;
  display: block;
  width: 1em;
}

.item-box > .item > .right-box > .add-button > .img-box > img {
  display: block;
  width: 100%;
}

.item-box > .item > .right-box > .add-button > .text {
  margin: auto 0;
  color: #26a5f9;
}

.item-box > .item > .right-box > .bind-button {
  display: flex;
}

.item-box > .item > .right-box > .bind-button > .img-box {
  margin: auto 0;
  display: block;
  width: 1em;
}

.item-box > .item > .right-box > .bind-button > .img-box > img {
  display: block;
  width: 100%;
}

.item-box > .item > .right-box > .bind-button > .text {
  margin: auto 0;
  color: #eaa63f;
}

</style>
