<template>
  <div id="register-container">
    <div class="navbar">
      <div class="left">
        <van-icon name="arrow-left" @click="onToBack"/>
      </div>
      <div class="right">
        <div class="text">已有账户?</div>
        <div class="button" @click="onToLogin">登录</div>
      </div>
    </div>
    <div class="title">
      <div class="hello">您好,</div>
      <div class="welcome">欢迎来到旦巢智家生活</div>
    </div>
    <van-form class="form">
      <div class="row">
        <div class="left-icon">
          <img src="../assets/icon/iphone.png"/>
        </div>
        <div class="option" @click="showCountryPopup = true">
          <div class="text">+{{ countryNumber }}</div>
          <div class="button">
            <van-icon name="arrow-down" color="#636465"/>
          </div>
        </div>
        <van-field placeholder="请输入手机号" type="number" v-model="phoneNumber"
                   :rules="[{ required: true, message: '请填写手机号' }]"/>
      </div>
      <van-divider/>
      <div class="row">
        <div class="left-icon">
         <img src="../assets/icon/auth.png"/>
        </div>
        <van-field placeholder="请输入验证码" type="number" v-model="authNumber"
                   :rules="[{ required: true, message: '请填写验证码' }]">
          <template #right-icon>
            <van-button style="margin: auto" text="获取验证码" color="#F18516" size="mini"  @click.native="sendCode" />
          </template>
        </van-field>
      </div>
      <van-divider/>
      <div class="row">
        <div class="left-icon">
          <img src="../assets/icon/lock.png"/>
        </div>
        <van-field placeholder="设置密码(8-16字母数字符号组合)" :type="showFirstPassword?'text':'password'"
                   v-model="firstPassword" :rules="[{ required: true, message: '请填写密码' }]"/>

      </div>
      <van-divider/>
      <div class="row">
        <div class="left-icon">
          <img src="../assets/icon/lock.png"/>
        </div>
        <van-field placeholder="再次确认密码" :type="showSecondPassword?'text':'password'" v-model="secondPassword"
                   :rules="[{ required: true, message: '请再次填写密码' },{validator:isSamePassword,message: '两次输入密码不相同,请重新输入'}]"/>
      </div>
      <van-divider/>
      <div class="clause-box">
        <van-checkbox icon-size="12px" class="check-box" v-model="isReadClause">您已阅读注册条款</van-checkbox>
        <div class="cant-receve-code">收不到验证码?</div>
      </div>
      <div class="submit-box">
        <van-button text="确定注册" color="#FBDF3E" :disabled="canSubmit"  @click="onSubmit"/>
      </div>
    </van-form>
    <van-popup v-model="showCountryPopup" position="bottom">
      <van-picker show-toolbar :columns="countryItems" title="请选择手机号码归属地" @confirm="onSelectContry"
                  @cancel="showCountryPopup=false"></van-picker>
    </van-popup>
  </div>
</template>

<script>
	import qs from 'qs'
export default {
  name: "register",
  data() {
    return {
      phoneNumber: '',
      countryNumber: 86,
      showCountryPopup: false,
      countryItems: ['中国大陆', '中国香港特别行政区', '中国澳门特别行政区', '中国台湾地区'],
      authNumber: '',
      showFirstPassword: false,
      showSecondPassword: false,
      firstPassword: '',
      secondPassword: '',
      isReadClause: false,
      canSubmit: true
    }
  },
  methods: {
    onToBack(){
      this.$router.back();
    },
    onToLogin() {
      this.$router.replace('/');
    },
    onSelectContry(value) {
      this.showCountryPopup = false;
      switch (value) {
        case '中国大陆' :
          this.countryNumber = 86;
          break;
        case  '中国香港特别行政区' :
          this.countryNumber = 852;
          break;
        case  '中国澳门特别行政区' :
          this.countryNumber = 853;
          break;
        case  '中国台湾地区' :
          this.countryNumber = 886;
          break;
      }
    },
    isSamePassword(value) {
      if (value != this.firstPassword) {
        return false;
      } else {
        return true;
      }
    },
	sendCode(){
		if(!this.phoneNumber){
			this.$toast('请输入手机号码');
			return false
		}
		const params =qs.stringify( {
			phone:this.phoneNumber
		})
		this.$axios({
			url: "sendCode",
			method: "POST",
			data: params
		}).then(res => {
			console.log(res)
			let { message } = res
			this.$toast(message);
		}).catch(error => {
			console.log(error, 'error');
		})
		
	},
    onSubmit(){
		let params = {
			phone:this.phoneNumber,
			username:this.phoneNumber,
			password:this.firstPassword,
			vcode:this.authNumber
		}
		this.$axios.post('tenant/register',params,{
			withCredentials:true
		}).then(res=>{
			let {code , message } = res;
			if(code === 20000){
				this.$toast(message);
				this.$router.replace('/index');
			}else{
				this.$toast(message);
			}
		}).catch(Error=>{
			console.log(Error);
		})
    }
  },
  watch: {
    phoneNumber: function () {
      if (this.phoneNumber.length != 0) {
        this.authNumber = '';
      }

      if (this.phoneNumber.length > 0 && this.authNumber.length > 0 && this.firstPassword.length > 0 && this.secondPassword.length > 0 && this.firstPassword == this.secondPassword && this.isReadClause) {
        this.canSubmit = false;
      } else {
        this.canSubmit = true;
      }
    },
    authNumber: function () {
      if (this.phoneNumber.length > 0 && this.authNumber.length > 0 && this.firstPassword.length > 0 && this.secondPassword.length > 0 && this.firstPassword == this.secondPassword && this.isReadClause) {
        this.canSubmit = false;
      } else {
        this.canSubmit = true;
      }
    },
    firstPassword: function () {
      if (this.phoneNumber.length > 0 && this.authNumber.length > 0 && this.firstPassword.length > 0 && this.secondPassword.length > 0 && this.firstPassword == this.secondPassword && this.isReadClause) {
        this.canSubmit = false;
      } else {
        this.canSubmit = true;
      }
    },
    secondPassword: function () {
      if (this.phoneNumber.length > 0 && this.authNumber.length > 0 && this.firstPassword.length > 0 && this.secondPassword.length > 0 && this.firstPassword == this.secondPassword && this.isReadClause) {
        this.canSubmit = false;
      } else {
        this.canSubmit = true;
      }
    },
    isReadClause: function () {
      if (this.phoneNumber.length > 0 && this.authNumber.length > 0 && this.firstPassword.length > 0 && this.secondPassword.length > 0 && this.firstPassword == this.secondPassword && this.isReadClause) {
        this.canSubmit = false;
      } else {
        this.canSubmit = true;
      }
    }
  }
}
</script>

<style scoped>
#register-container {
  min-height: 100%;
  background-color: #FFFFFF;
}

.navbar {
  display: flex;
  margin: 2%;
  padding: 2%;
}

.navbar .left {
  margin: auto 0;
  margin-right: auto;
}

.navbar .right {
  margin: auto 0;
  margin-left: auto;
  text-align: right;
  font-size: 14px;
  color: #646566;
  display: flex;
}

.navbar .right .button {
  margin-left: 5px;
  color: rgb(241, 133, 22);
}

.title {
  margin: 8% 4%;
  padding: 2%;
}

.title .hello {
  font-size: 26px;
  font-weight: bold;
}

.form {
  margin: 2%;
  padding: 4%;
}

.form .row {
  display: flex;
}

.form .row .left-icon {
  width: 1.5em;
  margin: auto;
}

.form .row .left-icon img {
  width: 100%;
  display: block;
  margin: auto;
}

.form .row .option {
  display: flex;
  margin: auto;
}

.form .row .option .text {
  color: #323233;
}

.form .row .option .button {
  margin: auto;
  display: block;
}

.form .row .option .button > * {
  margin: auto;
  display: block;
}

::v-deep .van-divider {
  margin: 0;
}

.clause-box {
  display: flex;
  margin: 2%;
  padding: 2%;
  font-size: 12px;
  color: #646566;
}

.clause-box .check-box {
  margin-right: auto;
}

.clause-box .cant-receve-code {
  margin-left: auto;
  text-align: right;
}

.submit-box {
  margin: 2%;
  padding: 2%;
}

.submit-box >>> .van-button {
  width: 100%;
}
</style>
