<template>
	<div id="payment-management-container">
		<van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
			<template #left>
				<van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff" />
			</template>
			<template #title>
				<span class="navbar-title">支付管理</span>
			</template>
		</van-nav-bar>

		<div class="item-box">
			<div class="item" @click="onToModifyPayMentPassword(1)" v-if="vuex_user.isSetPaypassword != 1">
				<div class="left">
					<div class="text">设置支付密码</div>
				</div>
				<div class="right">
					<van-icon name="arrow" color="#646566" />
				</div>
			</div>
			<div class="item" @click="onToModifyPayMentPassword(2)">
				<div class="left">
					<div class="text">修改支付密码</div>
				</div>
				<div class="right">
					<van-icon name="arrow" color="#646566" />
				</div>
			</div>
			<van-divider />
			<!-- <div class="item">
				<div class="left">
					<div class="text">忘记支付密码</div>
				</div>
				<div class="right">
					<van-icon name="arrow" color="#646566" />
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: "payment-management",
		created() {
		},
		mounted() {
			let currentUser = this.vuex_user;
			console.log('currentUser',currentUser)
			if (!currentUser.userId) {
				this.$router.push('/login')
				return;
			}
		},
		computed: {
			...mapState({
				vuex_user: state => state.vuex_user,
			})
		},
		methods: {
			onClickFallback() {
				this.$router.back();
			},
			onToModifyPayMentPassword(type){
				if(type === 1){
					this.$router.push({path:'/modify-payment-password',query:{type:type}});
				}else{
					this.$router.push({path:'/verifi-phone',query:{type:type,title:'修改支付密码'}});
				}
			}
		}
	}
</script>

<style scoped>
	#payment-management-container {
		min-height: 100%;
		background-color: #F8F8F8;
	}

	.van-nav-bar__placeholder>>>.van-nav-bar--fixed {
		background-color: #26a5f9;
		border: 0;
	}

	.van-index-anchor {
		background-color: #efefef;
	}

	.navbar-title {
		color: #FFFFFF;
		font-weight: bold;
	}

	.item-box>.item {
		display: flex;
		height: 3em;
		padding: 0 4%;
		background-color: #FFFFFF;
	}

	.item-box>.item>.left {
		margin: auto 0;
		margin-right: auto;
	}

	.item-box>.item>.right {
		margin: auto 0;
		margin-left: auto;
	}

	.item-box>.item>.right>>>.van-icon {
		display: block;
		margin: auto;
	}

	::v-deep .van-divider {
		margin: 0;
	}
</style>
