<template>
  <div id="add-family-container">
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">添加家人</span>
      </template>
      <template #right>
        <van-icon @click="onClickMore" name="ellipsis" size="2em" color="#ffffff"></van-icon>
      </template>
    </van-nav-bar>
    <div class="step-box">
      <van-steps :active="step" active-color="#26a5f9">
        <van-step>基本信息</van-step>
        <van-step>人脸采集</van-step>
      </van-steps>
    </div>
    <div class="item-box" v-show="step==0">
      <div class="item">
        <div class="text">姓名:</div>
        <div class="option">
          <van-field placeholder="请输入姓名" v-model="name" input-align="right"/>
        </div>
      </div>
      <van-divider/>
      <div class="item">
        <div class="text">关系:</div>
        <div class="option option-text" v-if="relationship!=''" @click="showRelationshipPicker=true">
          {{ relationship }}
        </div>
        <div class="button-box" v-if="relationship==''">
          <van-button @click="showRelationshipPicker=true" size="small" text="选择关系" color="#26a5f9"/>
        </div>
        <van-popup v-model="showRelationshipPicker" position="bottom">
          <van-picker
              title="选择民族"
              show-toolbar
              :columns="relationshipItems"
              @confirm="onSelectRelationship"
              @cancel="showRelationshipPicker=false"
          />
        </van-popup>
      </div>
      <van-divider/>
      <div class="item">
        <div class="text">出生日期:</div>
        <div class="option option-text"
             v-if="!(this.birthday.getFullYear() == this.maxDate.getFullYear() && this.birthday.getMonth() == this.maxDate.getMonth() && this.birthday.getDate() == this.maxDate.getDate())"
             @click="showBirthdayPicker=true">
          {{ birthday.getFullYear() }}年{{ birthday.getMonth() + 1 }}月{{ birthday.getDate() }}日
        </div>
        <div class="button-box"
             v-if="(this.birthday.getFullYear() == this.maxDate.getFullYear() && this.birthday.getMonth() == this.maxDate.getMonth() && this.birthday.getDate() == this.maxDate.getDate())">
          <van-button @click="showBirthdayPicker=true" size="small" text="选择出生日期" color="#26a5f9"/>
        </div>
        <van-popup v-model="showBirthdayPicker" position="bottom">
          <van-datetime-picker v-model="birthday" :max-date="maxDate" :min-date="minDate" type="date" title="请选择出生日期"
                               @confirm="showBirthdayPicker=false" @cancel="showBirthdayPicker=false"/>
        </van-popup>
      </div>
      <van-divider/>
      <div class="item">
        <div class="text">手机号码:</div>
        <div class="option">
          <van-field placeholder="请输入手机号码" v-model="telephone" input-align="right"/>
        </div>
      </div>
      <van-divider/>
      <div class="item">
        <div class="text">民族:</div>
        <div class="option option-text" v-if="nation!=''" @click="showNationPicker=true">
          {{ nation }}
        </div>
        <div class="button-box" v-if="nation==''">
          <van-button @click="showNationPicker=true" size="small" text="选择民族" color="#26a5f9"/>
        </div>
        <van-popup v-model="showNationPicker" position="bottom">
          <van-picker
              title="选择民族"
              show-toolbar
              :columns="nationItems"
              @confirm="onSelectNation"
              @cancel="showNationPicker=false"
          />
        </van-popup>
      </div>
      <van-divider/>
      <div class="item">
        <div class="text">证件类型:</div>
        <div class="option option-text" v-if="idType!=''" @click="showIdTypePicker=true">
          {{ idType }}
        </div>
        <div class="button-box" v-if="idType==''">
          <van-button @click="showIdTypePicker=true" size="small" text="选择证件类型" color="#26a5f9"/>
        </div>
        <van-popup v-model="showIdTypePicker" position="bottom">
          <van-picker
              title="选择证件类型"
              show-toolbar
              :columns="idTypeItems"
              @confirm="onSelectIdType"
              @cancel="showIdTypePicker=false"
          />
        </van-popup>
      </div>
      <van-divider/>
      <div class="item" v-if="idType=='户口本'">
        <div class="text">上传户主页面</div>
        <div class="option">
          <van-uploader preview-image>
            <van-button icon="plus" type="primary" color="#26a5f9" size="small"/>
          </van-uploader>
        </div>
      </div>
      <van-divider v-if="idType=='户口本'"/>
      <div class="item" v-if="idType=='户口本'">
        <div class="text">上传本人页面</div>
        <div class="option">
          <van-uploader>
            <van-button icon="plus" type="primary" color="#26a5f9" size="small"/>
          </van-uploader>
        </div>
      </div>
      <van-divider v-if="idType=='户口本'"/>
      <div class="item" v-if="idType=='身份证'||idType=='居住证'">
        <div class="text">上传{{ idType }}正面</div>
        <div class="option">
          <van-uploader>
            <van-button icon="plus" type="primary" color="#26a5f9" size="small"/>
          </van-uploader>
        </div>
      </div>
      <van-divider v-if="idType=='身份证'||idType=='居住证'"/>
      <div class="item" v-if="idType=='身份证'||idType=='居住证'">
        <div class="text">上传{{ idType }}反面</div>
        <div class="option">
          <van-uploader>
            <van-button icon="plus" type="primary" color="#26a5f9" size="small"/>
          </van-uploader>
        </div>
      </div>
      <van-divider v-if="idType=='身份证'||idType=='居住证'"/>
      <div class="item">
        <div class="text">证件号码:</div>
        <div class="option">
          <van-field placeholder="请输入证件号码" v-model="idNo" input-align="right"/>
        </div>
      </div>
      <van-divider/>
      <div class="item" style="height: auto;min-height: 3em;">
        <div class="text">户籍地址:</div>
        <div class="option">
          <van-field
              v-model="permanentAddress"
              rows="1"
              autosize
              type="textarea"
              placeholder="请输入户籍地址"
              input-align="right"
          />
        </div>
      </div>
      <van-divider/>
      <div class="item">
        <div class="text">拍摄本人照片</div>
        <div class="option">
          <van-uploader>
            <van-button icon="plus" type="primary" color="#26a5f9" size="small"/>
          </van-uploader>
        </div>
      </div>
      <van-divider/>
      <div class="item">
        <div class="text">添加智能钥匙</div>
        <div class="option">
          <van-field placeholder="请输入钥匙编码" v-model="keyId" input-align="right"/>
        </div>
      </div>
      <van-divider/>
      <div class="button-box">
        <van-button text="下一步" size="small" color="#26a5f9"/>
      </div>
      <div class="contactUsService">
        联系客服
      </div>
    </div>

    <div class="head-box" v-show="step==1">

    </div>
  </div>
</template>

<script>
import {Step, Steps} from 'vant';
import Vue from 'vue';

Vue.use(Step);
Vue.use(Steps);
export default {
  name: "add-family",
  data() {
    return {
      name: '',
      birthday: new Date(),
      maxDate: new Date(),
      minDate: new Date(1920, 1, 1,),
      showBirthdayPicker: false,
      telephone: '',
      relationship: '',
      relationshipItems: ['配偶', '子女', '父母', '亲戚', '朋友'],
      showRelationshipPicker: false,
      nation: '',
      nationItems: ['汉族', '壮族', '满族', '回族', '苗族', '维吾尔族', '土家族', '彝族', '蒙古族', '藏族', '布依族', '侗族', '瑶族', '朝鲜族', '白族', '哈尼族', '哈萨克族', '黎族', '傣族', '畲族', '僳僳族', '仡佬族', '东乡族', '拉祜族', '水族', '佤族', '纳西族', '羌族', '土族', '仫佬族', '锡伯族', '柯尔克孜族', '达翰尔族', '景颇族', '毛南族', '撒拉族', '布朗族', '塔吉克族', '阿昌族', '普米族', '鄂温克族', '怒族', '京族', '基诺族', '德昂族', '保安族', '俄罗斯族', '裕固族', '乌孜别克族', '门巴族', '鄂伦春族', '独龙族', '塔塔尔族', '赫哲族', '高山族', '珞巴族', '其他'],
      showNationPicker: false,
      idType: '',
      idTypeItems: ['身份证', '居住证', '户口本'],
      showIdTypePicker: false,
      idNo: '',
      permanentAddress: '',
      keyId: '',
      step: 0,

      //face
      nets: "tinyFaceDetector", // 模型
      options: null, // 模型参数
      withBoxes: false, // 框or轮廓
      detectFace: "detectSingleFace", // 单or多人脸
      detection: "landmark",
      videoEl: null,
      canvasEl: null,
      timeout: 0,
      // 视频媒体参数配置
      constraints: {
        audio: false,
        video: {
          // ideal（应用最理想的）
          width: {
            min: 320,
            ideal: 375,
            max: 414,
          },
          height: {
            min: 568,
            ideal: 822,
            max: 900,
          },
          // frameRate受限带宽传输时，低帧率可能更适宜
          frameRate: {
            // min: 50,
            ideal: 60,
            // max: 60,
          },
          // 显示模式前置后置
          facingMode: "environment",
        },
      }
    }
  },
  methods: {
    onClickFallback() {
      this.$router.back();
    },
    onClickMore() {

    },
    onSelectRelationship(value) {
      this.relationship = value;
      this.showRelationshipPicker = false;
    },
    onSelectNation(value) {
      this.nation = value;
      this.showNationPicker = false;
    },
    onSelectIdType(value) {
      this.idType = value;
      this.showIdTypePicker = false;
    },
  },
  watch: {},
  mounted() {

  }
}
</script>

<style scoped>
#add-family-container {
  min-height: 100%;
  background-color: #FFFFFF;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.van-index-anchor {
  background-color: #efefef;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}

::v-deep .van-nav-bar {
  z-index: 2;
}

.step-box >>> .van-steps {
  width: 50%;
  margin: auto;
  font-size: 18px;
}

.step-box >>> .van-step__title, .step-box >>> .van-step__icon {
  font-size: 14px;
}


.item-box {
  padding: 2%;
}

.item-box > .item {
  display: flex;
  min-height: 3em;
}

.item-box > .item > .text {
  margin: auto 0;
}

.item-box > .item > .option {
  margin: auto 0;
  margin-left: auto;
}

.item-box > .item > .button-box {
  margin: auto 0;
  margin-left: auto;
  margin-right: 9px;
}

.item-box > .item > .option-text {
  margin-right: 11px;
  font-size: 14px;
  color: #323233
}

.item-box > .button-box {
  min-height: 3em;
  display: block;
}

.item-box > .button-box >>> .van-button {
  display: block;
  margin-left: auto;
  margin-top: 1em;
  width: 7em;
}

::v-deep .van-divider {
  margin: 0;
}

.head-box {
  position: relative;
  height: 100%;
}

.head-box video {
  height: 100%;
  width: 100%;
}

.head-box canvas {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.contactUsService {
  color: #646566;
  font-size: 12px;
  margin: auto 0;
  margin-top: 2em;
  text-align: center;
}
</style>
