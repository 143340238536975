<template>
	<div id="rentDetail-container">

		<!--导航条-->
		<van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
			<template #left>
				<van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff" />
			</template>
			<template #title>
				<span class="navbar-title">大运 星河时代 次卧 朝南</span>
			</template>
			<template #right>
				<van-icon @click="onClickMore" name="ellipsis" size="2em" color="#ffffff"></van-icon>
			</template>
		</van-nav-bar>

		<div id="rentDetail-body">
			<!--轮播图-->
			<van-swipe :autoplay="3000">
				<van-swipe-item v-for="(i, index) in swipeImageItems" :key="index">
					<img v-lazy="i" />
				</van-swipe-item>
			</van-swipe>

			<div class="type-info">
				<div class="text">整租 · 一房一厅</div>
				<div class="button-box" @click="toViewVR">
					<div class="text">
						VR看房
					</div>
					<img class="button" src="../assets/icon/v000.png">
				</div>
			</div>

			<!--重要信息-->
			<div class="important-info">
				<div class="item">
					<div class="title">使用面积</div>
					<div class="text">35.2m²</div>
				</div>
				<div class="item">
					<div class="title">朝向</div>
					<div class="text">南</div>
				</div>
				<div class="item">
					<div class="title">入住</div>
					<div class="text">随时入住</div>
				</div>
				<div class="item">
					<div class="title">楼层</div>
					<div class="text">7/11</div>
				</div>
			</div>

			<!--标签 大-->
			<div class="tag-box">
				<div class="item" v-for="i in tagItems" :key="i">{{ i }}</div>
			</div>

			<!--次要信息 卡片-->
			<div class="secondary-info">
				<div class="left-box">
					<div class="item">
						<div class="title">押金</div>
						<div class="text">2×月租金</div>
					</div>
					<div class="item">
						<div class="title">电梯</div>
						<div class="text">有</div>
					</div>
					<div class="item">
						<div class="title">门锁</div>
						<div class="text">云匙2020Pro版</div>
					</div>
					<div class="item">
						<div class="title">租期</div>
						<div class="text">1-6个月 | 1年</div>
					</div>
				</div>
				<div class="right-box">
					<div class="item">
						<div class="title">电价</div>
						<div class="text">1.5元/度</div>
					</div>
					<div class="item">
						<div class="title">水价</div>
						<div class="text">8元/m²</div>
					</div>
					<div class="item">
						<div class="title">其他费用</div>
						<div class="text">无</div>
					</div>
				</div>
			</div>

			<!--房屋配套-->
			<div class="equipment-info">
				<div class="title">房屋配套</div>
				<div class="item-box">
					<div class="item" v-for="i in equipmentItems" :key="i.text">
						<div class="icon-box">
							<img :src="i.url" />
						</div>
						<div class="text">
							{{ i.text }}
						</div>
					</div>
				</div>
				<van-divider />
				<div class="remark">
					【交通出行】
					<br />
					公交途径5个站点。小区东门步行约200米就是水蓝湾公交总站，可直达大运，吉祥地铁站。1000米内有三个公交站点，地铁16号线在建中。连接水官高速、武深高速
					清平高速、南坪快速、丹坪快速相连，公交地铁也可以通往深圳各个区域，快速方便。
					<br />
					【周边配套】
					<br />
					小区门门口有华润万家超市，钱大妈，瑜伽馆，各种教育兴趣班，早餐店，满足生活需要。人人乐购物广场、华润万家、天虹购物商场、万科广场、星河COCOPARK公园有龙城公园、龙潭公园、龙园公园，龙园公园。地铁16号线、仁恒商圈综合体、龙岗儿童乐园
					<br />
					【小区介绍】
					<br />
					小区位于龙岗区龙平西路与黄阁北路交汇处，是2007年建成的，28万m²低密度社区，小区居住舒适，有游泳池，网球场，地上地下停车位充足，绿化达到80%，物业为招商物业公司，每天都有保安巡逻，治安很好。
				</div>
			</div>

			<div class="position-box">
				<div class="title">
					<div class="text">房源位置</div>
					<div class="position-button-box" @click="onPosition">
						<img class="position-button" src="../assets/icon/position.png" />
					</div>
				</div>
				<baidu-map :dragging="false" :double-click-zoom="false" :pinch-to-zoom="false" class="map-container">
					<bm-view class="map-view" cneter="深圳市" />
					<bm-transit v-if="destinationName.length==0" :start="locationPoint" end="深圳市龙岗区天玑城"
						:auto-viewport="true" :panel="false" />
					<bm-transit v-if="destinationName.length>0" start="深圳市龙岗区天玑城" :end="locationPoint"
						:auto-viewport="true" :panel="false" />
				</baidu-map>
				<div class="jump-button-box">
					<van-field v-model="destinationName" ref="startInput" @keydown="onInputStart"
						@blur="onBlurDestination" @focus="onFocusDestination" placeholder="输入目的地" />
					<van-button style="margin-left: auto" @click="onClicksSupport" size="small" text="周边详情"
						color="#26a5f9" />
					<van-button style="margin-right: 0" @click="showJumpPopup=true" size="small" text="立即出发"
						color="#2769fc" />
				</div>
				<van-popup v-model="showJumpPopup" position="bottom">
					<div class="jump-popup-menu">
						<div class="item" @click="onJumpToBaiduMap">百度地图</div>
						<van-divider />
						<div class="item" @click="onJumpToAMap">高德地图</div>
						<van-divider />
						<div class="item" @click="showJumpPopup=false">取消</div>
					</div>
				</van-popup>
			</div>

			<!--业主信息-->
			<div class="owner-box">
				<div class="head-box">
					<img :src="ownerInfo.headUrl" />
				</div>
				<div class="info-box">
					<div class="name">
						<div class="text">{{ ownerInfo.name }}</div>
					</div>
					<div class="info">
						<div class="text">正在出租：{{ ownerInfo.houseCount }}套</div>
					</div>
				</div>
				<div class="button-box">
					<div class="collection-button">
						<img @click="collectionFn" v-if="!ownerInfo.isCollection"
							src="../assets/icon/collection_line.png">
						<img @click="collectionFn" v-if="ownerInfo.isCollection"
							src="../assets/icon/collectioned.png">
					</div>
					<div class="chat-button" @click="onClickChat">
						<img src="../assets/icon/chat.png" />
					</div>
					<div class="phone-button" @click="onClickCallPhone">
						<img src="../assets/icon/phone.png" />
					</div>
					<div class="more-button">
						<img src="../assets/icon/right.png">
					</div>
				</div>
			</div>

			<!--该房东其他房源/周边房源数据分析-->
			<van-tabs class="other-box" v-model="otherIndex">
				<van-tab class="other-house-box" title="周边房源">
					<div class="item-box">
						<div class="item" v-for="(i,index) in otherHouseItems" :key="i.id"
							:style="index%2==0?'margin-right:2%':'margin-left:2%'">
							<div class="img-box">
								<img class="img" :src="i.imgUrl" />
							</div>
							<div class="title">
								{{ i.type }}租 · {{ i.livingRoomCount }}室{{ i.bedRoomCount }}厅 · {{ i.area }}m²
							</div>
							<div class="text">
								{{ i.district }}区/{{ i.residence }}
							</div>
							<div class="price">
								<div class="number">{{ i.price }}</div>
								<div class="unit">元/月</div>
							</div>
						</div>
					</div>
					<div class="button-box">
						<van-button @click="showFavorite" color="#26a5f9" text="查看更多" />
					</div>
				</van-tab>
				<!-- <van-tab class="nearby-house-box" title="周边房源数据分析">
					<div class="title">
						<div class="switch-button-box">
							<van-button @click="nearbyIndex==0?nearbyIndex=1:nearbyIndex=0" text="切换交通/配套"
								color="#26a5f9" size="small" />
						</div>
						<div class="input-box">
							<van-field v-model="destinationName" ref="destinationInput" @keydown="onInputDestination"
								@blur="onBlurDestination" @focus="onFocusDestination" placeholder="输入工作/常用地址" />
						</div>
						<div class="selected-button-box">
							<van-button text="查看已筛选" color="#dc143c" size="small" @click="showFavorite" />
						</div>
					</div>
					<div class="primary-box" v-if="nearbyIndex==0">
						<div class="title">
							<div class="item">房源</div>
							<div class="item">面积</div>
							<div class="item">租金</div>
							<div class="item">水/电</div>
							<div class="item">其他费用</div>
							<div class="item">透光率</div>
							<div class="item">筛选</div>
						</div>
						<div class="row" v-for="(i,index) in showFiltered?primaryItemsFiltered:primaryItems"
							:key="i.name" :style="index%2==0?'background-color:#EFEFEF':''">
							<div class="item">{{ i.name }}</div>
							<div class="item">
								<div class="text">{{ i.area }}</div>
								<div class="img-box">
									<img v-if="index!=0&&i.area>primaryItems[0].area" src="../assets/icon/rise.png">
									<img v-if="index!=0&&i.area<primaryItems[0].area" src="../assets/icon/decline.png">
								</div>
							</div>
							<div class="item">
								<div class="text">{{ i.price }}</div>
								<div class="img-box">
									<img v-if="index!=0&&i.price>primaryItems[0].price" src="../assets/icon/rise.png">
									<img v-if="index!=0&&i.price<primaryItems[0].price"
										src="../assets/icon/decline.png">
								</div>
							</div>
							<div class="item">
								<div class="text">{{ i.water }}/{{ i.electricity }}</div>
								<div class="img-box">
									<img v-if="index!=0&&(i.water>primaryItems[0].water||i.electricity>primaryItems[0].electricity)"
										src="../assets/icon/rise.png">
									<img v-if="index!=0&&(i.water<primaryItems[0].water||i.electricity<primaryItems[0].electricity)"
										src="../assets/icon/decline.png">
								</div>
							</div>
							<div class="item">
								<div class="text">{{ i.other }}</div>
								<div class="img-box">
									<img v-if="index!=0&&i.other>primaryItems[0].other" src="../assets/icon/rise.png">
									<img v-if="index!=0&&i.other<primaryItems[0].other"
										src="../assets/icon/decline.png">
								</div>
							</div>
							<div class="item">
								<div class="text">{{ Math.floor(i.lighting * 100) }}%</div>
								<div class="img-box">
									<img v-if="index!=0&&i.lighting>primaryItems[0].lighting"
										src="../assets/icon/rise.png">
									<img v-if="index!=0&&i.lighting<primaryItems[0].lighting"
										src="../assets/icon/decline.png">
								</div>
							</div>
							<div class="item" style="display: flex;">
								<div class="img-box">
									<img v-if="!i.isFavoriteFlag" style="height: 1.5em;"
										src="../assets/icon/collection_line.png">
									<img v-if="i.isFavoriteFlag" style="height: 1.5em;"
										src="../assets/icon/collectioned.png">
								</div>
							</div>
						</div>
					</div>
					<div class="secondary-box" v-if="nearbyIndex==1">
						<div class="title">
							<div class="item">房源</div>
							<div class="item">距离</div>
							<div class="item">公交路线</div>
							<div class="item">
								<van-dropdown-menu>
									<van-dropdown-item v-model="supportIndex" :options="supportItems" />
								</van-dropdown-menu>
							</div>
							<div class="item">筛选</div>
						</div>
						<div class="row" v-for="(i,index) in showFiltered?secondItemsFiltered:secondItems" :key="index"
							:style="index%2==0?'background-color:#EFEFEF':''">
							<div class="item">
								<div class="text">{{ i.name }}</div>
							</div>
							<div class="item" style="display: flex;text-align: right">
								<div class="text" style="width: 70%">{{
                    i.distance >= 1000 ? Math.round(i.distance / 1000) + '公里' : i.distance + '米'
                  }}
								</div>
								<div class="img-box" style="width: 30%;">
									<img style="height: 1em;" v-if="index!=0&&i.distance>secondItems[0].distance"
										src="../assets/icon/rise.png">
									<img style="height: 1em;" v-if="index!=0&&i.distance<secondItems[0].distance"
										src="../assets/icon/decline.png">
								</div>
							</div>
							<div class="item">
								<div class="text">{{ i.route }}</div>
							</div>
							<div class="item">
								<div class="text" v-if="supportIndex==0">{{ i.supermarket }}</div>
								<div class="text" v-if="supportIndex==1">{{ i.hospital }}</div>
								<div class="text" v-if="supportIndex==2">{{ i.school }}</div>
							</div>
							<div class="item" style="display: flex;">
								<img v-if="!i.isFavoriteFlag" style="margin: auto;"
									src="../assets/icon/collection_line.png">
								<img v-if="i.isFavoriteFlag" style="margin: auto;"
									src="../assets/icon/collectioned.png">
							</div>
						</div>
					</div>
				</van-tab> -->
			</van-tabs>

		</div>

		<div class="bottom-bar" v-if="showTabbar">
			<div class="price-box">
				<div class="actual-price">￥2654</div>
				<div class="original-price">￥3216</div>
				<div class="payment-period">/月</div>
			</div>

			<van-button class="see" color="#26a5f9" text="预约看房" @click="showDateTimePickerPopup=true" />
			<van-popup v-model="showDateTimePickerPopup" position="bottom">
				<van-datetime-picker v-model="watchDate" type="datehour" title="请选择看房日期" :min-date="minDate"
					:max-date="maxDate" @confirm="onConfirmWatchDate" @cancel="this.showDateTimePickerPopup = false;" />
			</van-popup>
			<van-button class="sign" color="#2769fc" text="立即签约" @click="showSignPopup=true" />
			<van-popup round v-model="showSignPopup" position="bottom">
				<div class="item-box">
					<div class="item">
						<div class="text">房号:</div>
						<div class="value">701</div>
					</div>
					<div class="item">
						<div class="text">租金:</div>
						<div class="value">￥2654元/月</div>
					</div>
					<div class="item">
						<div class="text">押金:</div>
						<div class="value">￥5308元</div>
					</div>
					<div class="item">
						<div class="text">管理费:</div>
						<div class="value">￥200元/月</div>
					</div>
					<div class="item">
						<div class="text">租期:</div>
						<div class="value">6个月</div>
					</div>
					<div class="item">
						<div class="text">租金缴纳日:</div>
						<div class="value">15号</div>
					</div>
				</div>
				<van-divider />
				<!--入住时间-->
				<div class="date-box">
					<div class="text">
						起租时间:
					</div>
					<div class="value" @click="showSignDate=true">
						{{ signDate.getFullYear() + '-' + signDate.getMonth() + '-' + signDate.getDate() }}
					</div>
					<div class="text" style="margin-left: auto">
						本次缴纳租金:
					</div>
					<div class="money" style="font-size: 20px;color: #DC143C;">
						<div style="margin-bottom: 3px;">
							￥1327元
						</div>
					</div>
				</div>
				<!--勾选条款-->
				<div class="agree-box">
					<van-checkbox v-model="isAgreeSign">您已阅读并同意《用户使用条款》《隐私保护条款》，授权我们查询您的实名、面部、指纹等信息。</van-checkbox>
				</div>
				<!--支付-->
				<div class="pay-box">
					<div class="total" style="display: flex;">
						<div style="margin: auto 5px;">合计:</div>
						<div style="margin: auto 0;font-size: 20px;color: #DC143C">￥6635元</div>
					</div>
					<div class="button-box">
						<van-button text="确定支付" color="#00c468" size="small" @click="pay()" />
					</div>
				</div>
			</van-popup>
		</div>
	</div>
</template>


<script>
	import Vue from 'vue';
	import {
		NavBar,
		Swipe,
		SwipeItem,
		Lazyload,
		Button,
		Popup,
		DatetimePicker,
		Toast,
		DropdownMenu,
		DropdownItem
	} from 'vant';

	Vue.use(NavBar);
	Vue.use(Swipe);
	Vue.use(SwipeItem);
	Vue.use(Lazyload);
	Vue.use(Button);
	Vue.use(Popup);
	Vue.use(DatetimePicker);
	Vue.use(Toast);
	Vue.use(DropdownMenu);
	Vue.use(DropdownItem);
	export default {
		name: "rent-detail",
		data() {
			return {
				roomItem: {},
				showFiltered: false,
				supportIndex: 0,
				supportItems: [{
						text: '商场',
						value: 0
					},
					{
						text: '医疗',
						value: 1
					},
					{
						text: '小学',
						value: 2
					},
				],

				//轮播图
				swipeImageItems: [
					'https://www.ikea.cn/images/yi2-jia1-dui4-gong1-ye4-wu4-yi2-jia1-pi1-fa1-wen2-ju4-pi1-fa-7817fd56b5412997e69eb2aa3eeb21a0.jpg?f=m',
					'https://www.ikea.cn/images/yi2-jia1-dui4-gong1-ye4-wu4-yi2-jia1-pi1-fa1-wen2-ju4-pi1-fa-ef228cccac1b9e29d4020ed525652ad3.jpg?f=m',
					'https://www.ikea.cn/images/yi2-jia1-dui4-gong1-ye4-wu4-yi2-jia1-pi1-fa1-wen2-ju4-pi1-fa-ef800953239cdd53dc7b750c41e62604.jpg?f=m',
					'https://www.ikea.cn/images/yi2-jia1-dui4-gong1-ye4-wu4-yi2-jia1-pi1-fa1-wen2-ju4-pi1-fa-f5ff5a7b429aaa44bfcac5cf6eb30f2c.jpg?f=m',
					'https://www.ikea.cn/images/yi2-jia1-dui4-gong1-ye4-wu4-yi2-jia1-pi1-fa1-wen2-ju4-pi1-fa-27eba1950ae12722ad4c8f115d13d754.jpg?f=m',
					'https://www.ikea.cn/images/yi2-jia1-dui4-gong1-ye4-wu4-yi2-jia1-pi1-fa1-wen2-ju4-pi1-fa-d3111b5f1478200f4e57c28fb2a132da.jpg?f=m',
					'https://www.ikea.cn/images/yi2-jia1-dui4-gong1-ye4-wu4-yi2-jia1-pi1-fa1-wen2-ju4-pi1-fa-908b98edc977b4f96ad4b9a6301a7db5.jpg?f=m',
					'https://www.ikea.cn/images/yi2-jia1-dui4-gong1-ye4-wu4-yi2-jia1-pi1-fa1-wen2-ju4-pi1-fa-2949000979c489d5ca3f6417eefb4144.jpg?f=m'
				],

				//标签
				tagItems: [
					'云匙8折',
					'近地铁',
					'近商圈',
					'生活配套丰富',
					'灵活短租',
				],

				//设备
				equipmentItems: [{
						text: '床',
						url: require('../assets/icon/bed.png')
					},
					{
						text: '衣柜',
						url: require('../assets/icon/wardrobe.png')
					},
					{
						text: '洗衣机',
						url: require('../assets/icon/washing_machine.png')
					}, {
						text: '空调',
						url: require('../assets/icon/air_conditioner.png')
					}
				],

				//定位
				locationPoint: {
					lat: 0,
					lng: 0
				},

				//出发点名称
				destinationName: '',

				//出发点 用于周边房源对比 默认值locationPoint 后根据destinationName获取
				destinationPoint: {
					lat: 0,
					lng: 0
				},

				//跳转菜单 (百度/高德)
				showJumpPopup: false,

				//该房东房源0 周边房源1
				otherIndex: 0,

				//周边房源交通分析0 配套分析1
				nearbyIndex: 0,

				//该房东其他房源
				otherHouseItems: [{
					id: 1001,
					type: '合',
					livingRoomCount: 1,
					bedRoomCount: 1,
					area: 36,
					price: 2600,
					imgUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1074388120,3557796269&fm=26&gp=0.jpg',
					district: '龙岗',
					residence: '天玑城-南苑'
				}, {
					id: 1002,
					type: '整',
					livingRoomCount: 1,
					bedRoomCount: 0,
					area: 24,
					price: 1800,
					imgUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1599889296316&di=5ccb17479ddc5ee0a882ac2270ca1aed&imgtype=0&src=http%3A%2F%2Fa-ssl.duitang.com%2Fuploads%2Fitem%2F201810%2F21%2F20181021015406_mhrjn.thumb.700_0.png',
					district: '龙岗',
					residence: '远洋新天地'
				}],

				primaryItems: [{
					name: '荷坳·乐城',
					area: 18.2,
					price: 1353,
					electricity: 1.5,
					water: 7,
					other: 50,
					lighting: 0.75,
					isFavoriteFlag: false
				}, {
					name: '南联·天玑城',
					area: 32,
					price: 2650,
					electricity: 1.6,
					water: 8,
					other: 150,
					lighting: 0.85,
					isFavoriteFlag: true
				}, {
					name: '南联·远海新干线',
					area: 27.3,
					price: 2804,
					electricity: 1.5,
					water: 7,
					other: 300,
					lighting: 1,
					isFavoriteFlag: false
				}, {
					name: '坪地·大东城',
					area: 31.2,
					price: 2360,
					electricity: 1.5,
					water: 7,
					other: 200,
					lighting: 0.65,
					isFavoriteFlag: true
				}, {
					name: '大运·星河时代',
					area: 21.5,
					price: 2684,
					electricity: 1.5,
					water: 7,
					other: 250,
					lighting: 0.55,
					isFavoriteFlag: true
				}],

				//http://api.map.baidu.com/place/v2/search?query=%E5%95%86%E5%9C%BA&location=22.680033,114.229494&radius=1000&output=json&page_size=1&ak=HsokRrR2m8zGqAKZh0odOCvv8sCDUOVN
				//http://api.map.baidu.com/place/v2/search?query=%E5%8C%BB%E9%99%A2$%E7%A4%BE%E5%BA%B7&location=22.680033,114.229494&radius=2000&output=json&page_size=1&ak=HsokRrR2m8zGqAKZh0odOCvv8sCDUOVN
				//http://api.map.baidu.com/place/v2/search?query=%E5%B9%BC%E5%84%BF%E5%9B%AD$%E5%B0%8F%E5%AD%A6$%E4%B8%AD%E5%AD%A6$%E9%AB%98%E4%B8%AD&location=22.680033,114.229494&radius=2000&output=json&page_size=1&ak=HsokRrR2m8zGqAKZh0odOCvv8sCDUOVN
				secondItems: [{
					name: '荷坳·乐城',
					price: 1353,
					distance: 0,
					route: '',
					position: {
						lat: 22.679862,
						lng: 114.229512,
					},
					supermarket: '',
					hospital: '',
					school: '',
					isFavoriteFlag: false
				}, {
					name: '南联·天玑城',
					price: 2650,
					distance: 0,
					route: '',
					position: {
						lat: 22.730305,
						lng: 114.273681,
					},
					supermarket: '',
					hospital: '',
					school: '',
					isFavoriteFlag: true
				}, {
					name: '南联·远海新干线',
					price: 2804,
					distance: 0,
					route: '',
					position: {
						lat: 22.724443,
						lng: 114.267964,
					},
					supermarket: '',
					hospital: '',
					school: '',
					isFavoriteFlag: false
				}, {
					name: '坪地·恒大帝豪城',
					price: 2360,
					distance: 0,
					route: '',
					position: {
						lat: 22.713494,
						lng: 114.346889,
					},
					supermarket: '',
					hospital: '',
					school: '',
					isFavoriteFlag: true
				}, {
					name: '大运·星河时代',
					price: 2684,
					distance: 0,
					route: '',
					position: {
						lat: 22.698533,
						lng: 114.233945,
					},
					supermarket: '',
					hospital: '',
					school: '',
					isFavoriteFlag: true,
				}],

				//弹出层(看房时间选择器)展
				showDateTimePickerPopup: false,

				//弹出层(签约界面)
				showSignPopup: false,

				//当前时间
				watchDate: new Date(),
				minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
				maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7, 23),

				signDate: new Date(),
				showTabbar: true,
				isAgreeSign: false,

				ownerInfo: {
					headUrl: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=2092511787,1157776987&fm=26&gp=0.jpg',
					name: '心在当下',
					houseCount: 25,
					isCollection: false
				},
				primaryItemsFiltered: [],
				secondItemsFiltered: [],
			}
		},
		mounted() {

			console.log(this.$route.query)
			this.roomItem = JSON.parse(this.$route.query.roomItem)

			console.log(1234567)
			console.log(this.roomItem)

			this.onPosition();

			window.vue = this;

			this.screenWidth = document.body.clientWidth;
			this.screenHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					// this.screenWidth = document.body.clientWidth;
					// this.screenHeight = document.body.clientHeight;
					if (this.screenHeight - document.body.clientHeight > this.screenHeight * 0.1) {
						this.showTabbar = false;
					} else {
						this.showTabbar = true;
					}
				})();
			};
		},
		methods: {
			collectionFn(){
				let url = '';
				if(this.ownerInfo.isCollection){
					 url = 'collection/del'
				}else{
				     url = 'collection/add'
				}
				this.$axios({
					url: url,
					method: "POST",
					headers: {
						'Content-Type': 'application/json;charset=UTF-8'
					},
					data: {
						houseId: this.roomItem.houseId,
						roomId: this.roomItem.id,
					}
				}).then(res => {
					console.log(res)
					if (res.code === 20001) {
						if (res.message === '请先登录') {
							this.$router.push('/login');
						} else {
							Toast.fail(url=='collection/del'?'取消收藏失败':'收藏失败');
						}
					} else {
						Toast.success(url=='collection/del'? '取消收藏成功':'收藏成功');
						this.ownerInfo.isCollection= !this.ownerInfo.isCollection;
					}
				
					if (res.status === 10007) {
						this.$router.push('/login');
					}
				}).catch(error => {
					Toast.fail('收藏失败');
					console.log(error)
				})
			},
			pay() {
				this.$axios({
					url: "paid/addPaid",
					method: "POST",
					headers: {
						'Content-Type': 'application/json;charset=UTF-8'
					},
					data: {
						houseId: this.roomItem.houseId,
						roomId: this.roomItem.id,
						address: this.roomItem.address,
						price: this.roomItem.price
					}
				}).then(res => {
					console.log('下单租房子', res)
					console.log(res)
					if (res.code === 20001) {
						if (res.message === '请先登录') {
							this.$router.push('/login');
						} else {
							Toast.fail('签订失败');
						}
					} else {
						Toast.success('签订成功');
					}

					if (res.status === 10007) {
						this.$router.push('/login');
					}
				}).catch(error => {
					Toast.fail('添加失败');
					console.log(error)
				})
			},
			onClickFallback() {
				this.$router.back();
			},
			onClickMore() {

			},
			onClickCallPhone() {
				window.location.href = 'tel://15897741992';
			},
			onClickChat() {
				this.$router.push('/chat-window?to='+2);
			},
			onClicksSupport() {
				this.$router.push('/supporting');
			},
			onJumpToBaiduMap() {
				this.showJumpPopup = false;
				window.location.href =
					'bdapp://map/direction?origin=我的位置&destination=深圳南联天玑城&coord_type=bd09ll&mode=transit&sy=0';
			},
			onJumpToAMap() {
				this.showJumpPopup = false;
				window.location.href =
					'amapuri://route/plan/?did=&dlat=22.72441051&dlon=114.26689445&dname=深圳南联天玑城&dev=0&t=1';
			},
			gps2baiduPoint(point, callback) {
				this.$axios({
					// url: "/baiduapi/geoconv/v1/",
					url: "http://api.map.baidu.com/geoconv/v1/",
					method: "GET",
					params: {
						coords: point.lng + ',' + point.lat,
						from: 1,
						to: 5,
						ak: 'EknQesPTKV54qEOYGm49uF0mern5O6bt'
					}
				}).then(res => {
					if (res.data.status == 0) {
						let result = {
							lng: res.data.result.x,
							lat: res.data.result.y,
						}
						callback(result);
					}
				})
			},
			onPosition() {
				this.destinationName = '';
				this.$getLocation({
					enableHighAccuracy: true, //defaults to false
					timeout: Infinity, //defaults to Infinity
					maximumAge: 0 //defaults to 0
				}).then(coordinates => {
					this.locationPoint.lat = coordinates.lat;
					this.locationPoint.lng = coordinates.lng;
					this.gps2baiduPoint(this.locationPoint, (result) => {
						this.locationPoint = result;
					});
					this.destinationPoint = this.locationPoint;
					this.afterPosition();
				});
			},
			toViewVR() {
				this.$router.push('/vr');
			},
			afterPosition() {
				for (let i = 0; i < this.secondItems.length; i++) {
					this.neabySearch('幼儿园$小学$中学$大学$学校', this.secondItems[i].position, 2000, 1, ((data) => {
						this.secondItems[i].school = data[0].name;
					}));

					this.neabySearch('商场$超市$便利店', this.secondItems[i].position, 2000, 1, ((data) => {
						this.secondItems[i].supermarket = data[0].name;
					}));

					this.neabySearch('医院$社康$药店', this.secondItems[i].position, 2000, 1, ((data) => {
						this.secondItems[i].hospital = data[0].name;
					}));

					this.routePlanning(this.destinationPoint, this.secondItems[i].position, ((text, distance) => {
						this.secondItems[i].route = text;
						this.secondItems[i].distance = distance;
					}));
				}
			},
			//http://api.map.baidu.com/place/v2/search?query=%E5%B9%BC%E5%84%BF%E5%9B%AD$%E5%B0%8F%E5%AD%A6$%E4%B8%AD%E5%AD%A6$%E9%AB%98%E4%B8%AD&location=22.680033,114.229494&radius=2000&output=json&page_size=1&ak=HsokRrR2m8zGqAKZh0odOCvv8sCDUOVN
			neabySearch(query, position, radius, size, callback) {
				this.$axios({
					// url: "/baiduapi/place/v2/search",
					url: "http://api.map.baidu.com/place/v2/search",
					method: "GET",
					params: {
						query: query,
						location: position.lat + ',' + position.lng,
						radius: radius,
						page_size: size,
						output: 'json',
						ak: 'HsokRrR2m8zGqAKZh0odOCvv8sCDUOVN'
					}
				}).then(res => {
					callback(res.data.results);
				})
			},
			//http://api.map.baidu.com/directionlite/v1/transit?origin=22.642,114.1983&destination=22.713494,114.346889&ak=EknQesPTKV54qEOYGm49uF0mern5O6bt
			routePlanning(origin, destination, callback) {
				this.$axios({
					// url: "/baiduapi/directionlite/v1/transit",
					url: "http://api.map.baidu.com/directionlite/v1/transit",
					method: "GET",
					params: {
						origin: origin.lat + ',' + origin.lng,
						destination: destination.lat + ',' + destination.lng,
						ak: 'EknQesPTKV54qEOYGm49uF0mern5O6bt'
					}
				}).then(res => {
					let routeText = '';
					let routeDistance = 0;
					if (res.data.status == 0) {
						for (let i = 0; i < res.data.result.routes[0].steps.length; i++) {
							routeDistance = res.data.result.routes[0].distance;
							if (res.data.result.routes[0].steps[i][0].vehicle.name.length > 0) {
								routeText = routeText == '' ? res.data.result.routes[0].steps[i][0].vehicle.name :
									routeText += '→' + res.data.result.routes[0].steps[i][0].vehicle.name;
							}
						}
					}
					callback(routeText, routeDistance);
				})
			},
			onFocusDestination() {
				this.nearbyIndex = 1;
			},
			onInputStart(key) {
				if (key.keyCode == 13) {
					this.$refs.startInput.blur();
				}
			},
			onInputDestination(key) {
				if (key.keyCode == 13) {
					this.$refs.destinationInput.blur();
				}
			},
			onBlurDestination() {
				//http://api.map.baidu.com/geocoding/v3/?address=%E6%B7%B1%E5%9C%B3%E5%B8%82%E5%B0%9A%E5%9F%8E%E5%A4%A7%E5%8E%A6&output=json&ak=EknQesPTKV54qEOYGm49uF0mern5O6bt&callback=showLocation
				this.$axios({
					// url: "/baiduapi/geocoding/v3",
					url: "http://api.map.baidu.com/geocoding/v3/",
					method: "GET",
					params: {
						address: this.destinationName,
						output: 'json',
						ak: 'EknQesPTKV54qEOYGm49uF0mern5O6bt'
					}
				}).then(res => {
					if (res.data.status == 0) {
						this.destinationPoint.lat = res.data.result.location.lat;
						this.destinationPoint.lng = res.data.result.location.lng;
						this.locationPoint = this.destinationPoint;

						for (let i = 0; i < this.secondItems.length; i++) {
							this.routePlanning(this.destinationPoint, this.secondItems[i].position, ((text,
								distance) => {
								this.secondItems[i].route = text;
								this.secondItems[i].distance = distance;
							}))
						}
					}
				})
			},
			onConfirmWatchDate() {
				this.showDateTimePickerPopup = false;
				Toast.success('预约成功');
			},
			onConfirmSignDate() {
				this.showSignDate = false;
			},
			showFavorite: function() {
				this.showFiltered = !this.showFiltered;
				console.log(this.showFiltered);
				this.primaryItemsFiltered = [];
				for (let i = 0; i < this.primaryItems.length; i++) {
					if (this.primaryItems[i].isFavoriteFlag) {
						this.primaryItemsFiltered.push(this.primaryItems[i]);
					}
				}

				this.secondItemsFiltered = [];
				for (let i = 0; i < this.secondItems.length; i++) {
					if (this.secondItems[i].isFavoriteFlag) {
						this.secondItemsFiltered.push(this.secondItems[i]);
					}
				}
			}
		}
	}
</script>

<style scoped>
	#rentDetail-container {
		background-color: #F8F8F8;
	}

	.van-nav-bar__placeholder>>>.van-nav-bar--fixed {
		background-color: #26a5f9;
		border: 0;
	}

	.van-index-anchor {
		background-color: #efefef;
	}

	.navbar-title {
		color: #FFFFFF;
		font-weight: bold;
	}

	#rentDetail-body {
		padding-bottom: 4em;
	}

	.bottom-bar {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #FFFFFF;
		height: 3em;
		padding: 5px;
		display: flex;
		z-index: 2;
	}

	.bottom-bar .price-box {
		margin: auto 0;
		display: flex;
	}

	.bottom-bar .actual-price {
		font-size: 22px;
		color: #DC143C;
	}

	.bottom-bar .original-price {
		font-size: 14px;
		color: #646566;
		margin: auto 2px;
		text-decoration: line-through;
	}

	.bottom-bar .payment-period {
		font-size: 14px;
		color: #646566;
		margin: auto 2px;
	}

	.bottom-bar .collection,
	.bottom-bar .phone {
		width: 2em;
		margin: 0 7px;
	}

	.bottom-bar .collection .icon-box,
	.bottom-bar .phone .icon-box {
		margin: 0 auto;
		width: 80%;
	}

	.bottom-bar .collection .icon-box img,
	.bottom-bar .phone .icon-box img {
		width: 100%;
	}

	.bottom-bar .collection .text,
	.bottom-bar .text {
		text-align: center;
		font-size: 12px;
		color: #646566;
	}

	.bottom-bar .see {
		width: 26%;
		margin-left: auto;
		margin-right: 5px;
		border-radius: 10px;
	}

	.bottom-bar .sign {
		margin-left: 5px;
		width: 26%;
		border-radius: 10px;
	}

	.bottom-bar .item-box {
		display: flex;
		flex-wrap: wrap;
		padding: 0 2%;
		padding-top: 2%;
	}

	.bottom-bar .item-box .item {
		display: flex;
		height: 2em;
		width: 48%;
	}

	.bottom-bar .item-box .item .text {
		margin: auto 0;
	}

	.bottom-bar .item-box .item .value {
		margin: auto 5px;
	}

	.bottom-bar .date-box {
		display: flex;
		padding: 0 2%;
	}

	.bottom-bar .date-box>* {
		margin: auto 0;
	}

	.bottom-bar .date-box .value {
		margin: auto 5px;
		background-color: #26a5f9;
		padding: 2%;
		border-radius: 5px;
		color: #FFFFFF;
	}

	.bottom-bar .agree-box {
		font-size: 12px;
		margin: 2%;
		padding: 1%;
		background-color: #EFEFEF;
	}

	.bottom-bar .pay-box {
		display: flex;
		padding-bottom: 2%;
	}

	.bottom-bar .pay-box>* {
		margin: auto 7px;
	}

	.bottom-bar .total {
		margin-left: auto;
	}

	.bottom-bar .pay-box>>>.van-button {
		height: 3em;
		width: 8em;
		border-radius: 5px;
	}

	.bottom-bar .pay-box>>>.van-button__text {
		font-size: 16px;
	}

	.van-swipe {
		height: 30vh;
	}

	.van-swipe-item {
		height: 100%;
	}

	.van-swipe-item img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.tag-box {
		display: flex;
		padding: 0 4%;
	}

	.tag-box .item {
		background-color: #eaeaea;
		margin: 0 3px;
		padding: 4px;
		font-size: 12px;
		border-radius: 2px;
	}

	.type-info {
		margin: 2%;
		padding: 0 2%;
		font-size: 18px;
		height: 2em;
		display: flex;
	}

	.type-info .text {
		margin: auto 0;
	}

	.type-info .button-box {
		margin-left: auto;
		display: flex;
		background-color: #26a5f9;
		padding: 2%;
		border-radius: 5px;
	}

	.type-info .button-box .text {
		color: #FFFFFF;
		font-size: 16px;
	}

	.type-info img {
		width: 1.5em;
	}

	.important-info {
		padding: 2% 0;
		display: flex;
	}

	.important-info .item {
		margin: 0 auto;
		text-align: center;
	}

	.important-info .title {
		font-size: 12px;
		color: #646566;
	}

	.important-info .text {
		font-weight: bold;
	}

	.secondary-info {
		display: flex;
		margin: 2%;
		padding: 2%;
		background-color: #FFFFFF;
		border-radius: 5px;
		font-size: 14px;
	}

	.secondary-info .left-box {
		width: 60%;
	}

	.secondary-info .right-box {
		width: 40%;
	}

	.secondary-info .item {
		display: flex;
		margin: 7px 0;
	}

	.secondary-info .item .title {
		color: #646566;
	}

	.secondary-info .item .text {
		margin-left: 14px;
	}

	.equipment-info {
		margin: 2%;
		padding: 2%;
		background-color: #FFFFFF;
		border-radius: 5px;
	}

	.equipment-info .title {
		font-weight: bold;
	}

	.equipment-info .item-box {
		display: flex;
	}

	.equipment-info .item {
		margin-top: 2%;
		width: 3em;
	}

	.equipment-info .icon-box {
		width: 80%;
		margin: auto;
	}

	.equipment-info img {
		width: 100%;
	}

	.equipment-info .text {
		text-align: center;
		font-size: 12px;
	}

	.equipment-info .remark {
		font-size: 12px;
	}

	.position-box {
		margin: 2%;
		background-color: #FFFFFF;
		padding: 2%;
		border-radius: 5px;
	}

	.position-box .title {
		font-weight: bold;
		display: flex;
	}

	.position-box .title>* {
		margin: auto 0;
	}

	.position-box .title .position-button-box {
		margin-left: auto;
	}

	.position-box .map-container {
		width: 100%;
		height: 20em;
	}

	.position-box .map-container .map-view {
		width: 100%;
		height: 100%;
		border-radius: 5px;
	}

	.position-box>>>.anchorBL {
		display: none;
	}

	.position-box .jump-button-box {
		display: flex;
		margin-top: 5px;
	}

	.position-box .jump-button-box>>>.van-field {
		width: 50%;
	}

	.position-box .position-button-box {
		height: 2em;
	}

	.position-box .position-button {
		height: 100%;
	}

	.position-box .jump-button-box>>>.van-button {
		display: block;
		margin: auto 7px;
		border-radius: 5px;
		width: 25%;
	}

	.position-box>>>.van-popup {
		border-radius: 5px 5px 0 0;
	}

	.position-box .jump-popup-menu .item {
		text-align: center;
		margin: 1em 0;
	}

	.position-box .jump-popup-menu>>>.van-divider {
		margin: 0;
	}

	.owner-box {
		margin: 2%;
		padding: 2%;
		background-color: #FFFFFF;
		border-radius: 5px;
		display: flex;
		height: 4em;
	}

	.owner-box .head-box {
		height: 80%;
		margin: auto 0;
	}

	.owner-box .head-box img {
		height: 100%;
		border-radius: 50%;
	}

	.owner-box .info-box {
		margin: 0 5px;
		height: 100%;
	}

	.owner-box .info-box .name {
		display: flex;
	}

	.owner-box .info-box .name .img-box {
		height: 60%;
		margin: auto 5px;
	}

	.owner-box .info-box .name .img-box img {
		height: 100%;
		display: block;
	}

	.owner-box .info-box .name,
	.owner-box .info-box .info {
		height: 50%;
		display: flex;
	}

	.owner-box .info-box .name .text,
	.owner-box .info-box .info .text {
		margin: auto 0;
	}

	.owner-box .info-box .name .text {
		font-weight: bold;
	}

	.owner-box .info-box .info .text {
		font-size: 14px;
		color: #646566;
	}

	.owner-box .button-box {
		display: flex;
		margin: auto 0;
		margin-left: auto;
		max-width: 40%;
	}

	.owner-box .button-box>* {
		width: 30%;
		margin: 0 7px;
	}

	.owner-box .button-box img {
		width: 100%;
	}


	/*.other-house-box {*/
	/*  padding: 2%;*/
	/*  margin: 2%;*/
	/*  background-color: #FFFFFF;*/
	/*  border-radius: 5px;*/
	/*}*/

	.other-box {
		background-color: #FFFFFF;
		border-radius: 5px;
		margin: 2%;
		padding: 1%;
	}

	.other-box>>>.van-tabs__line {
		background-color: #26a5f9;
		z-index: 1;
	}

	.other-house-box {
		padding: 2%;
	}

	.other-house-box .item-box {
		display: flex;
		flex-wrap: wrap;
		margin-top: 2%;
		margin-top: 2%;
	}

	.other-house-box .item {
		width: 48%;
	}

	.other-house-box .img-box {
		width: 100%;
		height: 120px;
	}

	.other-house-box img {
		object-fit: cover;
		border-radius: 5px;
		width: 100%;
		height: 100%;
	}

	.other-house-box .item .title {
		font-size: 14px;
		font-weight: bolder;
		margin: 1% 0;

	}

	.other-house-box .text {
		color: #646566;
		font-size: 12px;
		margin: 1% 0;
	}

	.other-house-box .price {
		display: flex;
		color: #DC143C;
		margin: 1% 0;
	}

	.other-house-box .number {
		font-weight: bolder;
	}

	.other-house-box .unit {
		font-size: 12px;
		margin-top: auto;
		margin-bottom: 1px;
		margin-left: 1px;
	}

	.other-house-box .button-box {
		margin: 2% 0;
	}

	.other-house-box>>>.van-button {
		width: 100%;
		border-radius: 10px;
		display: block;
		margin: auto;
	}

	.nearby-house-box>.title {
		display: flex;
		margin-top: 2%;
		height: 3em;
	}

	.nearby-house-box .switch-button-box,
	.nearby-house-box .selected-button-box {
		margin: auto 0;
	}

	.nearby-house-box .switch-button-box>>>.van-button,
	.nearby-house-box .selected-button-box>>>.van-button {
		font-size: 10px;
	}

	.nearby-house-box .selected-button-box {
		margin-left: auto;
	}

	.nearby-house-box>>>.van-button {
		padding: 0 2px;
		border-radius: 5px;
	}

	.nearby-house-box .primary-box {
		border-radius: 5px;
		font-size: 12px;
	}

	.nearby-house-box .secondary-box {
		border-radius: 5px;
		font-size: 12px;
	}

	.nearby-house-box .secondary-box>>>.van-dropdown-menu__bar {
		height: 100%;
		background-color: #26a5f9;
	}

	.nearby-house-box .secondary-box>>>.van-ellipsis {
		color: #FFFFFF;
		font-size: 11px;
	}

	.nearby-house-box .secondary-box>>>.van-dropdown-menu__title--active {
		color: #FFFFFF;
	}

	.nearby-house-box .secondary-box>>>.van-dropdown-item__option--active,
	.nearby-house-box .secondary-box>>>.van-dropdown-item__icon {
		color: #26a5f9;
	}

	.nearby-house-box .primary-box .title,
	.nearby-house-box .secondary-box .title {
		display: flex;
		color: #FFFFFF;
		background-color: #26a5f9;
		font-size: 11px;
		height: 3em;
	}

	.nearby-house-box .primary-box .title .item,
	.nearby-house-box .secondary-box .title .item {
		margin: auto 0;
	}

	.nearby-house-box .primary-box .row {
		display: flex;
		min-height: 3em;
	}

	.nearby-house-box .primary-box .title .item,
	.nearby-house-box .primary-box .row .item {
		width: 15%;
		text-align: center;
		margin: auto;
	}

	.nearby-house-box .primary-box .title .item .text,
	.nearby-house-box .primary-box .row .item .text {
		width: 70%;
		text-align: right;
	}

	.nearby-house-box .primary-box .title .item .img-box,
	.nearby-house-box .primary-box .row .item .img-box {
		width: 30%;
		margin: auto;
	}

	.nearby-house-box .secondary-box .title .item {
		width: 20%;
		text-align: center;
	}

	.nearby-house-box .primary-box .row .item {
		display: flex;
	}

	.nearby-house-box .primary-box .row img {
		height: 1em;
		display: block;
	}

	.nearby-house-box .secondary-box .row {
		display: flex;
		min-height: 3em;
	}

	.nearby-house-box .secondary-box .row .item {
		width: 20%;
		text-align: center;
		margin: auto;
	}

	.nearby-house-box .secondary-box .row .item .img-box {
		width: 30%;
		margin: auto;
	}

	.nearby-house-box .secondary-box img {
		height: 1.5em;
		display: block;
		margin: auto;
	}
</style>
