<template>
	<div id="my-container">
		<!--头像盒子-->
		<div class="head-box" @click="onToPersonalInformation">
			<div class="img-box">
				<van-image round
					src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2291332875,175289127&fm=26&gp=0.jpg" />
			</div>
			<div class="name-box">
				<div class="nickName">{{ vuex_user.name || vuex_user.phone || '--' }}</div>
				<div class="loginName">云匙号:{{vuex_user.id}}</div>
			</div>
			<div class="icon-box">
				<van-icon class="qr-icon" name="qr" />
				<van-icon style="font-size: 25px;" name="arrow" />
			</div>
		</div>

		<!--横向菜单盒子-->
		<div class="transverse-box">
			<div class="item">
				<div class="img-box">
					<img src="../assets/icon/vip_colorful.png" />
				</div>
				<div class="text">开通会员</div>
			</div>

			<div class="item">
				<div class="img-box">
					<img src="../assets/icon/coupon.png" />
				</div>
				<div class="text">优惠卷</div>
			</div>

			<div class="item">
				<div class="img-box">
					<img src="../assets/icon/order.png" />
				</div>
				<div class="text">订单</div>
			</div>

			<div class="item" @click="toCollection">
				<div class="img-box">
					<img src="../assets/icon/collection.png" />
				</div>
				<div class="text">收藏</div>
			</div>
		</div>

		<!--竖向菜单盒子-->
		<div class="portrait-box">
			<div class="item">
				<div class="img-box">
					<img src="../assets/icon/vip_colorful.png" />
				</div>
				<div class="text">会员</div>
				<div class="icon-box">
					<van-icon name="arrow" />
				</div>
			</div>
			<van-divider />
			<div class="item" @click="onToWallet">
				<div class="img-box">
					<img src="../assets/icon/wallet.png" />
				</div>
				<div class="text">钱包</div>
				<div class="icon-box">
					<van-icon name="arrow" />
				</div>
			</div>
			<van-divider />
			<div class="item" @click="onToAuthFamily">
				<div class="img-box">
					<img src="../assets/icon/family_certification.png" />
				</div>
				<div class="text">家庭认证</div>
				<div class="icon-box">
					<van-icon name="arrow" />
				</div>
			</div>
			<van-divider />
			<div class="item">
				<div class="img-box">
					<img src="../assets/icon/cost.png" />
				</div>
				<div class="text">费用清单</div>
				<div class="icon-box">
					<van-icon name="arrow" />
				</div>
			</div>
			<van-divider />
			<div class="item" @click="onToTemporatVisit">
				<div class="img-box">
					<img src="../assets/icon/temporary_visit.png" />
				</div>
				<div class="text">临时来访</div>
				<div class="icon-box">
					<van-icon name="arrow" />
				</div>
			</div>
			<van-divider />
			<div class="item" @click="onToBooking">
				<div class="img-box">
					<img src="../assets/icon/booking.png" />
				</div>
				<div class="text">预约办理</div>
				<div class="icon-box">
					<van-icon name="arrow" />
				</div>
			</div>
			<van-divider />
			<div class="item" @click="onToRelease">
				<div class="img-box">
					<img src="../assets/icon/release.png" />
				</div>
				<div class="text">转租/分租发布</div>
				<div class="icon-box">
					<van-icon name="arrow" />
				</div>
			</div>
			<van-divider />
			<div class="item" @click="showSettingPopup=true">
				<div class="img-box">
					<img src="../assets/icon/setting.png" />
				</div>
				<div class="text">设置</div>
				<div class="icon-box">
					<van-icon name="arrow" />
				</div>
			</div>
		</div>

		<van-popup class="setting-popup" v-model="showSettingPopup" position="bottom" round>
			<div class="item-box">
				<div class="item" @click="onToAccountManagement">
					<div class="img-box">
						<img src="../assets/icon/account_setting.png" />
					</div>
					<div class="text">账户管理</div>
				</div>

				<div class="item" @click="onToKeyManagement">
					<div class="img-box">
						<img src="../assets/icon/key.png" />
					</div>
					<div class="text">钥匙管理</div>
				</div>

				<div class="item" @click="onToRealName">
					<div class="img-box">
						<img src="../assets/icon/real_name.png" />
					</div>
					<div class="text">实名认证</div>
				</div>

				<div class="item">
					<div class="img-box">
						<img src="../assets/icon/finger_print.png" />
					</div>
					<div class="text">指纹采集</div>
				</div>


				<div class="item">
					<div class="img-box">
						<img src="../assets/icon/contact_service.png" />
					</div>
					<div class="text">联系客服</div>
				</div>

				<div class="item">
					<div class="img-box">
						<img src="../assets/icon/switch_account.png" />
					</div>
					<div class="text">切换账号</div>
				</div>

				<div class="item">
					<div class="img-box">
						<img src="../assets/icon/about_us.png" />
					</div>
					<div class="text">关于旦巢</div>
				</div>

				<div class="item" @click="loginOut">
					<div class="img-box">
						<img src="../assets/icon/exit.png" />
					</div>
					<div class="text">退出</div>
				</div>
			</div>
		</van-popup>


	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import Vue from 'vue';
	import {
		Image as VanImage,
		Icon,
		Divider,
		Dialog,
		Toast
	} from 'vant';

	Vue.use(VanImage);
	Vue.use(Icon);
	Vue.use(Divider);
	Vue.use(Dialog);
	Vue.use(Toast);
	export default {
		name: "my",
		data() {
			return {
				showSettingPopup: false
			}
		},
		computed: {
			...mapState({
				vuex_user: state => state.vuex_user,
			})
		},
		methods: {
			onToBooking: function() {
				this.$router.push('/booking');
			},
			onToRelease: function() {
				Toast('该功能正在升级中...');
				return false;
				//this.$router.push('/release');
			},
			onToWallet() {
				this.$router.push('/wallet');
			},
			onToAuthFamily() {
				this.$router.push('/auth-family');
			},
			onToRealName() {
				this.$router.push('/real-name');
			},
			onToAccountManagement() {
				this.$router.push('/account-management');
			},
			onToKeyManagement() {
				this.$router.push('/key-management');
			},
			onToPersonalInformation() {
				this.$router.push('/personal-information');
			},
			onToTemporatVisit() {
				this.$router.push('/temporary-visit');
			},
			toCollection(){
				this.$router.push('/collection');
			},
			loginOut() {
				this.$axios({
					url: "login/out",
					method: "POST",
					headers: {
						'Content-Type': 'application/json;charset=UTF-8'
					}
				}).then(res => {
					const {
						status
					} = res
					if (status === 10000) {
						this.$store.commit('changeVuexToken',
							'')
						this.$store.commit('changeVuexUser', {})
						this.$router.push('/login');
					} else {
						Toast.fail(res.msg || '退出登录失败');
					}
					// if (res.data.code == 10019) {
					//   this.$router.push('/index');
					// }else if(res.data.code == 10011){
					//   Toast.fail('账户/密码错误!');
					// } else if (res.data.code == 10012) {
					//   Toast.fail('账户未激活,请联系我们!');
					// } else if (res.data.code == 10013) {
					//   Toast.fail('账户异常,请联系客服!');
					// } else {
					//   Toast.fail('登录失败');
					// }
				}).catch(error => {
					console.log(error, 'error');
					Toast.fail('退出登录失败');
				})
			}
		}
	}
</script>

<style scoped>
	#my-container {
		height: 100%;
		background-color: #F8F8F8;
	}

	.head-box {
		padding: 48px 32px;
		display: flex;
		background-color: #FFFFFF;
	}

	.head-box .img-box {
		width: 3em;
	}

	.name-box {
		height: 100%;
		margin: auto 0;
		margin-left: 7px;
	}

	.name-box .nickName {
		font-size: 18px;
		font-weight: bold;
	}

	.name-box .loginName {
		font-size: 12px;
		color: #646566;
	}

	.head-box .icon-box {
		margin: auto 0;
		margin-left: auto;
	}

	.qr-icon {
		margin-right: 7px;
		font-size: 25px;
	}

	.transverse-box {
		display: flex;
		background-color: #FFFFFF;
		padding: 7px;
		margin: 7px 0;
	}

	.transverse-box .item {
		width: 25%;
	}

	.transverse-box .item .img-box {
		width: 40%;
		margin: auto;
	}

	.transverse-box .item img {
		width: 100%;
	}

	.transverse-box .item .text {
		text-align: center;
		margin: 0 auto;
		font-size: 12px;
	}

	.portrait-box {
		margin-top: 7px;
		background-color: #FFFFFF;
	}

	.portrait-box .item {
		height: 2em;
		display: flex;
		padding: 7px 14px;
	}

	.portrait-box .icon-box {
		margin: auto 0;
		margin-left: auto;
	}

	.portrait-box .icon-box>>>.van-icon {
		display: block;
		margin: auto 0;
	}

	.portrait-box .text {
		margin: auto 0;
		margin-left: 5px;
	}

	.portrait-box .img-box {
		margin: auto 0;
		width: 1.5em;
		height: 1.5em;
	}

	.portrait-box img {
		height: 100%;
		width: 100%;
	}

	.portrait-box>>>.van-divider {
		margin: 0;
	}

	.setting-popup>.item-box {
		display: flex;
		flex-wrap: wrap;
		padding: 2%;
		margin-bottom: 14px;
	}

	.setting-popup>.item-box>.item {
		width: 25%;
		margin: 14px 0;
	}

	.setting-popup>.item-box>.item>.img-box {
		width: 35%;
		margin: auto;
	}

	.setting-popup>.item-box>.item>.img-box>img {
		width: 100%;
	}

	.setting-popup>.item-box>.item>.text {
		text-align: center;
		font-size: 14px;
		color: #646566;
	}
</style>
