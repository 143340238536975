<template>
  <div id="modify-payment-password-container">
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">{{type == 1 ? '设置支付密码':'修改支付密码'}}</span>
      </template>
    </van-nav-bar>

    <div class="body">
      <van-password-input
          :value="form.paypassword"
          :info="tips"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
      />
	  <div style="height: 30px;"></div>
	  <van-password-input
	      :value="form.oldPaypassword"
	      :info="tips2"
	      :focused="showKeyboard2"
	      @focus="showKeyboard2 = true"
	  />

      <van-number-keyboard
          :show="showKeyboard"
          @input="onInput"
          @delete="onDelete"
          @blur="showKeyboard = false"
      />
	  
	  <van-number-keyboard
	      :show="showKeyboard2"
	      @input="onInput2"
	      @delete="onDelete2"
	      @blur="showKeyboard2 = false"
	  />
	  
	 <div class="button-box">
		  <van-button type="primary" class="sure-btn" @click="updatePayPassword">确 定</van-button>
	 </div>
	 
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { PasswordInput, NumberKeyboard , Toast} from 'vant';
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Toast);
import {
		mapState
	} from 'vuex'
export default {
  name: "modify-payment-password",
  data() {
    return {
      form: {
		  paypassword:'',
		  oldPaypassword:''
	  },
      showKeyboard: true,
	  showKeyboard2:false,
      tips:'请输入支付密码',
	  tips2:'请再次输入支付密码',
	  type:1,
    };
  },
  created() {
  	this.type = this.$route.query.type;
  },
  computed: {
  	...mapState({
  		vuex_user: state => state.vuex_user,
  	})
  },
  methods: {
	updatePayPassword(){
		if(this.form.paypassword != this.form.oldPaypassword){
			Toast.fail('两次输入密码不一致');
			return false;
		}
		  this.$axios({
		  	url: "/user/editPayPassword",
		  	method: "POST",
			data:this.form,
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			},
		  }).then(res => {
		  	let {
		  		status,
		  		data,
				code,
		  	} = res
		  	if (status === 10000) {
		  		Toast.success('设置支付密码成功');
				this.$router.push('/payment-management')
		  	}else if(status === 10007) {
				this.$router.push('/login')
			}
			
			if(code == 20000){
				Toast.success('设置支付密码成功');
				this.$router.push('/payment-management')
			}
		  		  
		  }).catch(error => {
		  	console.log(error, 'error');
		  	Toast.fail('设置支付密码失败');
		  })
	},
    onClickFallback() {
      this.$router.back();
    },
    onInput(key) {
      this.form.paypassword = (this.form.paypassword + key).slice(0, 6);
    },
    onDelete() {
      this.form.paypassword = this.form.paypassword.slice(0, this.form.paypassword.length - 1);
    },
	onInput2(key) {
	  this.form.oldPaypassword = (this.form.oldPaypassword + key).slice(0, 6);
	},
	onDelete2() {
	  this.form.oldPaypassword = this.form.oldPaypassword.slice(0,  this.form.oldPaypassword.length - 1);
	}
  }
}
</script>

<style scoped>
	.sure-btn{
		width: 90%;
		font-size: 18px;
		display: inline-block;
		text-align: center;
	}
#modify-payment-password-container {
  min-height: 100%;
}
/deep/ .van-password-input__security li{
	background-color: #f8f8f8;
}
.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.van-index-anchor {
  background-color: #efefef;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}
.button-box{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
}
.body{
  padding-top: 40px;
}

</style>
