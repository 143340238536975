
//用户数据示例
let users = [{
		"userId": "2",
		"name": "赵颖欣",
		"password": "123",
		"avatar": '/static/images/Avatar-1.png'
	},
	{
		"userId": "3",
		"name": "zuke",
		"password": "123",
		"avatar": '/static/images/Avatar-2.png'
	},
	{
		"userId": "5",
		"name": "fangdong",
		"password": "123",
		"avatar": '/static/images/Avatar-3.png'
	},
	{
		"userId": "33c3693b-dbb0-4bc9-99c6-fa77b9eb763f",
		"name": "Juanita",
		"password": "123",
		"avatar": '/static/images/Avatar-4.png'
	}
];

//群数据示例
let groups = [{
		"id": "group-a42b-47b2-bb1e-15e0f5f9a19a",
		"name": "小程序交流群",
		"avatar": '/static/images/wx.png',
		"userList": ['2', '3bb179af-bcc5-4fe0-9dac-c05688484649', 'fdee46b0-4b01-4590-bdba-6586d7617f95',
			'33c3693b-dbb0-4bc9-99c6-fa77b9eb763f'
		]
	},
	{
		"id": "group-4b01-4590-bdba-6586d7617f95",
		"name": "UniApp交流群",
		"avatar": '/static/images/uniapp.png',
		"userList": ['5', 'fdee46b0-4b01-4590-bdba-6586d7617f95', '33c3693b-dbb0-4bc9-99c6-fa77b9eb763f']
	},
	{
		"id": "group-dbb0-4bc9-99c6-fa77b9eb763f",
		"name": "GoEasy交流群",
		"avatar": '/static/images/goeasy.jpeg',
		"userList": ['5', '3bb179af-bcc5-4fe0-9dac-c05688484649']
	}
];

function RestApi() {

}

function User(userId, name, avatar) {
	this.userId = userId;
	this.name = name;
	this.avatar = avatar;
}

function Group(id, name, avatar) {
	this.id = id;
	this.name = name;
	this.avatar = avatar;
}

RestApi.prototype.findFriends = async function(user) {
	let friendList = users.filter(v => Number(v.userId) !== Number(user.userId));
	return friendList;
}

RestApi.prototype.findGroups = async function(user) {
	let groupList = groups.filter(v => v.userList.find(userId => Number(userId) === Number(user.userId)));
	return groupList;
}

RestApi.prototype.findUser = function(username, password) {
	let user = users.find(user => (user.name === username && user.password === password));
	if (user) {
		return new User(user.userId, user.name, user.avatar);
	}
	return user;
}

RestApi.prototype.findGroupById = function(groupId) {
	let group = groups.find(group => (group.id === groupId));
	return new Group(group.id, group.name, group.avatar);
};

RestApi.prototype.findUserById = function(userId) {
	let user = users.find(user => (Number(user.userId) === Number(userId)));
	return new User(user.userId, user.name, user.avatar);
};

RestApi.prototype.findGroupMembers = function(groupId) {
	let members = {};
	let group = groups.find(v => v.id === groupId);
	users.map(user => {
		let groupUserUuid = group.userList.find((id) => {
			return id === user.id;
		});
		if (groupUserUuid) {
			members[groupUserUuid] = new User(user.id, user.name, user.avatar);
		}
	});
	return members;
}

export default new RestApi();
