<template>
	<div id="personal-information-container">
		<van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
			<template #left>
				<van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff" />
			</template>
			<template #title>
				<span class="navbar-title">个人信息</span>
			</template>
		</van-nav-bar>
		<div class="item-box">
			<div class="item">
				<div class="text">头像:</div>
				<div class="head">
					<van-image @click="showHeadPopup=true"
						src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2291332875,175289127&fm=26&gp=0.jpg" />
				</div>
			</div>
			<van-divider />
			<div class="item">
				<div class="text">昵称:</div>
				<div class="option">
					<van-field v-model="nickname" input-align="right" />
				</div>
			</div>
			<van-divider />
			<div class="item">
				<div class="text">旦巢号:</div>
				<div class="option option-text">{{ loginName }}</div>
			</div>
			<van-divider />
			<div class="item">
				<div class="text">手机号:</div>
				<div class="option option-text">{{telephone}}</div>
			</div>
			<van-divider />
			<div class="item" @click="showQRPopup=true;creatQrCode()">
				<div class="text">我的二维码</div>
				<div class="option option-icon">
					<van-icon name="qr" style="font-size: 20px;" />
					<van-icon name="arrow" style="font-size: 20px;" />
				</div>
			</div>
			<van-divider />
		</div>

		<!-- <div class="button-box" @click="onToBack">
			<div class="text">确定</div>
		</div> -->

		<van-popup class="qr-popup" v-model="showQRPopup">
			<div class="qr-box">
				<!-- <img src="../assets/testQr.png"/> -->
				<div id="qrcode" ref="qrCodeDiv"></div>
				<div class="remark">扫一扫二维码图案,加我旦巢好友</div>
				<van-button type="primary"  @click="downloadCode">保存</van-button>
			</div>
		</van-popup>

		<van-popup class="head-popup" v-model="showHeadPopup">
			<van-image
				src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2291332875,175289127&fm=26&gp=0.jpg" />
		</van-popup>
		<a id="downloadImg"></a>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2';
	import {
		mapState
	} from 'vuex'
	export default {
		name: "personal-information",
		data() {
			return {
				nickname: '刘昌',
				loginName: 'DC001',
				telephone: '15897741992',
				showQRPopup: false,
				showHeadPopup: false,
				qrcode:'',
			}
		},
		mounted() {
			
			if(!this.vuex_user.userId){
				this.$router.push("/login")
			}
			//this.creatQrCode();
		},
		computed: {
			...mapState({
				vuex_user: state => state.vuex_user,
			})
		},
		methods: {
			// 生成二维码
			creatQrCode() {
				var vm = this;
				this.$nextTick(() => {
					console.log(vm.vuex_user)
					var id = vm.vuex_user.userId
					console.log(vm.$refs.qrCodeDiv)
					let text = id
					vm.qrcode = new QRCode(vm.$refs.qrCodeDiv, {
						text: text, //二维码地址，避免识别不了url地址将进行编码
						width: 150, //单位（px）
						height: 150, //单位（px）
						colorDark: "#000000", //二维码颜色：支持英文、十六进制、RGB、RGBA
						colorLight: "#FFFFFF", //二维码背景色：支持英文、十六进制、RGB、RGBA
						render: 'canvas', // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
						correctLevel: QRCode.CorrectLevel.L, //容错率，由低到高：L/M/Q/H
					})
				})
			},
			// 保存下载二维码
			downloadCode() {
				let img = document.getElementById('qrcode').getElementsByTagName('img')[0];
				// 构建画布
				let canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;
				canvas.getContext('2d').drawImage(img, 0, 0);
				// 构造url
				let url = canvas.toDataURL('image/png');
				document.getElementById('downloadImg').setAttribute('href', url);
				document.getElementById('downloadImg').setAttribute('download', '我的二维码.png');
				document.getElementById('downloadImg').click();
			},
			onClickFallback() {
				this.$router.back();
			},
			onToBack() {
				this.$router.back();
			}
		}
	}
</script>

<style scoped lang="scss">
	#qrcode {
		width: 150px;
		height: 150px;
		margin-right: 0px;
		margin: 0 auto;
		img {
			width: 100%;
		}
	}

	#personal-information-container {
		background-color: #F8F8F8;
		min-height: 100%;
	}

	.van-nav-bar__placeholder>>>.van-nav-bar--fixed {
		background-color: #26a5f9;
		border: 0;
	}

	.van-index-anchor {
		background-color: #efefef;
	}

	.navbar-title {
		color: #FFFFFF;
		font-weight: bold;
	}

	::v-deep .van-divider {
		margin: 0;
	}


	.item-box>.item {
		display: flex;
		min-height: 3em;
		padding: 0 4%;
		background-color: #FFFFFF;
	}

	.item-box>.item>.text {
		margin: auto 0;
	}

	.item-box>.item>.option {
		margin: auto 0;
		margin-left: auto;
	}

	.item-box>.item>.option-text {
		margin-right: 11px;
		font-size: 14px;
		color: #323233
	}

	.item-box>.item>.option-icon {
		margin-right: 11px;
	}

	.item-box>.item>.head {
		width: 3em;
		margin: 2%;
		margin-left: auto;
	}

	.item-box>.item>.head>>>img {
		border-radius: 5px;
	}


	.button-box {
		margin: 2% 0;
		display: flex;
		min-height: 3em;
		padding: 0 4%;
		background-color: #FFFFFF;
	}

	.button-box .text {
		color: #26a5f9;
		margin: auto;
	}

	.qr-popup {
		width: 80%;
		border-radius: 5px;
		padding: 2%;
	}

	.qr-box {
		padding-top: 30px;
		background-color: #FFFFFF;
		text-align: center;
	}

	.qr-box img {
		width: 100%;
		display: block;
		margin: auto;
	}

	.qr-box .remark {
		font-size: 12px;
		line-height: 33px;
		text-align: center;
		color: #646566;
	}

	.head-popup {
		width: 100%;
	}
</style>
