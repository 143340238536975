<template>
	<div id="supporting-container">

		<div class="navbar">
			<div class="title">
				<div class="button-box" @click="onClickFallback">
					<img src="../assets/icon/left.png">
				</div>
				<div class="text">周边配套</div>
				<div class="button-box" @click="showJumpPopup=true">
					<img src="../assets/icon/navigation.png">
				</div>
			</div>
			<van-popup v-model="showJumpPopup" position="bottom">
				<div class="jump-popup-menu">
					<div class="item" @click="onJumpToBaiduMap">百度地图</div>
					<van-divider />
					<div class="item" @click="onJumpToAMap">高德地图</div>
					<van-divider />
					<div class="item" @click="showJumpPopup=false">取消</div>
				</div>
			</van-popup>
		</div>

		<baidu-map class="map-container" :center="positionPoint" :continuous-zoom="true" :inertial-dragging="true"
			:zoom="15">
			<bm-marker :position="positionPoint" animation="BMAP_ANIMATION_BOUNCE" :z-index="2"
				:icon="{url: require('../assets/icon/location.png'), size: {width: 32, height: 32}}" />
			<span v-if="infoIndex==0">
				<bm-marker v-for="(i,index) in busItems" :key="i.index" @click="busIndex=index" :position="i.location"
					:icon="{url: require('../assets/icon/bus.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="busIndex==index" @close="busIndex=-1">
						<div class="name">公交站:{{ i.name }}</div>
						<div class="text">{{ i.address }}</div>
					</bm-info-window>
				</bm-marker>
				<bm-marker v-for="(i,index) in metroItems" :key="i.index" :z-index="1" @click="metroIndex=index"
					:position="i.location"
					:icon="{url: require('../assets/icon/metro.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="metroIndex==index" @close="metroIndex=-1">
						<div class="name">地铁站:{{ i.name }}</div>
						<div class="text">{{ i.address }}</div>
					</bm-info-window>
				</bm-marker>
			</span>
			<span v-if="infoIndex==1">
				<bm-marker v-for="(i,index) in nurserySchoolItems" :key="i.index" @click="nurserySchoolIndex=index"
					:position="i.location"
					:icon="{url: require('../assets/icon/school.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="nurserySchoolIndex==index" @close="nurserySchoolIndex=-1">
						<div class="name">{{ i.name }}</div>
						<div class="text">地址:{{ i.address }}</div>
					</bm-info-window>
				</bm-marker>
				<bm-marker v-for="(i,index) in primarySchoolItems" :key="i.index" @click="primarySchoolIndex=index"
					:position="i.location"
					:icon="{url: require('../assets/icon/school.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="primarySchoolIndex==index" @close="primarySchoolIndex=-1">
						<div class="name">{{ i.name }}</div>
						<div class="text">{{ i.address }}</div>
					</bm-info-window>
				</bm-marker>
				<bm-marker v-for="(i,index) in middleSchoolItems" :key="i.index" :position="i.location"
					:icon="{url: require('../assets/icon/school.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="middleSchoolIndex==index" @close="middleSchoolIndex=-1">
						<div class="name">{{ i.name }}</div>
						<div class="text">{{ i.address }}</div>
					</bm-info-window>
				</bm-marker>
			</span>
			<span v-if="infoIndex==2">
				<bm-marker v-for="(i,index) in hospitalItems" :key="i.index" @click="hospitalIndex=index"
					:position="i.location"
					:icon="{url: require('../assets/icon/hospital.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="hospitalIndex==index" @close="hospitalIndex=-1">
						<div class="name">{{ i.name }}</div>
						<div class="text">地址:{{
                                                                      i.address
                                                                    }}</div>
					</bm-info-window>
				</bm-marker>
				<bm-marker v-for="(i,index) in pharmacyItems" :key="i.index" @click="pharmacyIndex=index"
					:position="i.location"
					:icon="{url: require('../assets/icon/pharmacy.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="pharmacyIndex==index" @close="pharmacyIndex=-1">
						<div class="name">{{ i.name }}</div>
						<div class="text">地址:{{
                                                                      i.address
                                                                    }}</div>
					</bm-info-window>
				</bm-marker>
			</span>

			<span v-if="infoIndex==3">
				<bm-marker v-for="(i,index) in bankItems" :key="i.index" @click="bankIndex=index" :position="i.location"
					:icon="{url: require('../assets/icon/bank.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="bankIndex==index" @close="bankIndex=-1">
						<div class="name">{{ i.name }}</div>
						<div class="text">地址:{{
                                                                      i.address
                                                                    }}</div>
					</bm-info-window>
				</bm-marker>
				<bm-marker v-for="(i,index) in shoppingItems" :key="i.index" @click="shoppingIndex=index" z-index="1"
					:position="i.location"
					:icon="{url: require('../assets/icon/shopping.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="shoppingIndex==index" @close="shoppingIndex=-1">
						<div class="name">{{ i.name }}</div>
						<div class="text">地址:{{
                                                                      i.address
                                                                    }}</div>
					</bm-info-window>
				</bm-marker>
			</span>

			<span v-if="infoIndex==4">

				<bm-marker v-for="(i,index) in cinemaItems" :key="i.index" @click="cinemaIndex=index"
					:position="i.location"
					:icon="{url: require('../assets/icon/cinema.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="cinemaIndex==index" @close="cinemaIndex=-1">
						<div class="name">{{ i.name }}</div>
						<div class="text">地址:{{
                                                                      i.address
                                                                    }}</div>
					</bm-info-window>
				</bm-marker>
				<bm-marker v-for="(i,index) in motionItem" :key="i.index" @click="motionIndex=index"
					:position="i.location"
					:icon="{url: require('../assets/icon/motion.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="motionIndex==index" @close="motionIndex=-1">
						<div class="name">{{ i.name }}</div>
						<div class="text">地址:{{
                                                                      i.address
                                                                    }}</div>
					</bm-info-window>
				</bm-marker>
				<bm-marker v-for="(i,index) in sceneryItem" :key="i.index" @click="sceneryIndex=index"
					:position="i.location"
					:icon="{url: require('../assets/icon/scenery.png'), size: {width: 32, height: 32}}">
					<bm-info-window :show="sceneryIndex==index" @close="sceneryIndex=-1">
						<div class="name">{{ i.name }}</div>
						<div class="text">地址:{{
                                                                      i.address
                                                                    }}</div>
					</bm-info-window>
				</bm-marker>
			</span>
		</baidu-map>

		<div class="info-box">
			<van-tabs v-model="infoIndex">
				<van-tab title="交通">

				</van-tab>
				<van-tab title="教育">

				</van-tab>
				<van-tab title="医疗">

				</van-tab>
				<van-tab title="生活">

				</van-tab>
				<van-tab title="休闲">

				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
	export default {
		name: "supporting",
		data() {
			return {
				//房源定位(百度经纬)
				positionPoint: {
					lat: 22.682180331430364,
					lng: 114.23080006946705
				},

				showJumpPopup: false,

				infoIndex: 0,
				//地铁站
				metroItems: [],
				metroIndex: -1,
				//公交站
				busItems: [],
				busIndex: -1,
				//幼儿园
				nurserySchoolItems: [],
				nurserySchoolIndex: -1,
				//小学
				primarySchoolItems: [],
				primarySchoolIndex: -1,
				//初中
				middleSchoolItems: [],
				middleSchoolIndex: -1,
				//医院
				hospitalItems: [],
				hospitalIndex: -1,
				//药店
				pharmacyItems: [],
				pharmacyIndex: -1,
				//银行
				bankItems: [],
				bankIndex: -1,
				//商场
				shoppingItems: [],
				shoppingIndex: -1,
				//景区
				sceneryItem: [],
				sceneryIndex: -1,
				//电影院
				cinemaItems: [],
				cinemaIndex: -1,
				//运动场所
				motionItem: [],
				motionIndex: -1
			}
		},
		mounted() {
			this.getNearbyItems(this.positionPoint, '地铁站', 3000, 20, ((data) => {
				for (let i = 0; i < data.length; i++) {
					this.metroItems.push({
						name: data[i].name,
						location: data[i].location,
						address: data[i].address
					});
				}
			}));

			this.getNearbyItems(this.positionPoint, '公交站', 1000, 20, ((data) => {
				for (let i = 0; i < data.length; i++) {
					this.busItems.push({
						name: data[i].name,
						location: data[i].location,
						address: data[i].address
					});
				}
			}));
		},
		methods: {
			getNearbyItems(point, query, radius, size, callback) {
				this.$axios({
					// url: "/baiduapi/place/v2/search",
					url: "http://api.map.baidu.com/place/v2/search",
					method: "GET",
					params: {
						query: query,
						location: point.lat + ',' + point.lng,
						radius: radius,
						page_size: size,
						output: 'json',
						ak: 'HsokRrR2m8zGqAKZh0odOCvv8sCDUOVN'
					}
				}).then(res => {
					console.log(res);
					if (res.data.status == 0) {
						callback(res.data.results);
					}
				})
			},
			onClickFallback() {
				this.$router.back();
			},
			onJumpToBaiduMap() {
				this.showJumpPopup = false;
				window.location.href =
					'bdapp://map/direction?origin=我的位置&destination=深圳南联天玑城&coord_type=bd09ll&mode=transit&sy=0';
			},
			onJumpToAMap() {
				this.showJumpPopup = false;
				window.location.href =
					'amapuri://route/plan/?did=&dlat=22.72441051&dlon=114.26689445&dname=深圳南联天玑城&dev=0&t=1';
			},
		},
		watch: {
			infoIndex: function(newValue) {
				switch (newValue) {
					case 1:
						if (this.nurserySchoolItems.length == 0 && this.primarySchoolItems.length == 0 && this
							.primarySchoolItems.length == 0) {
							this.getNearbyItems(this.positionPoint, '幼儿园', 2000, 20, ((data) => {
								for (let i = 0; i < data.length; i++) {
									this.nurserySchoolItems.push({
										name: data[i].name,
										location: data[i].location,
										address: data[i].address
									});
								}
							}));

							this.getNearbyItems(this.positionPoint, '小学', 2000, 20, ((data) => {
								for (let i = 0; i < data.length; i++) {
									this.primarySchoolItems.push({
										name: data[i].name,
										location: data[i].location,
										address: data[i].address
									});
								}
							}));

							this.getNearbyItems(this.positionPoint, '初中', 5000, 20, ((data) => {
								for (let i = 0; i < data.length; i++) {
									this.primarySchoolItems.push({
										name: data[i].name,
										location: data[i].location,
										address: data[i].address
									});
								}
							}));
						}
						break;
					case 2:
						if (this.hospitalItems.length == 0 && this.pharmacyItems.length == 0) {
							this.getNearbyItems(this.positionPoint, '医疗', 3000, 20, ((data) => {
								for (let i = 0; i < data.length; i++) {
									this.hospitalItems.push({
										name: data[i].name,
										location: data[i].location,
										address: data[i].address
									});
								}
							}));
							this.getNearbyItems(this.positionPoint, '药店', 3000, 20, ((data) => {
								for (let i = 0; i < data.length; i++) {
									this.pharmacyItems.push({
										name: data[i].name,
										location: data[i].location,
										address: data[i].address
									});
								}
							}));
						}
						break;
					case 3:
						if (this.bankItems.length == 0 && this.shoppingItems.length == 0) {
							this.getNearbyItems(this.positionPoint, '银行$保险', 3000, 20, ((data) => {
								for (let i = 0; i < data.length; i++) {
									this.bankItems.push({
										name: data[i].name,
										location: data[i].location,
										address: data[i].address
									});
								}
							}));

							this.getNearbyItems(this.positionPoint, '商场', 3000, 20, ((data) => {
								for (let i = 0; i < data.length; i++) {
									this.shoppingItems.push({
										name: data[i].name,
										location: data[i].location,
										address: data[i].address
									});
								}
							}));
						}
						break;
					case 4:
						if (this.sceneryItem.length == 0 && this.cinemaItems.length == 0 && this.motionItem.length ==
							0) {
							this.getNearbyItems(this.positionPoint, '景区', 3000, 20, ((data) => {
								for (let i = 0; i < data.length; i++) {
									this.sceneryItem.push({
										name: data[i].name,
										location: data[i].location,
										address: data[i].address
									});
								}
							}));

							this.getNearbyItems(this.positionPoint, '影院$剧场', 3000, 20, ((data) => {
								for (let i = 0; i < data.length; i++) {
									this.cinemaItems.push({
										name: data[i].name,
										location: data[i].location,
										address: data[i].address
									});
								}
							}));

							this.getNearbyItems(this.positionPoint, '运动', 3000, 20, ((data) => {
								for (let i = 0; i < data.length; i++) {
									this.motionItem.push({
										name: data[i].name,
										location: data[i].location,
										address: data[i].address
									});
								}
							}));
						}
						break;
				}
			}
		}
	}
</script>

<style scoped>
	#supporting-container {
		height: 100%;
	}

	::v-deep .van-tabs__line {
		background-color: #26a5f9;
	}

	::v-deep .BMap_Marker img {
		object-fit: cover;
		width: 100%;
	}

	.navbar {
		background-color: #FFFFFF;
		position: fixed;
		width: 90%;
		height: 2.5em;
		border-radius: 5px;
		margin: 5%;
		z-index: 1;
	}

	.navbar .title {
		height: 100%;
		width: 100%;
		display: flex;
	}

	.navbar .button-box {
		height: 50%;
		margin: auto 5px;
	}

	.navbar .text {
		font-weight: bold;
		margin: auto;
	}

	.navbar img {
		height: 100%;
	}

	.navbar>>>.van-popup {
		border-radius: 5px 5px 0 0;
	}

	.navbar .jump-popup-menu .item {
		text-align: center;
		margin: 1em 0;
	}

	.navbar .jump-popup-menu>>>.van-divider {
		margin: 0;
	}

	.map-container {
		height: 100%;
	}

	.map-container>>>.anchorBL {
		display: none;
	}

	.map-container .name {
		padding-right: 14px;
	}

	.map-container .text {
		font-size: 12px;
		color: #646566;
	}

	.info-box {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
	}
</style>
