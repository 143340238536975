<template>
	<div id="real-name-container">
		<van-nav-bar :placeholder="true" :fixed="true" :border="false" class="navbar">
			<template #left>
				<van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff" />
			</template>
			<template #title>
				<span class="navbar-title">实名认证</span>
			</template>
		</van-nav-bar>
		<!-- <div class="step-box">
      <van-steps :active="step" active-color="#26a5f9">
        <van-step>身份信息</van-step>
        <van-step>人脸采集</van-step>
        <van-step>完成认证</van-step>
      </van-steps>
    </div> -->
		<div class="first-page" v-show="step==0">
			<div class="item">
				<div class="text">姓名:</div>
				<div class="option">
					<van-field placeholder="请输入姓名" v-model="name" input-align="right" />
				</div>
			</div>
			<van-divider />
			<!-- <div class="item">
        <div class="text">出生日期:</div>
        <div class="option option-text"
             v-if="!(this.birthday.getFullYear() == this.maxDate.getFullYear() && this.birthday.getMonth() == this.maxDate.getMonth() && this.birthday.getDate() == this.maxDate.getDate())"
             @click="showBirthdayPicker=true">
          {{ birthday.getFullYear() }}年{{ birthday.getMonth() + 1 }}月{{ birthday.getDate() }}日
        </div>
        <div class="button-box"
             v-if="(this.birthday.getFullYear() == this.maxDate.getFullYear() && this.birthday.getMonth() == this.maxDate.getMonth() && this.birthday.getDate() == this.maxDate.getDate())">
          <van-button @click="showBirthdayPicker=true" size="small" text="选择出生日期" color="#26a5f9"/>
        </div>
        <van-popup v-model="showBirthdayPicker" position="bottom">
          <van-datetime-picker v-model="birthday" :max-date="maxDate" :min-date="minDate" type="date" title="请选择出生日期"
                               @confirm="showBirthdayPicker=false" @cancel="showBirthdayPicker=false"/>
        </van-popup>
      </div>
      <van-divider/> -->
			<!-- <div class="item">
        <div class="text">手机号码:</div>
        <div class="option">
          <van-field placeholder="请输入手机号码" v-model="telephone" input-align="right"/>
        </div>
      </div>
      <van-divider/> -->
			<!-- <div class="item">
        <div class="text">民族:</div>
        <div class="option option-text" v-if="nation!=''" @click="showNationPicker=true">
          {{ nation }}
        </div>
        <div class="button-box" v-if="nation==''">
          <van-button @click="showNationPicker=true" size="small" text="选择民族" color="#26a5f9"/>
        </div>
        <van-popup v-model="showNationPicker" position="bottom">
          <van-picker
              title="选择民族"
              show-toolbar
              :columns="nationItems"
              @confirm="onSelectNation"
              @cancel="showNationPicker=false"
          />
        </van-popup>
      </div>
      <van-divider/> -->
			<div class="item">
				<div class="text">证件类型:</div>
				<div class="option option-text" v-if="idType!=''" @click="showIdTypePicker=true">
					{{ idType }}
				</div>
				<div class="button-box" v-if="idType==''">
					<van-button @click="showIdTypePicker=true" size="small" text="选择证件类型" color="#26a5f9" />
				</div>
				<van-popup v-model="showIdTypePicker" position="bottom">
					<van-picker title="选择证件类型" show-toolbar :columns="idTypeItems" @confirm="onSelectIdType"
						@cancel="showIdTypePicker=false" />
				</van-popup>
			</div>
			<van-divider />
			<div class="item" v-if="idType=='户口本'">
				<div class="text">上传户主页面</div>
				<div class="option">
					<van-uploader preview-image>
						<van-button icon="plus" type="primary" color="#26a5f9" size="small" />
					</van-uploader>
				</div>
			</div>
			<van-divider v-if="idType=='户口本'" />
			<div class="item" v-if="idType=='户口本'">
				<div class="text">上传本人页面</div>
				<div class="option">
					<van-uploader>
						<van-button icon="plus" type="primary" color="#26a5f9" size="small" />
					</van-uploader>
				</div>
			</div>
			<van-divider v-if="idType=='户口本'" />
			<div class="item" v-if="idType=='身份证'||idType=='居住证'">
				<div class="text">上传本人人脸</div>
				<div class="option">
					<van-uploader v-model="idcard_img_people" :max-count="1" accept='.jpg,.jpeg,.png'
						:before-read="beforeRead" :after-read="afterReadIdCardFront">
						<!-- <van-button icon="plus" type="primary" color="#26a5f9" size="small" /> -->
					</van-uploader>
				</div>
			</div>
			<!-- <van-divider v-if="idType=='身份证'||idType=='居住证'" />
			<div class="item" v-if="idType=='身份证'||idType=='居住证'">
				<div class="text">上传{{ idType }}反面</div>
				<div class="option">
					<van-uploader v-model="idcard_img_back" :max-count="1" accept='.jpg,.jpeg,.png'
						:before-read="beforeRead" :after-read="afterReadIdCardBack"> -->
			<!-- <van-button icon="plus" type="primary" color="#26a5f9" size="small" v-if="idcard_img_back.length === 0"/> -->
			<!-- </van-uploader>
				</div>
			</div> -->
			<van-divider v-if="idType=='身份证'||idType=='居住证'" />
			<div class="item">
				<div class="text">证件号码:</div>
				<div class="option">
					<van-field placeholder="请输入证件号码" v-model="idNo" input-align="right" />
				</div>
			</div>
			<!-- <van-divider/>
      <div class="item" style="height: auto;min-height: 3em;">
        <div class="text">户籍地址:</div>
        <div class="option">
          <van-field
              v-model="permanentAddress"
              rows="1"
              autosize
              type="textarea"
              placeholder="请输入户籍地址"
              input-align="right"
          />
        </div>
      </div>
      <van-divider/> -->
			<!-- <div class="item">
        <div class="text">拍摄本人照片</div>
        <div class="option">
          <van-uploader>
            <van-button icon="plus" type="primary" color="#26a5f9" size="small"/>
          </van-uploader>
        </div>
      </div>
      <van-divider/> -->
			<div class="button-box" @click="authUser">
				<van-button text="开始认证" size="small" color="#26a5f9" />
			</div>
		</div>
		<div class="second-page">

		</div>
		<div class="third-page">

		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import {

		Toast
	} from 'vant';


	Vue.use(Toast);
	export default {
		name: "real-name",
		data() {
			return {
				name: '',
				birthday: new Date(),
				maxDate: new Date(),
				minDate: new Date(1920, 1, 1, ),
				showBirthdayPicker: false,
				telephone: '',
				nation: '',
				nationItems: ['汉族', '壮族', '满族', '回族', '苗族', '维吾尔族', '土家族', '彝族', '蒙古族', '藏族', '布依族', '侗族', '瑶族', '朝鲜族',
					'白族', '哈尼族', '哈萨克族', '黎族', '傣族', '畲族', '僳僳族', '仡佬族', '东乡族', '拉祜族', '水族', '佤族', '纳西族', '羌族', '土族',
					'仫佬族', '锡伯族', '柯尔克孜族', '达翰尔族', '景颇族', '毛南族', '撒拉族', '布朗族', '塔吉克族', '阿昌族', '普米族', '鄂温克族', '怒族',
					'京族', '基诺族', '德昂族', '保安族', '俄罗斯族', '裕固族', '乌孜别克族', '门巴族', '鄂伦春族', '独龙族', '塔塔尔族', '赫哲族', '高山族',
					'珞巴族', '其他'
				],
				showNationPicker: false,
				idType: '',
				idTypeItems: ['身份证', /* '居住证', '户口本' */ ],
				showIdTypePicker: false,
				idNo: '',
				permanentAddress: '',
				keyId: '',
				step: 0,
				idcard_img_front: [
					/* {
											url: 'https://img01.yzcdn.cn/vant/leaf.jpg',
											status: 'uploading',
											message: '上传中...',
										},
										{
											url: 'https://img01.yzcdn.cn/vant/tree.jpg',
											status: 'failed',
											message: '上传失败',
										}, */
				],
				idcard_img_people: [],
				idcard_img_back: [],
			}
		},
		methods: {
			// 开始实名认证，调用后端接口
			authUser() {
				this.$axios({
					url: "tenant/userAuth",
					method: "POST",
					headers: {
						'Content-Type': 'application/json;charset=UTF-8'
					},
					data: {
						idcardNum: this.idNo,
						idcardName: this.name,
						idcardImgFront: this.idcard_img_front[0]?.url,
						idcardImgPeople: this.idcard_img_people[0]?.url,
						base64StrPeople: this.idcard_img_people[0]?.content.replace(/^data:image\/\w+;base64,/, ""),
						idcardImgBack: this.idcard_img_back[0]?.url
					}
				}).then(res => {
					console.log(res)
					if (res.status === 20000) {
						Toast.fail(res.message || '认证成功');
						this.$router.push('/index');
					} else {
						Toast.fail(res.message || '认证失败');
					}
				}).catch(error => {
					console.log(error, 'error');
					Toast.fail('认证失败');
				})
			},
			afterReadIdCardFront(file) {
				var vm = this;
				file.status = 'uploading';
				file.message = '上传中...';

				this.uploadFile(file.file, function(res) {
					console.log(res)
					let {
						code,
						message,
						data
					} = res;
					if (code === 20001) {
						file.status = 'failed';
						file.message = '上传失败';
						Toast.fail(message)
					} else {
						file.status = 'success';
						file.message = '上传成功';
						file.url = data;
						console.log(vm.idcard_img_front)
					}
				})
			},
			afterReadIdCardBack(file) {
				let vm = this;
				file.status = 'uploading';
				file.message = '上传中...';
				console.log(file)

				this.uploadFile(file.file, function(res) {
					console.log(res)
					let {
						code,
						message,
						data
					} = res;
					if (code === 20001) {
						file.status = 'failed';
						file.message = '上传失败';
						Toast.fail(message)
					} else {
						file.status = 'success';
						file.message = '上传成功';
						file.url = data;
					}
					console.log(vm.idcard_img_back)

				})
			},
			uploadFile(file, cb) {
				let formData = new FormData();
				formData.append('file', file)
				this.$axios({
					url: "upload/file",
					method: "POST",
					headers: {
						'Content-Type': 'application/json;charset=UTF-8'
					},
					data: formData
				}).then(res => {
					console.log(res)
					if (cb && typeof cb === 'function') cb(res)
				}).catch(error => {
					console.log(error, 'error');
					Toast.fail('文件上传失败');
				})
			},
			beforeRead(file) {
				console.log("上传前", file);
				const imgformat = /image\/(png|jpg|jpeg)$/;
				if (!imgformat.test(file.type)) {
					Toast.fail('请上传 jpg/jpeg/png 格式图片');
					return false;
				}
				if (file.size > 20 * 1024 * 1024) {
					Toast.fail('文件大小不能超过 20M');
					return false;
				}
				return true;
			},
			onClickFallback() {
				this.$router.back();
			},
			onSelectNation(value) {
				this.nation = value;
				this.showNationPicker = false;
			},
			onSelectIdType(value) {
				this.idType = value;
				this.showIdTypePicker = false;
			},
		}
	}
</script>

<style scoped>
	#real-name-container {
		min-height: 100%;
		background-color: #FFFFFF;
	}

	.van-nav-bar__placeholder>>>.van-nav-bar--fixed {
		background-color: #26a5f9;
		border: 0;
	}

	.van-index-anchor {
		background-color: #efefef;
	}

	.navbar-title {
		color: #FFFFFF;
		font-weight: bold;
	}

	.step-box {
		padding: 0 8%;
	}

	.step-box>>>.van-steps {
		margin: auto;
		font-size: 18px;
	}

	.step-box>>>.van-step__title,
	.step-box>>>.van-step__icon {
		font-size: 14px;
	}

	.first-page {
		padding: 2%;
	}

	.first-page>.item {
		display: flex;
		min-height: 3em;
	}

	.first-page>.item>.text {
		margin: auto 0;
	}

	.first-page>.item>.option {
		margin: auto 0;
		margin-left: auto;
	}

	.first-page>.item>.button-box {
		margin: auto 0;
		margin-left: auto;
		margin-right: 9px;
	}

	.first-page>.item>.option-text {
		margin-right: 11px;
		font-size: 14px;
		color: #323233
	}

	.first-page>.button-box {
		min-height: 3em;
		display: block;
	}

	.first-page>.button-box>>>.van-button {
		display: block;
		margin-left: auto;
		margin-top: 1em;
		width: 7em;
	}

	::v-deep .van-divider {
		margin: 0;
	}

	/*步骤条置于导航栏下*/
	::v-deep .van-step__circle-container {
		z-index: 1;
	}

	::v-deep .van-step__line {
		z-index: 0;
	}

	::v-deep .van-nav-bar {
		z-index: 2;
	}
</style>
