<template>
  <div id="addFriend-container">
    <van-nav-bar :placeholder="true" :fixed="true" :border="false" style="background-color:#e9e9e9;">
      <template #left>
        <van-icon @click="onClickFallback" name="arrow-left" size="2em" color="#ffffff"/>
      </template>
      <template #title>
        <span class="navbar-title">添加朋友</span>
      </template>
    </van-nav-bar>

    <van-field placeholder="请输入对方云匙号" class="input-userName" name="userName" v-model="inputUserName"/>

    <div class="block-myUserName">我的云匙号: {{ myUserName }}</div>
    <transition name="fade">
      <div v-if="showUserInfoFlag" class="block-userInfo">
        <div class="head-box">
          <van-image class="head" fit="cover" :src="userInfo.headUrl"/>
        </div>
        <div class="nickname-box">
          <div class="nickname">
            {{ userInfo.nickName }}
          </div>
          <div class="status">
            {{ userInfo.status }}
          </div>
        </div>
        <div class="infoList">
          <div class="infoItem" v-for="i in userInfo.infoList" :key="i.key">
            <div class="infoItem-key">{{ i.key }}</div>
            <div class="infoItem-value">{{ i.value }}</div>
          </div>
        </div>
        <van-button @click="onClickAddButton" color="#26a5f9" size="large" text="加好友"/>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import {NavBar, Field, Image as VanImage, Button, Toast} from 'vant';

Vue.use(NavBar);
Vue.use(Field);
Vue.use(VanImage);
Vue.use(Button);
Vue.use(Toast);
export default {
  name: "add-friend",
  data() {
    return {
      inputUserName: '',
      myUserName: 'Mr.Huang',
      showUserInfoFlag: false,
      userInfo: {
        headUrl: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598437256390&di=6509c8f137e0e146b3d2896aa866ef13&imgtype=0&src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fimages%2F20180115%2Fbbb960a21a8c4552a6f2c853f24e7d81.jpeg',
        nickName: '刘昌',
        statusFlag: 1,
        status: '官方',
        infoList: [
          {
            key: '云匙号:',
            value: 'YS001'
          },
          {
            key: '地址:',
            value: '广东 深圳'
          }
        ]
      }
    }
  },
  methods: {
    onClickFallback() {
      this.$router.back();
    },
    onClickAddButton() {
      Toast.success('申请成功\n待对方同意!');
    }
  },
  watch: {
    inputUserName: function (newValue) {
      if (newValue.length > 0) {
        this.showUserInfoFlag = true;
      } else {
        this.showUserInfoFlag = false;
      }
    }
  }
}
</script>

<style scoped>
#addFriend-container {
  height: 100%;
  background-color: #e9e9e9;
}

.van-nav-bar__placeholder >>> .van-nav-bar--fixed {
  background-color: #26a5f9;
  border: 0;
}

.navbar-title {
  color: #FFFFFF;
  font-weight: bold;
}

.input-userName {
  width: 80%;
  margin: auto;
  margin-top: 7pt;
  border-radius: 5px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.block-myUserName {
  text-align: center;
  font-size: 12px;
  color: #969799;
  margin-top: 7pt;
}

.block-userInfo {;
  background-color: #FFFFFF;
  border-radius: 10px;
  width: 85%;
  margin: auto;
  margin-top: 7pt;
  padding: 7pt;
}

.head-box {
  width: 10em;
  margin: auto;
  margin-top: 7pt;
}

.head {
  width: 100%;
}

.nickname-box {
  margin-top: 7pt;
  display: flex;
  align-items: center;
}

.nickname {
  margin-left: auto;
}

.status {
  margin-right: auto;
  margin-left: 5px;
  width: 30px;
  text-align: center;
  font-size: 8px;
  background-color: #26a5f9;
  color: #ffffff;
  border-radius: 5px;
}

.infoList {
  margin-top: 7pt;
  margin-left: 7pt;
  margin-right: 7pt;
}

.infoItem {
  display: flex;
  margin-top: 4.5pt;
}

.infoItem-key {
  width: 30%;
  color: #969799;
}

.infoItem-value {
  width: 70%;
}

.van-button {
  width: 80%;
  display: block;
  height: 40px;
  border-radius: 5px;
  margin: auto;
  margin-top: 7pt;
  margin-bottom: 7pt;
}
</style>
